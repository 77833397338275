<template>
  <section>
    <div class="bi-channel-date-search">
      <el-form :inline="true">
        <el-form-item>
          <channel-select></channel-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dateType" placeholder="请选择日期类型" @change="dateTypeChange">
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="yearMonth" :type="dateType.type" placeholder="选择日期" :value-format="dateType.value" style="float: left"> </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQueryClick" :loading="loading">查看</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="box">
      <!--账单展示-->
      <el-row :gutter="10">
        <el-col :lg="12" :xs="24">
          <el-card>
            <el-table :data="billData" :summary-method="getSummaries" show-summary stripe>
              <el-table-column prop="channelId" label="渠道ID" :formatter="channelFormatter" sortable></el-table-column>
              <el-table-column prop="month" label="月" sortable v-if="hasChannel"></el-table-column>
              <el-table-column prop="money" label="金额（元）" sortable></el-table-column>
              <el-table-column prop="currency" label="货币" sortable></el-table-column>
              <el-table-column label="操作" v-if="hasDetail">
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleBillDetail(scope.$index, scope.row)" type="info">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <export-excel v-show="billBtn" :data="billData" :headers="billExcelTHList" :filename="billExcelTitle"></export-excel>
          </el-card>
        </el-col>
        <el-col :lg="12" :xs="24">
          <el-card v-show="status">
            <el-table :data="billDetailData" :summary-method="getSummaries" show-summary stripe border>
              <el-table-column :label="channelValue" >
                <el-table-column prop="day" :label="detailDateTitle" :formatter="dayFormatter" sortable></el-table-column>
                <el-table-column prop="money" :label="detailMoneyTitle" sortable></el-table-column>
              </el-table-column>
            </el-table>
            <export-excel v-show="billDetailBtn" :data="billDetailData" :headers="detailExcelTHList" :filename="detailExcelTitle"></export-excel>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import api from '@/api/fee'
import ChannelSelect from '@/components/Channel/ChannelSelect'
import ExportExcel from '@/components/Excel/ExportExcel'
import f from '@/filter'
export default {
  name: 'bill',
  components: {
    ChannelSelect,
    ExportExcel
  },
  data () {
    let dateTypeList = [
      {
        value: 'yyyy',
        label: '年',
        type: 'year',
        detail: false
      },
      {
        value: 'yyyy-MM',
        label: '年-月',
        type: 'month',
        detail: true
      },
      {
        value: 'yyyy-MM-dd',
        label: '年-月-日',
        type: 'date',
        detail: false
      }
    ]
    const billTHItems = [
      { value: 'channelId', label: '渠道ID' },
      { value: 'month', label: '月' },
      { value: 'money', label: '金额（元）' },
      { value: 'currency', label: '货币' }
    ]
    return {
      loading: false,
      yearMonth: '',
      status: false,
      billData: [],
      billDetailData: [],
      iosBillData: [],
      channelValue: '',
      billBtn: false,
      billDetailBtn: false,
      dateType: dateTypeList[1],
      dateList: dateTypeList,
      hasChannel: false,
      hasDetail: false,
      detailDateTitle: '',
      detailMoneyTitle: '',
      billExcelTitle: '',
      billTHItems: billTHItems,
      billExcelTHList: billTHItems,
      detailExcelTitle: '',
      detailExcelTHList: [
        { value: 'day', label: '日' },
        { value: 'money', label: '金额（元）' },
        { value: 'currency', label: '货币' }
      ]
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    channelOptions () {
      return this.$store.getters.channels
    },
    channelId () {
      return this.$store.getters.channelId
    }
  },
  watch: {
    channelId: function (val, oldVal) {
      this.watchChannelId(val)
    }
  },
  created () {
    this.watchChannelId(this.channelId)
    this.setYearMonthValue()
  },
  methods: {
    dateTypeChange () {
      this.yearMonth = ''
      this.billData = []
      this.billDetailData = []
      this.status = false
      this.hasDetail = this.hasChannel || this.dateType.detail
      if (this.hasChannel && this.dateType.type === 'date') {
        this.dateType = this.dateList[1]
      }
      this.setYearMonthValue()
    },
    setYearMonthValue () {
      if (this.dateType.type === 'year') {
        this.yearMonth = this.$moment().format('YYYY')
      } else if (this.dateType.type === 'month') {
        this.yearMonth = this.$moment().format('YYYY-MM')
      } else if (this.dateType.type === 'date') {
        this.yearMonth = this.$moment().format('YYYY-MM-DD')
      }
    },
    watchChannelId (val) {
      this.hasChannel = val > 0
      this.status = false
      this.billData = []
      this.billDetailData = []
      if (this.hasChannel) {
        this.dateType = this.dateList[0]
      }
      this.hasDetail = this.hasChannel || this.dateType.detail
    },
    getBillParams (appId) {
      let params = {
        appId: appId,
        year: this.yearMonth.substr(0, 4)
      }
      let month = this.yearMonth.substr(5, 2)
      if (this.dateType.type === 'month') {
        params.month = month
      }
      if (this.channelId > 0) {
        params.channelId = this.channelId
        return params
      }
      if (this.dateType.type === 'date') {
        params.month = month
        params.day = this.yearMonth.substr(8, 2)
      }
      return params
    },
    handleBillExcelTitleChange (params) {
      let title = `G${this.appId}--账单`
      if (this.channelId > 0) {
        title = title + '--' + f.channelFormat(this.channelId)
      }
      title = title + '--' + params.year
      if (this.dateType.type === 'month') {
        title = title + '-' + params.month
      }
      if (this.dateType.type === 'date') {
        title = title + '-' + params.day
      }
      this.billExcelTitle = title
    },
    fetchData () {
      this.loading = true
      this.billData = []
      this.status = false
      let params = this.getBillParams(this.appId)
      this.handleBillExcelTitleChange(params)
      api.getFeeBill(params).then(res => {
        for (let item of res) {
          if (!this.hasChannel && (item.channelId === 3997 || item.channelId === 3989)) {
            continue
          }
          item.money = item.money / 100
          this.billData.push(item)
        }
        if (this.appId === 3 && (!this.hasChannel || this.channelId === 3999)) {
          let iosParams = this.getBillParams('2')
          iosParams.groupBy = this.dateType.type
          if (this.channelId === 3999) {
            iosParams.groupBy = 'month'
          }
          api.getSdkBill(iosParams).then(res => {
            res.forEach(item => {
              item['channelId'] = 3999
              item.currency = 'RMB'
              item.money = item.money / 100
              this.billData.unshift(item)
            })
          })
        }
        this.billBtn = true
        this.loading = false
        this.billExcelTHList = [...this.billTHItems]
        if (!this.hasChannel) {
          this.billExcelTHList.splice(1, 1)
        }
      })
    },
    handleQueryClick () {
      if (!this.yearMonth) {
        this.$message.error('请选择时间')
        return
      }
      this.fetchData()
    },
    // 初始化渠道ID
    channelFormatter (row) {
      return f.channelFormat(row.channelId)
    },
    // 合计
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (column.property === 'month') {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return (prev + curr)
            } else {
              return prev
            }
          }, 0)
          sums[index] = sums[index] + ' 元'
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 请求详情
    handleBillDetail (index, row) {
      this.detailDateTitle = this.hasChannel ? this.yearMonth.substr(0, 4) + '-' + row.month : this.yearMonth.substr(0, 7)
      this.channelValue = f.channelFormat(row.channelId)
      this.detailExcelTitle = `G${this.appId}--账单详情--${this.channelValue}--${this.detailDateTitle}`
      this.billDetailData = []
      this.status = false
      this.detailMoneyTitle = `金额 ${row.currency} 元`
      if (row.channelId === 3999) {
        let iosParams = {
          appId: 2,
          year: this.yearMonth.substr(0, 4),
          month: this.hasChannel ? row.month : this.yearMonth.substr(5, 2)
        }
        api.getSdkBillDetail(iosParams).then(res => {
          for (let item of res) {
            item.money = item.money / 100
            this.billDetailData.push(item)
          }
          this.status = true
          this.billDetailBtn = true
        })
        return
      }
      let params = {
        appId: this.appId,
        channelId: row.channelId,
        year: this.yearMonth.substr(0, 4),
        month: this.hasChannel ? row.month : this.yearMonth.substr(5, 2)
      }
      api.getFeeBillDetail(params).then(res => {
        for (let item of res) {
          item.money = item.money / 100
          this.billDetailData.push(item)
        }
        this.status = true
        this.billDetailBtn = true
      })
    },
    dayFormatter (row) {
      return row.day + '日'
    }
  }
}
</script>
