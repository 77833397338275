<template>
  <section>
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
<!--    <bi-channel-date-search @change="fetchData"></bi-channel-date-search>-->
    <el-row :gutter="10">
      <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4" v-for="(item, i) in topData" :key="i">
        <small-card :icon="item.icon" :label="getTargetName(item.target)"
                    :value="item.value"
                    :fontColor="item.fontColor">
        </small-card>
      </el-col>
    </el-row>
    <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-for="(item, i) in charts" :key="i">
          <div class="box" v-if="!(i === charts.length - 1 && isOpenServer)">
            <el-tabs class="bg-white" type="card" v-model="item.tabActive" @tab-click="handleChartClick(i)" v-if="item.matrixKey === 'loginRole' ? isOpenServer : true">
              <el-tab-pane v-for="(tab, ti) in item.tabs" :label="tab.title" :name="''+ti" :key="ti"></el-tab-pane>
            </el-tabs>
            <chart-pie-nest v-if="item.isPieNest" :data="item.data" v-show="item.matrixKey === 'loginRole' ? isOpenServer : true"></chart-pie-nest>
            <chart-line-bar v-else :data="item.data" v-show="item.matrixKey === 'loginRole' ? isOpenServer : true"></chart-line-bar>
          </div>
        </el-col>
    </el-row>
  </section>
</template>

<script>
import option from '@/common/option'
import api from '@/api/bi'
import SmallCard from '@/components/SmallCard'
import ChartPieNest from '@/components/Charts/ChartPieNest'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'

export default {
  components: {
    ChartPieNest,
    ChartLineBar,
    SmallCard,
    BiChannelDateSearch
  },
  data () {
    let topData = [ // 概况
      { target: 'loginDeviceAdd', icon: 'tablet-alt', fontColor: '#55B1E5', value: 0 },
      { target: 'loginUserAdd', icon: 'user-plus', fontColor: '#646C92', value: 0 },
      { target: 'loginUser', icon: 'users', fontColor: '#7DE6C0', value: 0 },
      { target: 'payUser', icon: 'user-tie', fontColor: '#A2A7E8', value: 0 },
      { target: 'payUserAdd', icon: 'child', fontColor: '#C7EBAA', value: 0 },
      { target: 'payRate', icon: 'percent', fontColor: '#A9D8C1', value: 0 },
      { target: 'payMoney', icon: 'yen-sign', fontColor: '#9FDEE9', value: 0 },
      { target: 'payMoneyAdd', icon: 'dollar-sign', fontColor: '#96F7CE', value: 0 },
      { target: 'arpu', icon: 'layer-group', fontColor: '#D1A4ED', value: 0 },
      { target: 'arpuAdd', icon: 'layer-group', fontColor: '#b5eba1', value: 0 },
      { target: 'loginUserAddConversion', icon: 'hourglass-half', fontColor: '#b5eba1', value: 0 },
      { target: 'churnRate', icon: 'user-shield', fontColor: '#b5eba1', value: 0 }
      // 暂时弃用
      // { target: 'arppu', icon: 'coins', fontColor: '#F2C49E', value: 0 },
      // { target: 'payRateAdd', icon: 'percent', fontColor: '#C7EBAA', value: 0 }
    ]
    let t = option.targetType
    let matrixTargets = ['loginUser', 'payMoney', 'payUser', 'loginRole', 'deviceAdd'] // 新老用户 新老付费金额 新老付费用户 角色 设备
    let charts = [
      // 新老用户
      {
        matrixKey: matrixTargets[0],
        tabActive: 0,
        tabs: [
          { title: '活跃用户构成', targets: [t.loginUserOld, t.loginUserAdd], targets2: [t.loginUserAddRate], stack: '活跃用户' },
          { title: '新增用户', targets: [t.loginUserAdd], type: 'line' },
          { title: '活跃用户', targets: [t.loginUser], type: 'line' }
        ],
        data: {}
      },
      {
        isPieNest: true,
        matrixKey: matrixTargets[0],
        tabActive: 0,
        tabs: [
          { title: '用户分布', targets: [t.loginUserAdd, t.loginUserOld], name: '活跃用户' }
        ],
        data: {}
      },
      // 新老付费金额
      {
        matrixKey: matrixTargets[1],
        tabActive: '0',
        tabs: [
          { title: '付费金额构成', targets: [t.payMoneyOld, t.payMoneyAdd], targets2: [t.payMoneyAddRate], stack: '付费金额' },
          { title: '新增付费金额', targets: [t.payMoneyAdd], type: 'line' },
          { title: '付费金额', targets: [t.payMoney], type: 'line' }
        ],
        data: {}
      },
      {
        isPieNest: true,
        matrixKey: matrixTargets[1],
        tabActive: 0,
        tabs: [
          { title: '付费金额分布', targets: [t.payMoneyAdd, t.payMoneyOld], name: '付费金额' }
        ],
        data: {}
      },
      // 新老付费用户
      {
        matrixKey: matrixTargets[2],
        tabActive: 0,
        tabs: [
          { title: '付费用户构成', targets: [t.payUserOld, t.payUserAdd], targets2: [t.payUserAddRate], stack: '付费用户' },
          { title: '新增付费用户', targets: [t.payUserAdd], type: 'line' },
          { title: '付费用户', targets: [t.payUser], type: 'line' }
        ],
        data: {}
      },
      {
        isPieNest: true,
        matrixKey: matrixTargets[2],
        tabActive: 0,
        tabs: [
          { title: '付费用户分布', targets: [t.payUserAdd, t.payUserOld], name: '付费用户' }
        ],
        data: {}
      },
      // 分服角色相关
      {
        matrixKey: matrixTargets[3],
        tabActive: 0,
        tabs: [
          { title: '活跃角色构成', targets: [t.loginRoleAdd, t.loginRole], targets2: [t.loginRoleAddRate], stack: '活跃角色' },
          { title: '新增角色', targets: [t.loginRoleAdd], type: 'line' },
          { title: '活跃角色', targets: [t.loginRole], type: 'line' }
        ],
        data: {}
      },
      {
        isPieNest: true,
        matrixKey: matrixTargets[3],
        tabActive: 0,
        tabs: [
          { title: '角色分布', targets: [t.loginRoleAdd, t.loginRoleOld], name: '活跃角色' }
        ],
        data: {}
      },
      {
        matrixKey: matrixTargets[1],
        tabActive: 0,
        tabs: [
          { title: 'ARPU', targets: [t.ARPU] }
        ],
        data: {}
      },
      {
        matrixKey: matrixTargets[1],
        tabActive: 0,
        tabs: [
          { title: 'ARPPU', targets: [t.ARPPU] }
        ],
        data: {}
      },
      {
        matrixKey: matrixTargets[2],
        tabActive: 0,
        tabs: [
          { title: '付费率', targets: [t.payRate] }
        ],
        data: {}
      },
      {
        matrixKey: matrixTargets[4],
        tabActive: 0,
        tabs: [
          { title: '设备激活', targets: [t.loginDeviceAdd] }
        ],
        data: {}
      }
    ]
    let matrixKeyChartIndexMap = new Map()
    for (let [index, item] of charts.entries()) {
      let matrixKey = item.matrixKey
      let val = matrixKeyChartIndexMap.get(matrixKey)
      if (!val) {
        val = new Set()
        matrixKeyChartIndexMap.set(matrixKey, val)
      }
      val.add(index)
    }
    return {
      isOpenServer: false, // 分服是否打开 true 打开 false 关闭， 默认关闭
      rightTabActive: 'tab1',
      getTargetName: option.getTargetName,
      topData: topData,
      matrixTargets: matrixTargets,
      matrixData: {},
      matrixKeyChartIndexMap: matrixKeyChartIndexMap,
      charts: charts
    }
  },
  created () {
    this.fetchData()
  },
  mounted () {},
  methods: {
    fetchData () {
      // 总览
      // 是否分服
      api.getDashboard(this.isOpenServer).then(res => {
        let data = Object.assign({}, res)
        for (let item of this.topData) {
          item.value = data[item.target] ? data[item.target] : 0
        }
      })
      // 下面10个图
      for (let target of this.matrixTargets) {
        let param = {}
        if (target !== 'deviceAdd') {
          param.target = target
          param.dist = 'channel'
        }
        if (target === 'loginRole') {
          if (!this.isOpenServer) {
            continue
          }
        }
        if (target === 'deviceAdd') {
          if (this.isOpenServer) {
            continue
          }
        }
        api.getMatrixTargetData(this.isOpenServer, target, param).then(res => {
          this.matrixData[target] = res
          let indexSet = this.matrixKeyChartIndexMap.get(target)
          if (indexSet) {
            for (let i of indexSet) {
              this.handleChartClick(i)
            }
          }
        })
      }
    },
    handleChartClick (index) {
      let item = this.charts[parseInt(index)]
      if (!item) {
        return
      }
      let i = parseInt(item.tabActive)
      item.data = Object.assign({}, item.tabs[i])
      item.data.data = this.matrixData[item.matrixKey]
      this.$set(this.charts, index, item)
    },
    isServer (value) {
      this.isOpenServer = value
      if (this.topData.length > 10) {
        this.topData.splice(10, 2)
      }
      let game = [
        { target: 'loginRoleAdd', icon: 'hourglass-half', fontColor: '#b5eba1', value: 0 },
        { target: 'loginRole', icon: 'user-shield', fontColor: '#b5eba1', value: 0 }
      ]
      let platform = [
        { target: 'loginUserAddConversion', icon: 'hourglass-half', fontColor: '#b5eba1', value: 0 },
        { target: 'churnRate', icon: 'user-shield', fontColor: '#b5eba1', value: 0 }
      ]
      this.topData = value ? this.topData.concat(game) : this.topData.concat(platform)
    }
  }
}
</script>

<style scoped>
 .card {
   width: 19%;
   float: left;
   margin-right: 1%;
   margin-top: 10px;
   height: 100px;
   background: #ffffff;
 }
  .card h5 {
    margin: 0;
    padding: 5px 0 15px;
  }
  .card span {
    display: block;
    margin: 0;
    padding: 10px 0;
    font-size: 18px;
  }
</style>
