<template>
  <section>
<!--    <bi-channel-date-search @change="fetchData"></bi-channel-date-search>-->
    <div class="bi-channel-date-search">
      <el-form :inline="true">
        <el-form-item>
          <channel-cascade :multiple="true"></channel-cascade>
        </el-form-item>
        <el-form-item>
          <server-select></server-select>
        </el-form-item>
        <el-form-item>
          <date-range></date-range>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData" :loading="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-for="(item, key) in charts" :key="key">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="item.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-pie-nest :data="item"></chart-pie-nest>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import DateRange from '@/components/DateRange'
import ChannelCascade from '@/components/Channel/ChannelCascade'
import ServerSelect from '@/components/Server/ServerSelect'
import api from '@/api/bi'
import option from '@/common/option'
import ChartPieNest from '@/components/Charts/ChartPieNest'

export default {
  components: {
    DateRange,
    ChannelCascade,
    ServerSelect,
    ChartPieNest
  },
  name: 'Distribution',
  data () {
    let t = option.targetType
    return {
      loading: false,
      charts: {
        chart1: { title: '新增用户', targets: [t.origin, t.roll], apiFunc: api.getRollLoginUserAdd, data: {}, distKey: 'server', name: '滚服来源-新增人数' },
        chart2: { title: '活跃用户', targets: [t.origin, t.roll], apiFunc: api.getRollLoginUser, data: {}, distKey: 'server', name: '滚服来源-活跃人数' },
        chart3: { title: '付费金额', targets: [{ label: '非滚服-付费金额', value: 'origin' }, { label: '滚服-付费金额', value: 'roll' }], apiFunc: api.getRollPayMoney, data: {}, distKey: 'server', name: '滚服来源-付费金额' },
        chart4: { title: '付费用户', targets: [{ label: '非滚服-付费人数', value: 'origin' }, { label: '滚服-付费人数', value: 'roll' }], apiFunc: api.getRollPayUser, data: {}, distKey: 'server', name: '滚服来源-付费人数' }
      }
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    serverId () {
      return this.$store.getters.serverId
    }
  },
  methods: {
    fetchData () {
      if (!this.serverId) {
        this.$message.error('请选择服务器')
        return
      }
      for (let chart in this.charts) {
        this.fetchChartsData(chart)
      }
    },
    fetchChartsData (chart) {
      let data = this.charts[chart]
      let serverId = this.serverId
      data.apiFunc(serverId).then(res => {
        data.data = res
      })
    }
  }
}
</script>

<style scoped>
  .bi-channel-date-search {
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #E4E7ED;
  }
</style>
