<template>
  <section>
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart1Tab" @tab-click="handleChartClick('chart1')">
            <el-tab-pane label="日付费率" name="tab1"> </el-tab-pane>
          </el-tabs>
          <chart-line :data="chart1Tab === 'tab1' ? payUserDataD : chart1Tab === 'tab2' ? payUserDataW : payUserDataM" :title="chart1Data.title" :targets="chart1Data.target"></chart-line>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart2Tab" @tab-click="handleChartClick('chart2')">
            <el-tab-pane label="日ARPU" name="tab1"></el-tab-pane>
            <el-tab-pane label="月ARPU" name="tab2"></el-tab-pane>
            <el-tab-pane label="日ARPPU" name="tab3"></el-tab-pane>
            <el-tab-pane label="月ARPPU" name="tab4"></el-tab-pane>
          </el-tabs>
          <chart-line :data="chart2Tab === 'tab1' || chart2Tab === 'tab3' ? payMoneyDataD : payMoneyDataM" :title="chart2Data.title" :targets="chart2Data.target"></chart-line>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import ChartLine from '@/components/Charts/ChartLine'
import api from '@/api/bi'
import option from '@/common/option'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'
require('echarts/theme/macarons')

export default {
  components: {
    BiChannelDateSearch,
    ChartLine
  },
  name: 'PayPermeate',
  data () {
    let t = option.targetType
    return {
      isOpenServer: false,
      chart1Tab: 'tab1',
      chart2Tab: 'tab1',
      chart3Tab: 'tab1',
      dataMap: new Map(),
      fetchedTabMap: new Map(),
      chartsData: {
        chart1: {
          tab1: { title: '日付费率', target: [t.payRate] }
        },
        chart2: {
          tab1: { title: '日ARPU', target: [t.ARPU] },
          tab2: { title: '月ARPU', target: [t.ARPU] },
          tab3: { title: '日ARPPU', target: [t.ARPPU] },
          tab4: { title: '月ARPPU', target: [t.ARPPU] }
        }
      },
      payUserDataD: {},
      payUserDataW: {},
      payUserDataM: {},
      payMoneyDataD: {},
      payMoneyDataM: {},
      payUserChannelDataD: {},
      payMoneyChannelDataD: {}
    }
  },
  created () {
    this.fetchData()
    this.handleChartClick('chart1')
    this.handleChartClick('chart2')
  },
  methods: {
    fetchData () {
      // 付费率日数据
      let paramD = { target: 'payUser', dist: 'channel' }
      api.getMatrixTargetData(this.isOpenServer, 'payUser', paramD).then(res => {
        this.payUserDataD = res
        this.payUserChannelDataD = { payRate: res.dist }
      })
      // ARPU ARPPU 日数据
      api.getMatrixTargetData(this.isOpenServer, 'payMoney', paramD).then(res => {
        this.payMoneyDataD = res
      })
      // 付费率月数据
      let paramM = { target: 'payUser' }
      // ARPU ARPPU 月数据
      api.getMonthlyMatrixTargetData(this.isOpenServer, 'payMoney', paramM).then(res => {
        this.payMoneyDataM = res
      })
    },
    handleChartClick (chart) {
      this[chart + 'Data'] = this.chartsData[chart][this[chart + 'Tab']]
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
