<template>
    <div class="bg-white charts-canvas"></div>
</template>

<script>
import util from '@/common/util'
import f from '@/filter'
require('echarts/theme/macarons')

export default {
  name: 'ChartLine',
  props: {
    data: { // 原始数据
      type: Object,
      required: true
    },
    // title: {
    //   type: String,
    //   required: true
    // },
    targets: {
      type: Array,
      required: true
    },
    xAxisKey: {
      type: String,
      default: 'date'
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        // if (Object.keys(chartData).length === 0) {
        //   return
        // }
        this.setOptions(val)
      }
    },
    targets: {
      handler (val) {
        // this.setOptions(this.data)
      }
    },
    xAxisKey: {
      handler (val) {
        this.setOptions(this.data)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
    let _this = this
    function handle () {
      if (_this.chart) {
        _this.chart.resize()
      }
    }
    window.addEventListener('resize', util.debounce(handle, 500))
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = this.$echarts.init(this.$el, 'macarons')
      this.setOptions(this.data)
    },
    setOptions (chartData) {
      this.chart.clear()
      let data = util.convertToChartDataByTargets(this.targets, chartData, this.xAxisKey)
      let xAxisData = data[this.xAxisKey] // x轴展示的内容 日期
      if (xAxisData) {
        xAxisData = xAxisData.map(v => f.autoFormat(this.xAxisKey, v))
      }
      let legendData = [] // 头顶的指标快捷选项
      let seriesData = [] // 具体数据
      for (let val of this.targets) {
        legendData.push(val.label)
        seriesData.push({
          name: val.label,
          type: 'line',
          data: data[val.value],
          markPoint: { data: [{ type: 'max', name: '最大值' }, { type: 'min', name: '最小值' }] },
          markLine: { data: [{ type: 'average', name: '平均值' }] }
        })
      }
      this.chart.setOption({
        // title: {
        //   text: this.title || ''
        // },
        tooltip: {
          trigger: 'axis', // 坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          axisPointer: { // 坐标轴指示器
            type: 'cross' // 十字准心指示器
          }
        },
        legend: {
          data: legendData,
          left: this.isMobile ? '5%' : 'center'
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100
          }
        ],
        toolbox: { // 右上角的快捷选项
          show: true,
          orient: 'horizontal', // 横向还是竖向展示
          left: 'right',
          top: 'top',
          feature: { // 快捷栏的选项
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false }, // 展示X轴上的刻度线
            data: xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: seriesData
      })
    }
  }
}
</script>
