<template>
  <section>
    <!--    <bi-channel-date-search @change="fetchData" @isLoading="isLoading" :loading="loading"></bi-channel-date-search>-->
    <div class="bi-channel-date-search">
      <el-form :inline="true">
        <el-form-item>
          <channel-cascade :multiple="true"></channel-cascade>
        </el-form-item>
        <el-form-item>
          <server-multiple-select @serverList="getServerList"></server-multiple-select>
        </el-form-item>
        <el-form-item>
          <date-range></date-range>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData" :loading="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(td, tab) in tabs" :label="td.label" :name="tab" :key="tab"></el-tab-pane>
        <el-table
          :data="tableData" style="width: 100%"
          stripe sortable
          :max-height="clientHeight"
          show-summary :summary-method="getSummaries"
          v-loading="loading"
          element-loading-text="拼命加载中,请稍等..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          >
          <el-table-column :prop="item.value"
                           :label="item.label"
                           :width="item.value === 'date'? '100' : '120'"
                           :fixed="item.value === 'date' || item.value === 'channel'"
                           v-for="item in tableHeaderList"
                           :sortable="item.sortable"
                           :key="item.value"
                           :formatter="item.formatter ? autoFormatter : null">
          </el-table-column>
        </el-table>
      </el-tabs>
      <export-excel style="margin: 10px" v-show="exportExcelVisible" :data="exportData" :headers="tableHeaderList" :filename="tabs[activeName].excelFilename"></export-excel>
    </div>
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import DateRange from '@/components/DateRange'
import ExportExcel from '@/components/Excel/ExportExcel'
import ChannelCascade from '@/components/Channel/ChannelCascade'
// import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'
import ServerMultipleSelect from '@/components/Server/ServerMultipleSelect2'
import f from '@/filter'
export default {
  components: {
    DateRange,
    ChannelCascade,
    // BiChannelDateSearch,
    ServerMultipleSelect,
    ExportExcel
  },
  computed: {
    // tables () {
    //   return this.tableData.slice(0, this.rangeYNumber)
    // },
    // tHeaderList () {
    //   return this.tableHeaderList.slice(0, this.rangeXNumber)
    // },
    clientHeight () {
      return document.body.clientHeight * 0.8
    }
  },
  data () {
    let thDate = { value: 'date', label: '日期', sortable: true }
    let thChannel = { value: 'channel', label: '渠道', sortable: true, formatter: true }
    let thServer = { value: 'server', label: '服务器', sortable: true, formatter: true }
    let thList = [
      { value: 'loginDeviceAdd', label: '新增设备' },
      { value: 'loginUserAdd', label: '新增用户', sortable: true },
      { value: 'loginUser', label: '活跃用户', sortable: true },
      { value: 'payMoney', label: '付费金额', sortable: true },
      { value: 'payUser', label: '付费用户', sortable: true },
      { value: 'payRate', label: '付费率(%)', sortable: true },
      { value: 'arppu', label: 'ARPPU' },
      { value: 'arpu', label: 'ARPU' },
      { value: 'payMoneyAdd', label: '新增付费金额' },
      { value: 'payUserAdd', label: '新增付费用户' },
      { value: 'payRateAdd', label: '新增付费率' },
      { value: 'arppuAdd', label: '新增ARPPU' },
      { value: 'arpuAdd', label: '新增ARPU' },
      { value: 'payRateOld', label: '老用户付费率' },
      { value: 'arppuOld', label: '老用户ARPPU' },
      { value: 'keep2', label: '次日留存' },
      { value: 'keep3', label: '3日留存' },
      { value: 'keep4', label: '4日留存' },
      { value: 'keep5', label: '5日留存' },
      { value: 'keep6', label: '6日留存' },
      { value: 'keep7', label: '7日留存' },
      { value: 'keep14', label: '14日留存' },
      { value: 'keep30', label: '30日留存' },
      { value: 'keep45', label: '45日留存' },
      { value: 'keep60', label: '60日留存' },
      { value: 'keep90', label: '90日留存' },
      { value: 'keep120', label: '120日留存' },
      { value: 'keep180', label: '180日留存' },
      { value: 'ltv1', label: '当日ltv' },
      { value: 'ltv2', label: '次日ltv' },
      { value: 'ltv3', label: '3日ltv' },
      { value: 'ltv4', label: '4日ltv' },
      { value: 'ltv5', label: '5日ltv' },
      { value: 'ltv6', label: '6日ltv' },
      { value: 'ltv7', label: '7日ltv' },
      { value: 'ltv14', label: '14日ltv' },
      { value: 'ltv30', label: '30日ltv' },
      { value: 'ltv45', label: '45日ltv' },
      { value: 'ltv60', label: '60日ltv' },
      { value: 'ltv90', label: '90日ltv' },
      { value: 'ltv120', label: '120日ltv' },
      { value: 'ltv180', label: '180日ltv' }
    ]
    return {
      loading: false,
      thList: thList,
      serverList: [],
      activeName: 'tab1',
      tabs: {
        tab1: { label: '数据总览', apiFunc: api.getReportServer, thExtends: [thDate], excelFilename: '分服日报-总览' },
        tab2: { label: '分服每日', apiFunc: api.getReportServerDay, thExtends: [thDate, thServer], excelFilename: '分服日报-每日' },
        tab3: { label: '分服汇总', apiFunc: api.getReportServerSer, thExtends: [thServer], excelFilename: '分服日报-分服汇总' },
        tab4: { label: '渠道汇总', apiFunc: api.getReportServerChannel, thExtends: [thChannel], excelFilename: '分服日报-渠道汇总' },
        tab5: { label: '原始数据', apiFunc: api.getReportServerOrigin, thExtends: [thDate, thChannel, thServer], excelFilename: '分服日报-原始' }
      },
      tableData: [],
      tabMap: new Map(),
      sumMap: new Map(),
      option: option,
      tableHeaderList: [],
      exportExcelVisible: false,
      exportData: []
      // rangeXNumber: 60,
      // rangeYNumber: 30
    }
  },
  created () {
    // this.handleClick()
  },
  methods: {
    fetchData () {
      // this.tabMap.clear()
      this.fetchTabData()
    },
    fetchTabData () {
      this.changeTableHeaders()
      this.loading = true
      let param = {
        servers: []
      }
      for (let s of this.serverList) {
        param.servers.push(s)
      }
      if (!param.servers.length) {
        this.$message.error('请选择服务器')
        this.loading = false
        return
      }
      this.tabs[this.activeName].apiFunc(param).then(res => {
        this.loading = false
        this.tableData = res.data
        this.tabMap.set(this.activeName, res.data)
        this.sumMap.set(this.activeName, res.sum)
        this.handleExportShow()
      })
    },
    getServerList (serverList) {
      this.serverList = serverList
    },
    changeTableHeaders () {
      this.exportExcelVisible = false
      this.tableData = []
      let tab = this.tabs[this.activeName]
      this.tableHeaderList = [...tab.thExtends, ...this.thList]
    },
    handleClick () {
      this.changeTableHeaders()
      if (this.tabMap.has(this.activeName)) {
        this.tableData = this.tabMap.get(this.activeName)
        this.handleExportShow()
      }
      // } else {
      //   this.fetchTabData()
      // }
    },
    handleExportShow () {
      let sumData = this.sumMap.get(this.activeName)
      let sum = Object.assign({ date: '统计', channel: '统计' }, sumData)
      this.exportData = [...this.tableData]
      this.exportData.push(sum)
      this.exportExcelVisible = true
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      let sumData = this.sumMap.get(this.activeName)
      if (!sumData) {
        return sums
      }
      columns.forEach((column, index) => {
        let key = column.property
        if (index === 0) {
          sums[index] = '统计'
          return
        }
        sums[index] = sumData[key]
      })
      return sums
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    }
    // loadMore (str) {
    //   if (!str) {
    //     this.rangeYNumber = this.tableData.length
    //   }
    //   if (str === 'x') {
    //     this.rangeXNumber += 5
    //   }
    //   if (str === 'y') {
    //     this.rangeYNumber += 10
    //   }
    // }
  }
}
</script>
