<template>
  <div class="bg-white charts-canvas"></div>
</template>

<script>
import option from '@/common/option'
import util from '@/common/util'
import 'echarts/map/js/china.js'
require('echarts/theme/macarons')

export default {
  name: 'ChartMap',
  props: {
    data: { // 原始数据
      required: true
    },
    // title: {
    //   type: String,
    //   required: true
    // },
    target: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chart: null,
      nameMap: option.nameMap
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
    let _this = this
    function handle () {
      if (_this.chart) {
        _this.chart.resize()
      }
    }
    window.addEventListener('resize', util.debounce(handle, 500))
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = this.$echarts.init(this.$el, 'macarons')
      if (!this.data) {
        return
      }
      this.setOptions(this.data)
    },
    setOptions (chartData) {
      if (!chartData.length) {
        return
      }
      this.chart.clear()
      let arr = []
      let nameMap = option.nameMap
      let data = [] // 存放地区数据
      chartData.forEach(item => {
        data.push({ name: nameMap[item.name], value: item.value })
        arr.push(item.value)
      })
      let min = Math.min.apply(null, arr)
      let max = Math.max.apply(null, arr)
      let label = {
        normal: { show: false },
        emphasis: { show: true }
      }
      let legendData = [] // 头顶的指标快捷选项
      let seriesData = [] // 具体数据
      for (let val of this.target) {
        legendData.push(val.label)
        seriesData.push({ name: val.label, type: 'map', map: 'china', roam: true, label: label, data: data })
      }
      this.chart.setOption({
        // title: {
        //   text: this.title || '',
        //   left: 'left'
        // },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          data: legendData
        },
        visualMap: {
          min: min,
          max: max,
          left: 'left',
          top: 'bottom',
          text: ['高', '低'],
          // inRange: {
          //   color: ['#F596AF', '#96F7CE', '#F6F48E', '#80CCFF', '#F2C49E', '#D1A4ED', '#D5F5A2', '#89F2F3', '#24448E', '#1C2B7F']
          // },
          calculable: true
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: seriesData
      })
    }
  }
}
</script>
