<template>
  <el-form v-bind="$attrs" :model="filterForm" :inline="true">
    <template v-for="(item, index) in filterColumn">
      <el-form-item v-if="item.type === 'action'" :key="index">
        <el-button type="primary" size="small" @click="onSearch">查询</el-button>
      </el-form-item>
      <el-form-item v-else :key="index" :label="item.label">
        <slot v-if="item.type === 'slot'" :name="item.value" v-bind:i="item" v-bind:form="filterForm"></slot>

        <el-select
          v-else-if="item.type === 'select'"
          v-model="filterForm[item.value]"
          :placeholder="item.placeholder"
          :multiple="item.multiple"
          size="small"
          clearable
        >
          <el-option
            v-for="(x, y) in (item.options)"
            :label="x.label"
            :value="x.value"
            :key="y"
          ></el-option>
        </el-select>

        <el-date-picker
          size="small"
          v-else-if="(item.type) === 'date'"
          v-model="filterForm[item.value]"
          :type="item.connect ? 'daterange' : 'date'"
          range-separator="至"
          :start-placeholder="item.connect && '开始日期'"
          :end-placeholder="item.connect && '结束日期'"
          :value-format="item.valueFormat || 'timestamp'"
          :picker-options="item.options || {}"
          :disabled="item.readonly || item.disabled"
          :default-time="item.connect ? ['00:00:00', '23:59:59'] : '00:00:00'"
          :clearable="item.clearable === undefined ? true : item.clearable"
          @change="(value) => $emit('onChange', item, form, value)"
          editable
        >
        </el-date-picker>

        <el-date-picker
          v-else-if="item.type === 'datetime'"
          size="small"
          v-model="filterForm[item.value]"
          :type="item.connect ? 'datetimerange' : 'datetime'"
          range-separator="至"
          :start-placeholder="item.connect && '开始日期'"
          :end-placeholder="item.connect && '结束日期'"
          :value-format="item.valueFormat || 'timestamp'"
          :picker-options="item.options || {}"
          :disabled="item.readonly || item.disabled"
          :default-time="item.connect ? ['00:00:00', '23:59:59'] : '00:00:00'"
          :clearable="item.clearable === undefined ? true : item.clearable"
          @change="(value) => $emit('onChange', item, form, value)"
          editable
        >
        </el-date-picker>

        <el-input  v-else clearable size="small" :placeholder="item.placeholder" v-model.trim="filterForm[item.value]"/>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
export default {
  name: 'FilterForm',
  props: {
    form: {
      type: Object,
      required: true
    },
    filterColumn: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  data () {
    return {
      filterForm: {}
    }
  },
  watch: {
    form: {
      handler (nVal) {
        if (JSON.stringify(nVal) !== JSON.stringify(this.filterForm)) {
          this.filterForm = { ...this.filterForm, ...nVal }
        }
      },
      immediate: true,
      deep: true
    },
    filterForm: {
      handler (nVal) {
        this.$emit('update:form', { ...this.form, ...nVal })
      }
    }
  },
  mounted () {},
  methods: {
    onSearch () {
      const filterForm = this.filterColumn.reduce((pre, cur) => {
        const form = {}
        if (['date', 'datetime'].includes(cur.type)) {
          if (cur.connect) {
            const list = this.filterForm[cur.value];
            [form[cur.value], form[cur.connect]] = (list === undefined ? [] : list).map(item => {
              return +(new Date(item))
            })
          } else {
            form[cur.value] = +(new Date(this.filterForm[cur.value]))
          }
        } else {
          form[cur.value] = this.filterForm[cur.value]
        }
        return { ...pre, ...form }
      }, {})
      this.$emit('onSearch', { ...filterForm })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
