<template>
  <div class="contrast">
    <div class="bi-channel-date-search">
      <el-form :inline="true">
        <el-form-item>
          <server-multiple-select @serverList="handleChildServerChange"></server-multiple-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="targetList" placeholder="请选择指标" filterable multiple clearable collapse-tags>
            <el-option v-for="item in targetOptions" :key="item.target" :label="item.name" :value="item.target"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="cycleList" placeholder="请选择阶段" filterable multiple clearable collapse-tags>
            <el-option v-for="item in cycleOptions" :key="item.cycle" :label="item.name" :value="item.cycle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchPortraitData">查询</el-button>
        </el-form-item>
      </el-form>
      <span style="font-size: 12px;color: #ccc">Tip: 所有选项必填且只有两个选项可多选</span>
    </div>
    <!--  图表展示区-->
    <el-row :gutter="10">
      <!--      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">-->
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="item in chartTargets" :key="item.name">
        <div class="box">
          <chart-bar :data="item.data" :targets="[item.targets]" :xAxisKey="chartInfo.x" v-if="chartInfo.cType === 'bar'"></chart-bar>
          <chart-line :data="item.data" :targets="[item.targets]" :xAxisKey="chartInfo.x" v-else></chart-line>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ServerMultipleSelect from '@/components/Server/ServerMultipleSelect2'
import ChartLine from '@/components/Charts/ChartLine'
import ChartBar from '@/components/Charts/ChartBar'
export default {
  name: 'Contrast',
  components: {
    ChartLine,
    ChartBar,
    ServerMultipleSelect
  },
  created () {
    this.fetchData()
  },
  filters: {
    titleFormat (value) {
      return 2
    }
  },
  data () {
    // a:服务器单选 b:指标单选 c:阶段单选
    // A:服务器多选 B:指标多选 C:阶段多选
    // 最多只有两项可以多选
    const cp = [
      { type: 'a-B-C', cType: 'line', x: 'cycle', length: 'targetList' },
      { type: 'A-b-C', cType: 'bar', x: 'server', length: 'cycleList' },
      { type: 'A-B-c', cType: 'bar', x: 'server', length: 'targetList' },
      { type: 'a-b-C', cType: 'line', x: 'cycle', length: 'targetList' },
      { type: 'a-B-c', cType: 'bar', x: 'target', length: 'cycleList' },
      { type: 'A-b-c', cType: 'bar', x: 'server', length: 'targetList' },
      { type: 'a-b-c', cType: 'bar', x: 'target', length: 'cycleList' }
    ]
    return {
      cp,
      targetOptions: [], // 指标select列表
      cycleOptions: [], // 阶段select列表
      targetAndCycleMap: new Map(), // 存放target和cycle的Map
      targetList: [], // 已选择的指标
      cycleList: [], // 已选择的阶段
      serverList: [], // 已选择的服务器

      chartInfo: {}, // 需要生成的图表基本信息
      chartTargets: [] // 需要生成的图表数据存放
    }
  },
  methods: {
    fetchData () {
      // 查询指标和阶段
      api.getPortraitParams().then(response => {
        this.targetOptions = [...response.targets]
        this.cycleOptions = [...response.cycles]
        this.targetOptions.forEach(item => this.targetAndCycleMap.set(item.target, item))
        this.cycleOptions.forEach(item => this.targetAndCycleMap.set(item.cycle, item))
        option.contrastOptions = this.targetAndCycleMap
      })
    },
    // 获取分服画像
    fetchPortraitData () {
      let cp = this.checkCp()
      if (!cp) {
        return false
      }
      this.chartInfo = cp
      const chartTargets = this.handleGetChartInfoTarget(this[cp.length]) // 每个图需要的target
      const data = []
      chartTargets.forEach(item => {
        let obj = {}
        obj.targets = { label: item.name, value: item.target ? item.target : item.cycle }
        obj.data = {}
        obj.params = {
          display: item.target ? 'target' : 'cycle',
          targets: item.target ? [item.target] : [...this.targetList],
          servers: [...this.serverList],
          cycles: item.cycle ? [item.cycle] : [...this.cycleList]
        }
        data.push(obj)
      })
      data.forEach(item => {
        api.getPortraitContrast(item.params).then(response => {
          item.data = response
        })
      })
      this.chartTargets = Object.assign([], data)
    },
    handleGetChartInfoTarget (list) {
      const arr = []
      list.forEach(item => {
        arr.push(this.targetAndCycleMap.get(item))
      })
      return arr
    },
    // 接收子组件传来的已经选择的服务器列表
    handleChildServerChange (serverList) {
      this.serverList = serverList
    },
    // 验证组合类型是否符合cp
    checkCp () {
      if (this.serverList.length <= 0 || this.targetList.length <= 0 || this.cycleList.length <= 0) {
        this.$message.error('选项必填')
        return false
      }
      const s = this.serverList.length > 1 ? 'A' : 'a'
      const t = this.targetList.length > 1 ? 'B' : 'b'
      const c = this.cycleList.length > 1 ? 'C' : 'c'
      const type = [s, t, c].join('-')
      const flag = this.cp.find(item => {
        return item.type === type
      })
      if (!flag) {
        this.$message.warning('类型匹配失败,请重新选择')
      }
      return flag
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>
