<template>
  <el-dialog
    :custom-class="'supplementary-order-dialog'"
    :visible.sync="formVisible"
    :append-to-body="true"
  >
    <FormComponent
      ref="form"
      :formData="formData"
      :column="column"
      :rules="rules"
      :hideBack="true"
      @onSubmit="submit"
    />
  </el-dialog>
</template>

<script>
import FormComponent from '@/components/Layout/Form.vue'
import api from '@/api/bi'
export default {
  name: 'SupplementaryOrder',
  components: {
    FormComponent
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formVisible: false,
      formData: {},
      column: [
        { label: '订单号', formType: 'text', value: 'orderId', disabled: true },
        { label: '渠道订单号', formType: 'text', value: 'channelOrderId', disabled: true },
        { label: '金额(分)', formType: 'text', value: 'money', disabled: true },
        { label: '支付时间', formType: 'datetime', valueFormat: 'yyyy-MM-dd HH:mm:ss', value: 'paidTime' },
        {
          label: '支付方式',
          value: 'payway',
          formType: 'select',
          placeholder: '支持自定义输入支付方式',
          filterable: true,
          bind: {
            'allow-create': true
          },
          formOptions: [
            { label: '支付宝', value: '1' },
            { label: '微信', value: '3' },
            { label: 'AppStore', value: '5' }
          ]
        }
      ]
    }
  },
  computed: {
    rules () {
      return {
        paidTime: { required: true, message: '请填写支付时间' },
        payway: { required: true, message: '请填写支付方式' }
      }
    }
  },
  watch: {
    visible (nVal) {
      if (nVal) {
        if (!Object.keys(this.formData).length) {
          this.handlerFormData(this.form)
        }
      } else {
        this.formData = {}
        this.$refs.form.resetForm()
      }
      this.formVisible = nVal
    },
    formVisible (nVal) {
      if (nVal !== this.visible) {
        this.$emit('update:visible', nVal)
      }
    },
    form: {
      handler (nVal) {
        if (nVal) {
          // 订单号 / 渠道订单号 / 金额(分) / 支付时间
          this.handlerFormData(nVal)
        } else {
          this.formData = {}
        }
      },
      immediate: true
    }
  },
  methods: {
    handlerFormData (valueForm) {
      this.formData = {
        orderId: valueForm.id,
        channelOrderId: valueForm.channel_order_id,
        money: valueForm.money,
        paidTime: new Date().toLocaleString().replaceAll('/', '-')
      }
    },
    submit (form) {
      const loading = this.$loading({
        target: document.querySelector('.supplementary-order-dialog')
      })
      api.patchOrder(form).then((res) => {
        this.$message[res.code === 1 ? 'success' : 'error'](`${res.code}: ${res.msg}`)
        this.formVisible = false
      }).finally(() => loading.close())
      console.log(form)
    }
  }
}
</script>

<style scoped>

</style>
