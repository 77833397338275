<template>
  <div class="form-component">
    <el-card shadow="never">
      <div slot="header" class="flex items-center justify-between" v-if="title">
        <span>{{ title }}</span>
        <div class="flex items-center justify-between">
          <slot></slot>
        </div>
      </div>
      <el-form
        :model="form"
        ref="form"
        :label-width="labelWidth || '100px'"
        class="form"
        v-bind="$attrs"
        :rules="rules"
      >
        <template v-for="(i, j) in column">
          <el-form-item
            v-show="judgmentType(i.hideForm, 'Function') ? i.hideForm(i, form) : !i.hideForm"
            :label="i.label" :key="j" :prop="i.value"
          >
            <el-input
              v-if="['text', 'password', 'textarea', 'number'].includes((i.formType === undefined ? i.type : i.formType))"
              v-model.trim="form[i.value]"
              size="small"
              :require="i.require"
              :placeholder="i.placeholder"
              clearable
              :rows="i.rows"
              :autosize="i.autosize"
              :type="i.type"
              :disabled="i.readonly || i.disabled"
              :maxlength="i.maxlength"
              :show-word-limit="!!i.maxlength"
              :min="i.min"
              :max="i.max"
            ></el-input>

            <el-input-number
              v-if="[i.type, i.formType].includes('inputNumber')"
              v-model="form[i.value]"
              :size="i.size || 'mini'"
              :label="i.label"
              :min="i.min"
              :max="i.max"
              :precision="i.precision"
              :step="i.step"
              :disabled="i.readonly || i.disabled"
            >
            </el-input-number>

            <el-select
              v-if="[i.type, i.formType].includes('select')"
              v-model="form[i.value]"
              :require="i.require"
              :placeholder="i.placeholder"
              :multiple="i.multiple"
              size="small"
              clearable
              :disabled="i.readonly || i.disabled"
              :filterable="i.filterable || i.remote"
              :remote="i.remote"
              :remote-method="(val) => i.remoteMethod(val, i, j)"
              v-bind="i.bind"
              @change="onChange(i, form)"
            >
              <el-option
                v-for="(x, y) in (i.formOptions || i.options)"
                :label="x.label !== undefined ? x.label : x[i.labelKey]"
                :value="x.value !== undefined ? x.value : x[i.valueKey]"
                :key="y"
              ></el-option>
            </el-select>

            <el-switch
              v-if="i.type === 'switch'"
              v-model="form[i.value]"
              :disabled="i.readonly || i.disabled"
              size="small"
              @change="onChange(i, form)"
              :active-text="i.activeText"
              :inactive-text="i.inactiveText"
            ></el-switch>

            <el-checkbox-group
              v-if="[i.type, i.formType].includes('checkbox')"
              size="small"
              v-model="form[i.value]"
              @change="onChange(i, form)"
            >
              <el-checkbox
                :label="x.value"
                :key="y"
                v-for="(x, y) in (i.formOptions || i.options)"
                :disabled="i.readonly || i.disabled"
              >{{ x.name }}
              </el-checkbox>
            </el-checkbox-group>

            <el-radio-group
              v-if="(i.formType === undefined ? i.type : i.formType) === 'radio'"
              size="small"
              v-model="form[i.value]"
              @change="onChange(i, form)"
            >
              <el-radio
                :label="x.value"
                :key="y"
                v-for="(x, y) in (i.formOptions || i.options)"
                :disabled="i.readonly || i.disabled"
                >{{ x.name }}</el-radio
              >
            </el-radio-group>

            <el-date-picker
              v-if="(i.formType === undefined ? i.type : i.formType) === 'date'"
              v-model="form[i.value]"
              :type="i.connect ? 'daterange' : 'date'"
              range-separator="至"
              :start-placeholder="i.connect && '开始日期'"
              :end-placeholder="i.connect && '结束日期'"
              :value-format="i.valueFormat || 'timestamp'"
              :picker-options="i.options || {}"
              :disabled="i.readonly || i.disabled"
              :default-time="i.connect ? ['00:00:00', '23:59:59'] : '00:00:00'"
              clearable
              editable
            >
            </el-date-picker>

            <el-date-picker
              v-if="(i.formType === undefined ? i.type : i.formType) === 'datetime'"
              v-model="form[i.value]"
              :type="i.connect ? 'datetimerange' : 'datetime'"
              range-separator="至"
              :start-placeholder="i.connect && '开始日期'"
              :end-placeholder="i.connect && '结束日期'"
              :value-format="i.valueFormat || 'timestamp'"
              :picker-options="i.options || {}"
              :disabled="i.readonly || i.disabled"
              :default-time="i.connect ? ['00:00:00', '23:59:59'] : '00:00:00'"
              clearable
              editable
            >
            </el-date-picker>

            <el-cascader
              v-if="i.type === 'cascader'"
              size="small"
              v-model="form[i.value]"
              :options="(i.formOptions || i.options)"
              :props="i.props"
              :placeholder="i.placeholder"
              :disabled="i.readonly || i.disabled"
              clearable
            ></el-cascader>

            <slot
              v-if="(i.formType === undefined ? i.type : i.formType) === 'slot'"
              :name="i.value"
              v-bind:i="i"
              v-bind:form="form"
            ></slot>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button v-if="!hideButton" type="primary" size="small" @click="onSubmit">
            {{ primaryButtonText || "保 存" }}
          </el-button>
          <el-button v-if="!hideBack" type="default" size="small" @click="$router.back()">
            返 回
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'FormComponent',
  props: {
    title: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    },
    column: {
      type: Array,
      default: () => []
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    hideBack: {
      type: Boolean,
      default: false
    },
    primaryButtonText: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      refForm: null,
      form: {}
    }
  },
  watch: {
    formData () {
      this.init()
    }
  },
  mounted () {
    this.refForm = this.$refs.form
    this.init()
  },
  methods: {
    /**
     * 判断类型
     * @param data 数据
     * @param type 判断类型
     * @returns { Boolean }
     */
    judgmentType (data, type) {
      const test = /^\[object +(\S*)+\]$/
      const typeStr = Reflect.toString.call(data)
      const match = typeStr.match(test)
      return match && match[1] === type
    },

    init () {
      const form = {}
      this.column.forEach(item => {
        if (!this.judgmentType(item.hideForm, 'Function') && item.hideForm && item.value !== 'id') return
        let value = this.formData[item.value]
        if (item.formType === 'select' && item.multiple) {
          value = this.formData[item.value] === undefined ? [] : this.formData[item.value]
        }
        if (item.formType === 'inputNumber') {
          value = this.formData[item.value] === undefined ? 0 : this.formData[item.value]
        }
        form[item.value] = value
      })
      this.form = form
    },
    // 重置表单
    resetForm () {
      this.refForm.resetFields()
    },
    onChange (item, form) {
      this.$emit('onChange', item, form)
    },
    onSubmit () {
      this.refForm.validate((validate) => {
        if (!validate) return
        const form = { ...this.form }
        this.column.forEach((item) => {
          if (Reflect.has(form, item.value)) {
            if (['date', 'datetime'].includes(item.formType)) {
              form[item.value] = +(new Date(form[item.value]))
            }
          }
        })
        this.$emit('onSubmit', form)
      })
    }
  }
}
</script>

<style scoped>
.form-component form {
  max-width: 600px;
  margin: 0 auto;
}
.form-component .el-form-item {
  margin-bottom: 18px;
}
.form-component .el-select,
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
