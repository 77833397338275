<template>
  <div class="login-box">
    <div class="login-logo">
      <strong>商业智能平台</strong>
    </div>
    <div class="login-body">
      <p class="login-title">The Login Window For BI Platform</p>
      <div class="login-button">
        <el-button type="primary" @click="login">统一授权登录</el-button>
      </div>
      <div class="forbid-box" v-show="systemForbid">
        <el-alert title="警告" :description="forbidMsg" type="warning" effect="dark" show-icon></el-alert>
      </div>
    </div>
  </div>
</template>

<script>
import p from '@/common/privilege'

export default {
  data () {
    return {
      form: {},
      systemForbid: false,
      forbidMsg: '您没有此系统权限，请联系管理员！'
    }
  },
  created () {
    this.checkLogin()
  },
  methods: {
    checkLogin () {
      let query = this.$route.query
      if (!query) {
        return
      }
      if (query.hasOwnProperty('f') && query.f === p.ForbidNonce) {
        this.systemForbid = true
        this.$message.error(this.forbidMsg)
        return
      }
      if (query.hasOwnProperty('access_token')) {
        let accessToken = query.access_token
        let resValues = query['res_values']
        // console.log(query, typeof resValues, resValues, accessToken)
        if (!resValues || resValues === '[]' || !accessToken) {
          const redirectUri = encodeURIComponent(window.location.origin + '#/login?f=' + p.ForbidNonce)
          window.location.href = p.OAuthExitUrl + '?redirect_uri=' + redirectUri
        } else {
          this.$store.dispatch('ThirdLogin', accessToken).then(() => {
            this.$router.push({ path: '/' })
          })
        }
      }
    },
    login () {
      this.systemForbid = false
      const redirectUri = encodeURIComponent(window.location.origin + '#/login?')
      window.location.href = p.OAuthUrl + '?client_id=bi&response_type=token&redirect_uri=' + redirectUri
    }
  }
}
</script>

<style scoped>
  .login-box {
    margin: 120px auto 0;
    width: 360px;
    text-align: center;
  }
  .login-logo {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .login-body {
    background-color: white;
    padding: 20px;
  }
  .login-title {
    font-size: 14px;
    margin: 0 0 10px;
  }
  .login-button {
    margin: 20px 0 10px;
  }
  .login-button button {
    display: block;
    width: 100%;
  }
  .forbid-box {
    margin: 10px 0;
    text-align: left;
  }
</style>
