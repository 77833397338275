<template>
  <div class="charts-canvas bg-white"></div>
</template>

<script>
import util from '@/common/util'
import f from '@/filter'
require('echarts/theme/macarons')
export default {
  name: 'ChartLineBar',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    let _this = this
    this.$nextTick(() => {
      this.initChart()
    })
    function handle () {
      if (_this.chart) {
        _this.chart.resize()
      }
    }
    window.addEventListener('resize', util.debounce(handle, 500))
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = this.$echarts.init(this.$el, 'macarons')
      this.setOptions(this.data)
    },
    setOptions (val) {
      this.chart.clear()
      let targets = val.targets || [] // 左Y轴图类型
      let targets2 = val.targets2 || [] // 右Y轴 数据
      let type = val.type || 'bar' // 左Y轴图类型
      let stack = val.stack // stack: '' 添加这个属性，表示是否堆叠
      let xAxisKey = val.xAxisKey || 'date'
      let data = util.convertToChartDataByTargets([...targets, ...targets2], val.data, xAxisKey)
      let xAxisData = data[xAxisKey]
      if (xAxisData) {
        xAxisData = xAxisData.map(v => f.autoFormat(xAxisKey, v))
      }
      let legendData = []
      let seriesData = []
      // 左Y轴图类型
      for (let t of targets) {
        legendData.push(t.label)
        let tdv = data[t.value]
        if (!tdv) {
          continue
        }
        let item = { name: t.label, type: type, data: tdv }
        if (stack) {
          item.stack = stack
        } else {
          item.markPoint = { data: [{ type: 'max', name: '最大值' }, { type: 'min', name: '最小值' }] }
          item.markLine = { data: [{ type: 'average', name: '平均值' }] }
        }
        seriesData.push(item)
      }
      let yAxis = [{ type: 'value' }]
      let type2 = type === 'bar' ? 'line' : 'bar' // 右Y轴图类型
      let stack2 = val.stack2 // 右Y轴图是否堆叠
      // 右Y轴 数据
      if (targets2) {
        yAxis = val.yAxis || [{ type: 'value' }, { type: 'value' }]
        for (let t of targets2) {
          legendData.push(t.label)
          let tdv = data[t.value]
          if (!tdv) {
            continue
          }
          let item = { name: t.label, type: type2, yAxisIndex: 1, data: tdv }
          if (stack2) {
            item.stack = stack2
          } else {
            item.markPoint = { data: [{ type: 'max', name: '最大值' }, { type: 'min', name: '最小值' }] }
            item.markLine = { data: [{ type: 'average', name: '平均值' }] }
          }
          seriesData.push(item)
        }
      }
      this.chart.setOption({
        // title: {
        //   text: val.title || ''
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: legendData,
          left: this.isMobile ? '5%' : 'center'
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: yAxis,
        series: seriesData
      })
    }
  }
}
</script>
