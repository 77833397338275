<template>
  <section>
<!--    <bi-channel-date-search @change="fetchData"></bi-channel-date-search>-->
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="tabActive" @tab-click="handleChart1Click">
            <el-tab-pane :label="t.label" :name="t.value" v-for="t in keepTargets" :key="t.value"></el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chart1.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart1"></chart-line-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chart2.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart2"></chart-line-bar>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-show="!isOpenServer">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart3Tab" @tab-click="handleChart3Click">
            <el-tab-pane :label="t.label" :name="t.value" v-for="t in keepTargets" :key="t.value"></el-tab-pane>
          </el-tabs>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="8" v-for="item in charts" :key="item.key">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="item.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-pie :data="item" v-if="item.key === '3'"></chart-pie>
          <chart-line-bar :data="item" v-else></chart-line-bar>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import ChartPie from '@/components/Charts/ChartPie'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'

export default {
  components: {
    BiChannelDateSearch,
    ChartLineBar,
    ChartPie
  },
  data () {
    let kt = option.keepType
    let krt = option.keepRateType
    let keepTargets = [kt.k2, kt.k3, kt.k4, kt.k5, kt.k6, kt.k7, kt.k14, kt.k30, kt.k45, kt.k60, kt.k90, kt.k120, kt.k180]
    let keepRateTargets = [krt.keep2, krt.keep3, krt.keep4, krt.keep5, krt.keep6, krt.keep7, krt.keep14, krt.keep30, krt.keep45, krt.keep60, krt.keep90, krt.keep120, krt.keep180]
    return {
      isOpenServer: false,
      keepTargets: keepTargets,
      keepRateTargets: keepRateTargets,
      tabActive: keepTargets[0].value,
      chart1: { title: '用户留存', targets: [keepRateTargets[0]], data: {}, type: 'line' },
      chart2: { title: '用户留存-渠道分布', targets: [keepTargets[0]], xAxisKey: 'channel', data: {} },
      chart3Tab: keepTargets[0].value,
      charts: [
        { key: '1', title: '新增日等级分布', targets: [{ label: '新增日等级分布', value: '1' }], xAxisKey: 'level', data: {}, type: 'line' },
        { key: '2', title: '新增日游戏次数', targets: [{ label: '新增日游戏次数', value: '2' }], xAxisKey: 'loginCount', data: {}, type: 'line' },
        { key: '3', title: '新增日是否付费', targets: [{ label: '新增日是否付费', value: '3' }], xAxisKey: 'paid', data: {}, formatOptions: [{ value: '0', label: '否' }, { value: '1', label: '是' }] },
        { key: '4', title: '周期内等级分布', targets: [{ label: '周期内等级分布', value: '4' }], xAxisKey: 'level', data: {}, type: 'line' },
        { key: '5', title: '周期内付费金额', targets: [{ label: '周期内付费金额', value: '5' }], xAxisKey: 'payRange', data: {} },
        { key: '6', title: '周期内付费次数', targets: [{ label: '周期内付费次数', value: '6' }], xAxisKey: 'payCount', data: {} }
      ]
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.handleChart1Click()
      if (!this.isOpenServer) {
        this.handleChart3Click()
      }
    },
    handleChart1Click () {
      for (let i in this.keepTargets) {
        let t = this.keepTargets[i]
        if (t.value === this.tabActive) {
          this.chart1.targets = [this.keepRateTargets[i]]
          this.chart2.targets = [t]
        }
      }
      let params = { target: this.tabActive, dist: 'channel' }
      api.getMatrixTargetData(this.isOpenServer, this.tabActive, params).then(res => {
        this.chart1.data = res
        let d = {}
        d[this.tabActive] = res.dist || []
        this.chart2.data = d
      })
    },
    handleChart3Click () {
      for (let item of this.charts) {
        let analysisType = item.key
        api.getKeepAnalysis(analysisType, this.chart3Tab, this.isOpenServer).then(res => {
          let obj = {}
          obj[analysisType] = res
          item.data = obj
        })
      }
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
