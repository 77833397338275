<template>
  <div class="bg space-between pad">
    <font-awesome-icon :icon="icon" size="4x" :style="{color: fontColor}"/>
    <div class="right-content" style="width: 100%">
      <div style="text-align: right;width: 100%" class="text">
        <h4 style="color: #8C8C8C">{{label}}</h4>
      </div>
      <span style="text-align: right;width: 100%;display: block">{{value ? value : 0}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: { // 原始数据
    label: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    fontColor: {
      type: String,
      default: '#73C3FF'
    }
  }
}
</script>

<style scoped>
  h4 {
    padding: 0;
    margin: 0;
  }
  .bg {
    height: 100%;
    background: #ffffff;
    margin-top: 10px;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
  }
  .pad {
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
  }
  .text {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
</style>
