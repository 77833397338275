export const FilterColumn = [
  { label: '', value: 'channels', type: 'slot' },
  { label: '', value: 'st', connect: 'et', type: 'date', clearable: false },
  { label: '', value: 'action', type: 'action' }
]

export const TableColumn = [
  { label: '日期', value: 'date', type: 'text' },
  { label: '回流老用户（活跃）', value: 'loginUser', type: 'text', bind: { sortable: true } },
  { label: '回流老用户（新增）', value: 'loginUserAdd', type: 'text', bind: { sortable: true } },
  { label: '回流付费数', value: 'payUser', type: 'text', bind: { sortable: true } },
  { label: '回流充值金额', value: 'payMoney', type: 'text', bind: { sortable: true } },
  { label: '回流付费率', value: 'payRate', type: 'text', bind: { sortable: true } },
  { label: '回流ARPU', value: 'arpu', type: 'text', bind: { sortable: true } }
]
