<template>
<section>
    <el-card >
        <h1>{{ this.$route.name }}</h1>
        <Table
        :border="true"
        stripe
        :tableData="tableData"
        :column="tableColumn"
        :max-height="clientHeight * 0.8"
      />
    </el-card>
</section>
</template>

<script>
import Table from '@/components/Layout/Table.vue'
import { TableColumn } from './config'
import api from '@/api/fee'
export default {
  name: 'PackageDetailsTable',
  components: {
    Table
  },
  computed: {
    clientHeight () { return document.body.clientHeight * 0.9 },
    appId () {
      return this.$store.getters.appId
    },
    tableColumn () { return [...TableColumn] }
  },
  data () {
    return {
      page: { page: 0, size: 10 },
      tableData: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const result = await api.getChannelPackages({
        appId: this.appId
      })
      if (!result.length) {
        this.$message({
          message: '暂无数据',
          type: 'warning'
        })
      }
      this.tableData = result.length ? result : []
    }
  }
}
</script>
