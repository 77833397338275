<template>
  <div class="bg-white charts-canvas"></div>
</template>

<script>
import util from '@/common/util'
import f from '@/filter'
require('echarts/theme/macarons')

export default {
  name: 'ChartPieNest',
  props: {
    data: { // 原始数据
      type: Object,
      required: true
    }
    // title: {
    //   type: String,
    //   required: true
    // },
    // targets: {
    //   type: Array,
    //   required: true
    // },
    // name: {
    //   type: String,
    //   required: true
    // }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
    let _this = this
    function handle () {
      if (_this.chart) {
        _this.chart.resize()
      }
    }
    window.addEventListener('resize', util.debounce(handle, 500))
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = this.$echarts.init(this.$el, 'macarons')
      this.setOptions(this.data)
    },
    setOptions (val) {
      this.chart.clear()
      if (!val) {
        return
      }
      let title = ''
      let targets = val.targets || []
      let chartData = val.data
      let name = val.name || ''
      let distKey = val.distKey || 'channel'
      if (!chartData || targets.length <= 0) {
        return
      }
      let data = util.convertToChartDataByTargets(targets, chartData)
      let legendData = [] // 头顶的指标快捷选项
      let pieData = [] // 饼内数据
      let loopData = [] // 环数据
      for (let t of targets) {
        let datum = data[t.value]
        if (!datum || !datum.length) {
          continue
        }
        legendData.push(t.label)
        pieData.push({ name: t.label, value: datum.reduce((preVal, curVal) => preVal + curVal) })
      }
      if (chartData.dist) {
        for (let item of chartData.dist) {
          let distName = f.autoFormat(distKey, item[distKey])
          // legendData.push(channel)
          loopData.push({ name: distName, value: item.value })
        }
      }
      this.chart.setOption({
        // title: {
        //   text: this.title || ''
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          x: 'left',
          top: '0',
          data: legendData
        },
        series: [
          {
            name: title,
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '40%'],
            label: {
              normal: {
                position: 'inner'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: pieData
          },
          {
            name: name,
            type: 'pie',
            radius: ['50%', '65%'],
            label: {
              normal: {
                // show: false,
                position: 'outside',
                // formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                formatter: '{b}: {c} ({d}%)'

              }
            },
            data: loopData
          }
        ]
      })
    }
  }
}
</script>
