<template>
  <el-button v-bind="$attrs" type="success" icon="el-icon-download" @click="handleDownload" :loading="downloadLoading">导出Excel</el-button>
</template>

<script>
import f from '@/filter'

export default {
  name: 'ExportExcel',
  props: {
    data: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    filename: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      downloadLoading: false
    }
  },
  methods: {
    handleDownload () {
      this.downloadLoading = true
      import('@/common/Export2Excel').then(excel => {
        const tHeader = []
        const filterVal = []
        for (let h of this.headers) {
          tHeader.push(h.label)
          filterVal.push(h.value)
        }
        const list = this.data
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType
        })
        this.downloadLoading = false
      })
    },
    formatJson (filterVal, jsonData) {
      let data = jsonData.map(v => {
        return filterVal.map(j => {
          if (v.data) {
            return f.autoFormat(j, v.data[j])
          } else {
            return f.autoFormat(j, v[j])
          }
        })
      })
      return data
    }
  }
}
</script>

<style scoped>

</style>
