<template>
<section>
  <div class="bi-channel-date-search">
    <el-form :inline="true">
      <el-form-item>
        <el-date-picker v-model="st" type="date" value-format="yyyy-MM-dd" ref="startTimePicker"  @change="startTimeChange" placeholder="选择开始日期"> </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="et" type="date" value-format="yyyy-MM-dd" ref="endTimePicker" @change="endTimeChange" placeholder="选择截至日期"> </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="createStartTime" type="date" value-format="yyyy-MM-dd" ref="createStartTimePicker"  @change="createStartTimeChange" placeholder="注册起始时间"> </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="createEndTime" type="date" value-format="yyyy-MM-dd" ref="createEndTimePicker" @change="createEndTimeChange" placeholder="注册结束时间"> </el-date-picker>
      </el-form-item>
      <el-form-item>
        <channel-cascade :multiple="true"></channel-cascade>
      </el-form-item>
      <el-form-item>
        <server-select></server-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="range" placeholder="请选择充值区间" clearable>
          <el-option value="" label="不限制充值区间"></el-option>
          <el-option v-for="(item, i) in rangeOptions" :key="i" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchData" :loading="loading">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="box">
<!--    <el-alert title="TOP 10" type="warning" :closable="false" show-icon> </el-alert>-->
    <!--分页器-->
    <div class="flex align-center">
      <div class="execl">
        <export-excel :button="{type: 'warning', size: 'mini'}" :data="tableData" :headers="tableList"></export-excel>
      </div>
      <el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.page"
                     :page-sizes="pageSizes" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="用户ID" fixed>
        <template slot-scope="scope">
          <el-button @click="handleSearchRoleList(scope.row.userId)" type="text">
            {{scope.row.userId}}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :prop="item.value" :label="item.label" v-for="item in tableList" :key="item.value" :formatter="autoFormatter"> </el-table-column>
    </el-table>
    <!--分页器-->
    <div class="block" style="float: left">
      <el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.page"
                     :page-sizes="pageSizes" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>
    </div>
  </div>
  <el-dialog :visible.sync="dialogTableVisible" :width="isMobile ? '100%' : '65%'" :title="title">
    <el-table :data="roleListData" style="width: 100%">
      <el-table-column :prop="item.value" :label="item.label" v-for="item in roleList" :key="item.value" :formatter="autoFormatter"> </el-table-column>
    </el-table>
  </el-dialog>
</section>
</template>

<script>
// import DateRange from '@/components/DateRange'
import ChannelCascade from '@/components/Channel/ChannelCascade'
import ExportExcel from '@/components/Excel/ExportExcel'
import ServerSelect from '@/components/Server/ServerSelect'
// import option from '@/common/option'
import api from '@/api/bi'
import f from '@/filter'
export default {
  name: 'PlayerWhale',
  components: {
    // DateRange,
    ChannelCascade,
    ExportExcel,
    ServerSelect
  },
  computed: {
    serverId () {
      return this.$store.getters.serverId
    },
    isMobile () {
      return this.$store.getters.isMobile
    }
  },
  data () {
    let tableList = [
      { value: 'userId', label: '用户ID' },
      { value: 'money', label: '角色充值(元)' },
      { value: 'totalMoney', label: '账号总充值' },
      { value: 'channelId', label: '渠道ID' },
      { value: 'channelUserId', label: '渠道用户ID' },
      { value: 'serverId', label: '服务器ID' },
      { value: 'roleId', label: '角色ID' },
      { value: 'roleName', label: '角色名称' }
    ]
    let roleList = [
      { value: 'money', label: '角色充值(元)' },
      { value: 'channelId', label: '渠道ID' },
      { value: 'serverId', label: '服务器ID' },
      { value: 'roleId', label: '角色ID' },
      { value: 'roleName', label: '角色名称' },
      { value: 'roleLevel', label: '角色等级' },
      { value: 'createTime', label: '创建时间' },
      { value: 'lastLoginTime', label: '最后登录时间' },
      { value: 'model', label: '设备' },
      { value: 'region', label: '地区' },
      { value: 'fromServerId', label: '滚服来源' }
    ]
    let rangeOptions = [
      // ...option.payRangeOptions.filter(item => item.value !== '8'),
      // { value: '8', label: '[5k, 10k)' },
      // { value: '9', label: '[10k, +)' }
      { value: JSON.stringify({ minMoney: 0, maxMoney: 10 }), label: '[0, 10)' },
      { value: JSON.stringify({ minMoney: 10, maxMoney: 50 }), label: '[10, 50)' },
      { value: JSON.stringify({ minMoney: 50, maxMoney: 100 }), label: '[50, 100)' },
      { value: JSON.stringify({ minMoney: 100, maxMoney: 200 }), label: '[100, 200)' },
      { value: JSON.stringify({ minMoney: 200, maxMoney: 500 }), label: '[200, 500)' },
      { value: JSON.stringify({ minMoney: 500, maxMoney: 1000 }), label: '[500, 1k)' },
      { value: JSON.stringify({ minMoney: 1000, maxMoney: 5000 }), label: '[1k, 5k)' },
      { value: JSON.stringify({ minMoney: 5000, maxMoney: 10000 }), label: '[5k, 10k)' },
      { value: JSON.stringify({ minMoney: 10000, maxMoney: 10000000 }), label: '[10k, +)' }
    ]
    return {
      st: '',
      et: '',
      createStartTime: '',
      createEndTime: '',
      title: '',
      pagination: {
        total: 0,
        size: 0,
        page: 1
      },
      pageSizes: [10, 20, 30, 50, 100],
      rangeOptions: rangeOptions,
      tableList: tableList,
      roleList: roleList,
      range: '',
      loading: false,
      dialogTableVisible: false,
      tableData: [],
      roleListData: []
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      this.tableData = []
      let params = {
        st: this.st,
        et: this.et,
        createStartTime: this.createStartTime,
        createEndTime: this.createEndTime,
        // payRange: this.range,
        ...JSON.parse(this.range ? this.range : '{}'),
        serverId: this.serverId
      }
      params.page = this.pagination.page
      params.size = this.pagination.size
      api.getWhaleData(params).then(res => {
        this.loading = false
        if (res.empty) {
          this.$message.info('没有查询到相关数据')
          return
        }
        this.tableData = res.content
        // 总条目数
        this.pagination.total = res.totalSizes
        // 每页展示条数
        this.pagination.size = res.size
        // 当前是第几页
        this.pagination.page = res.page
      })
    },
    startTimeChange () {
      if (this.$moment(this.st).isAfter(this.et)) {
        this.et = ''
        // this.$refs.endTimePicker.focus()
      }
    },
    endTimeChange () {
      if (this.$moment(this.st).isAfter(this.et)) {
        this.st = ''
        // this.$refs.startTimePicker.focus()
      }
    },
    createStartTimeChange () {
      if (this.$moment(this.createStartTime).isAfter(this.createEndTime)) {
        this.createEndTime = ''
        // this.$refs.createEndTimePicker.focus()
      }
    },
    createEndTimeChange () {
      if (this.$moment(this.createStartTime).isAfter(this.createEndTime)) {
        this.createStartTime = ''
        // this.$refs.createStartTimePicker.focus()
      }
    },
    handleSearchRoleList (userId) {
      this.roleListData = []
      api.getUserRoleList(userId).then(res => {
        if (!res.length) {
          this.$message.info('没有查询到角色相关数据')
          return
        }
        this.roleListData = res
        this.title = `【${userId}】角色列表`
        this.dialogTableVisible = true
      })
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    },
    // 每页条数改变的时候触发
    handleSizeChange (val) {
      this.pagination.size = val
      this.fetchData()
    },
    // 当前页改变的时候触发
    handleCurrentChange (val) {
      this.pagination.page = val
      this.fetchData()
    }
  }
}
</script>

<style scoped>
  .el-form-item {
    margin-bottom: 0;
  }
  .flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .execl {
    height: fit-content;
    margin-right: 10px;
  }
</style>
