<template functional>
  <section class="table-functional">
    <el-table
      :data="props.data"
      show-summary
      style="width: 100%"
      :summary-method="listeners.summaryMethod"
      v-bind="data.attrs"
    >
      <template v-for="(item, index) in props.cloumn">
        <template v-if="item.type === 'hash'">
          <el-table-column
            :key="index"
            :align="item.align === undefined ? 'center' : item.align"
            :prop="item.value"
            :label="item.label"
            v-bind="item.bind"
          >
            <template slot-scope="{ row }">
              <span>
                  {{ item.options[row[item.value]] }}
              </span>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          v-if="item.type !== 'hash'"
          :key="index"
          :align="item.align === undefined ? 'center' : item.align"
          :label="item.label"
          :prop="item.value"
          v-bind="item.bind"
        >
        </el-table-column>
      </template>
    </el-table>
  </section>
</template>

<script>
export default {
  name: 'TableFunctional',
  props: {
    cloumn: {
      type: Array,
      required: true,
      default: () => []
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style scoped>
.table-functional {
  width: 100%;
}
</style>
