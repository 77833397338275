<template>
    <el-cascader :size="size || 'default'" v-model="channelCascadeIds" :options="channelCascadeOptions" :props="props" :show-all-levels="false" @change="handleChange" clearable collapse-tags filterable></el-cascader>
</template>

<script>
export default {
  name: 'ChannelCascade',
  props: {
    multiple: { // 多选
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    channelCascadeOptions () {
      return this.$store.getters.channelCascadeOptions
    }
  },
  data () {
    return {
      channelCascadeIds: this.$store.getters.channelCascadeIds,
      props: { multiple: this.multiple },
      lastHasAll: false
    }
  },
  created () {
    let length = this.channelCascadeIds.length
    if (length > 0) {
      let first = this.channelCascadeIds[0]
      this.lastHasAll = first && first.length > 0 && first[0] === 0
    }
  },
  methods: {
    handleChange (val) {
      let length = val.length
      if (length <= 0) {
        this.lastHasAll = false
        this.$store.dispatch('ChangeChannelCascadeIds', this.channelCascadeIds)
        return
      }
      let first = val[0]
      let hasAll = first && first.length > 0 && first[0] === 0
      if (hasAll) {
        if (this.lastHasAll) {
          this.$delete(this.channelCascadeIds, 0)
        } else {
          this.channelCascadeIds = [[0]]
        }
      }
      this.lastHasAll = hasAll
      this.$store.dispatch('ChangeChannelCascadeIds', this.channelCascadeIds)
    }
  }
}
</script>
