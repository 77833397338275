<template>
  <el-form :inline="true">
    <el-form-item>
      <el-date-picker
        :picker-options="type === 'date' ? pickerOptions(0) : null"
        ref="startTimePicker"
        :clearable = false
        :editable = false
        v-model="timeValue[0]"
        value-format="yyyy-MM-dd"
        :type="type"
        placeholder="选择日期"
        :style="{marginBottom: isMobile ? '10px' : 0}"
        @change="startTimeChange">
      </el-date-picker>
    </el-form-item>
    <el-form-item v-if="!isMobile">-</el-form-item>
    <el-form-item>
      <el-date-picker
        :picker-options="type === 'date' ? pickerOptions(1) : null"
        ref="endTimePicker"
        :clearable = false
        :editable = false
        v-model="timeValue[1]"
        :type="type"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        :style="{marginBottom: isMobile ? '10px' : 0}"
        @change="endTimeChange">
      </el-date-picker>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'DateRange',
  props: {
    saveValue: {
      type: Array
    },
    type: {
      default: 'date'
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    }
  },
  data () {
    return {
      timeValue: this.$store.getters.dataPickerValue
    }
  },
  created () {
    if (!this.timeValue || this.timeValue.length < 2) {
      this.handleShortcutsClick(-6)
    }
  },
  methods: {
    setTime () {
      this.$store.dispatch('ChangeDataPickerValue', this.timeValue).then(() => {})
      this.$emit('getTime', this.timeValue)
    },
    startTimeChange () {
      if (this.$moment(this.timeValue[0]).isAfter(this.timeValue[1])) {
        this.timeValue = [this.timeValue[0], this.timeValue[0]]
        this.$refs.endTimePicker.focus()
      }
      this.setTime()
    },
    endTimeChange () {
      if (this.$moment(this.timeValue[0]).isAfter(this.timeValue[1])) {
        this.timeValue = [this.timeValue[1], this.timeValue[1]]
        this.$refs.startTimePicker.focus()
      }
      this.setTime()
    },
    handleShortcutsClick (days) {
      let startTime = this.$moment().add(days, 'days').format('YYYY-MM-DD')
      let endTime = this.$moment().add(days === -1 ? -1 : 0, 'days').format('YYYY-MM-DD')
      this.timeValue = [startTime, endTime]
      this.setTime()
    },
    pickerOptions (index) {
      const vm = this
      return {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', vm.timeValue[index])
            vm.handleShortcutsClick(0)
          }
        }, {
          text: '昨天',
          onClick (picker) {
            picker.$emit('pick', vm.timeValue[index])
            vm.handleShortcutsClick(-1)
          }
        }, {
          text: '近7天',
          onClick (picker) {
            picker.$emit('pick', vm.timeValue[index])
            vm.handleShortcutsClick(-6)
          }
        }, {
          text: '近14天',
          onClick (picker) {
            picker.$emit('pick', vm.timeValue[index])
            vm.handleShortcutsClick(-13)
          }
        }, {
          text: '近30天',
          onClick (picker) {
            picker.$emit('pick', vm.timeValue[index])
            vm.handleShortcutsClick(-29)
          }
        }]
      }
    }
  }
}
</script>
<style scoped>
  .el-form-item {
    margin-bottom: 0;
  }
  .el-picker-panel .el-picker-panel__sidebar {
    height: 40px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    line-height: 40px;
  }
  .el-picker-panel [slot=sidebar]+.el-picker-panel__body, .el-picker-panel__sidebar+.el-picker-panel__body {
    margin-top: 40px;
    margin-left: 0 !important;
  }
  .el-picker-panel .el-picker-panel__shortcut {
    width: auto;
    float: left !important;
  }
  /*日期组件的宽度*/
  .el-date-picker.has-sidebar {
    width: 328px
  }
</style>
