<template>
  <section>
<!--    <bi-channel-date-search @change="fetchData"></bi-channel-date-search>-->
    <bi-channel-date-search @change="fetchData" :isGame="false" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="tabs.active" @tab-click="handleTabClick">
            <el-tab-pane :label=option.label :name="`${index}`" v-for="(option, index) in tabs.options" v-bind:key=option.value></el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="charts[0][0].chartData.title" name="tab1"></el-tab-pane>
          </el-tabs>
<!--          <chart-bar :data="charts[0][0].chartData.data" :targets="charts[0][0].chartData.targets" :xAxisKey="charts[0][0].chartData.xAxisKey"></chart-bar>-->
          <chart-line-bar :data="charts[0][0].chartData"></chart-line-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-for="(item, i) in charts[1]" :key=i>
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="item.chartData.title" name="tab1"></el-tab-pane>
          </el-tabs>
<!--          <chart-bar :data="item.chartData.data" :targets="item.chartData.targets" :xAxisKey="item.chartData.xAxisKey"></chart-bar>-->
          <chart-pie :data="item.chartData" v-if="item.isPie"></chart-pie>
          <chart-line-bar :data="item.chartData" v-else></chart-line-bar>
        </div>
      </el-col>
    </el-row>

<!--    <el-row style="margin-top: 12px" :gutter=12 v-for="(row, rowIndex) in charts" v-bind:key=rowIndex>-->
<!--      <el-col :span=24/row.length v-for="chart in row" v-bind:key=chart.id>-->
<!--        <chart-bar :title="chart.chartData.title" :data="chart.chartData.data" :targets="chart.chartData.targets" :xAxisKey="chart.chartData.xAxisKey"></chart-bar>-->
<!--      </el-col>-->
<!--    </el-row>-->
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
// import ChartBar from '@/components/Charts/ChartBar'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import ChartPie from '@/components/Charts/ChartPie'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'
export default {
  name: 'PlayerDevice',
  components: {
    BiChannelDateSearch,
    ChartLineBar,
    ChartPie
  },
  data () {
    let target = option.targetType
    return {
      isOpenServer: false,
      tabs: {
        active: '0',
        options: [
          target.loginUserAdd,
          target.loginUser,
          target.payUser
        ]
      },
      charts: [
        [
          {
            id: Math.random(),
            apiFunc: api.getTargetModel,
            chartData: { title: '机型', data: {}, xAxisKey: 'model', targets: [target.loginUserAdd] }
          }
        ],
        [
          {
            id: Math.random(),
            apiFunc: api.getTargetResolution,
            chartData: { title: '分辨率', data: {}, xAxisKey: 'resolution', targets: [target.loginUserAdd] }
          },
          {
            id: Math.random(),
            apiFunc: api.getTargetOS,
            isPie: true,
            chartData: { title: '操作系统图', data: {}, xAxisKey: 'os', targets: [target.loginUserAdd] }
          }
        ]
      ]
    }
  },
  created () {
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    handleTabClick () {
      this.fetchData()
    },
    fetchData () {
      const target = this.tabs.options[parseInt(this.tabs.active)]
      for (let i in this.charts) {
        for (let j in this.charts[i]) {
          const data = this.charts[i][j]
          data.apiFunc(target.value, this.isOpenServer).then(res => {
            let chartData = Object.assign({}, data.chartData, { targets: [target] })
            chartData.data = {}
            chartData.data[target.value] = res
            data.chartData = chartData
            data.id = Math.random()
          })
        }
      }
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
