<template>
    <section>
      <div class="bi-channel-date-search">
        <el-form :inline="true">
          <el-form-item>
            <el-input v-model.number="phoneNo" placeholder="用户手机号" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model.number="userId" placeholder="用户ID" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <channel-select></channel-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="channelUserId" placeholder="渠道用户ID" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="fetchData" :loading="loading">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="box">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column width="100" label="用户ID" fixed>
            <template slot-scope="scope">
              <el-button @click="handleSearchRoleList(scope.row.userId)" type="text">{{scope.row.userId}}</el-button>
            </template>
          </el-table-column>
          <el-table-column :prop="item.value" :label="item.label" v-for="item in tableList" :key="item.value" :formatter="autoFormatter"> </el-table-column>
          <el-table-column label="迁移">
            <template slot-scope="scope">
              <el-button @click="handleTransfer(scope.row)" type="text">迁移</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="box" v-show="roleListData.length">
        <el-table :data="roleListData" style="width: 100%">
          <el-table-column :prop="item.value" :label="item.label" v-for="item in roleList" :key="item.value" :formatter="autoFormatter"> </el-table-column>
        </el-table>
      </div>
<!--      账号迁移二次确认信息dialog-->
      <el-dialog title="确认信息" :visible.sync="dialogTransferVisible" center>
        <el-table :data="transferData">
          <el-table-column prop="userId" label="用户ID" width="120"> </el-table-column>
          <el-table-column prop="channelId" label="渠道ID" :formatter="autoFormatter"> </el-table-column>
          <el-table-column prop="channelUserId" label="渠道用户ID"> </el-table-column>
          <el-table-column prop="channelUserName" label="渠道用户名称"> </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTransferVisible = false">取 消</el-button>
<!--          transferData内只有一条数据，element表格不支持对象格式-->
          <el-button type="primary" @click="handleSubmitTransfer(transferData[0])">确 定</el-button>
        </div>
      </el-dialog>
<!--      账号迁移返回结果dialog-->
      <el-dialog title="返回结果" center :visible.sync="dialogResultVisible" width="30%">
        <el-input placeholder="账 号" v-model="transferResult.username" disabled style="margin-bottom: 10px">
          <template slot="prepend">账 号</template>
        </el-input>
        <el-input placeholder="密 码" v-model="transferResult.password" disabled>
          <template slot="prepend">密 码</template>
        </el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="copy">复制</el-button>
        </div>
      </el-dialog>
    </section>
</template>

<script>
import api from '@/api/bi'
import f from '@/filter'
import ChannelSelect from '@/components/Channel/ChannelSelect'

export default {
  name: 'User',
  components: {
    ChannelSelect
  },
  computed: {
    channelId () {
      return this.$store.getters.channelId
    },
    appId () {
      return this.$store.getters.appId
    }
  },
  data () {
    const tableList = [
      // { value: 'userId', label: '用户ID' },
      { value: 'money', label: '用户充值(元)' },
      { value: 'channelId', label: '渠道ID' },
      { value: 'channelUserId', label: '渠道用户ID' },
      { value: 'channelUserName', label: '渠道用户名称' },
      { value: 'createTime', label: '创建时间' },
      { value: 'lastLoginTime', label: '最后登录时间' },
      { value: 'model', label: '设备' },
      { value: 'region', label: '地区' }
    ]
    const roleList = [
      // { value: 'channelId', label: '渠道ID' },
      { value: 'money', label: '角色充值(元)' },
      { value: 'serverId', label: '服务器ID' },
      { value: 'roleId', label: '角色ID' },
      { value: 'roleName', label: '角色名称' },
      { value: 'roleLevel', label: '角色等级' },
      { value: 'model', label: '设备' },
      { value: 'createTime', label: '创建时间' },
      { value: 'lastLoginTime', label: '最后登录时间' },
      { value: 'fromServerId', label: '滚服来源' }
    ]
    return {
      userId: '',
      channelUserId: '',
      tableData: [],
      tableList: tableList,
      roleList: roleList,
      loading: false,
      roleListData: [],
      transferData: [],
      dialogTransferVisible: false,
      dialogResultVisible: false,
      transferResult: {},
      phoneNo: ''
    }
  },
  methods: {
    fetchData () {
      if (!this.userId && !this.channelUserId && !this.phoneNo) {
        this.$message.error('请输入用户ID')
        return
      }
      if (!this.userId && this.channelUserId && this.channelId === '') {
        this.$message.error('请选择渠道')
        return
      }
      this.tableData = []
      this.roleListData = []
      this.loading = true
      let params = {}
      if (this.channelId) params.channelId = this.channelId
      if (this.phoneNo) params.phoneNo = this.phoneNo
      params.channelUserId = this.channelUserId
      params.userId = this.userId
      api.getUserData(params).then(res => {
        if (!res.length) {
          this.$message.info('没有查询到相关数据')
        }
        this.tableData = res
      }).finally(() => { this.loading = false })
    },
    handleSearchRoleList (userId) {
      api.getUserRoleList(userId).then(res => {
        if (!res.length) {
          this.$message.info('没有查询到角色相关数据')
        }
        this.roleListData = res
      })
    },
    // 打开账户迁移弹窗
    handleTransfer (rowData) {
      this.transferData = [rowData]
      this.dialogTransferVisible = true
    },
    // 发送账户迁移
    handleSubmitTransfer ({ userId, channelId, channelUserId, channelUserName }) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = { userId, channelId, channelUserId, channelUserName }
      api.accountTransfer(this.appId, params).then(res => {
        this.dialogTransferVisible = false
        this.$message.success('迁移成功')
        this.transferResult = res
        this.dialogResultVisible = true
        loading.close()
      }).catch(() => {
        loading.close()
      })
    },
    // 复制迁移结果
    copy () {
      let input = document.createElement('input')
      input.value = '账号：' + this.transferResult.username + ' ' + '密码：' + this.transferResult.password
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
      this.dialogResultVisible = false
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    }
  }
}
</script>

<style scoped>
  .el-form-item {
    margin-bottom: 0;
  }
</style>
