export const FilterColumn = [
  { label: '', value: 'channels', type: 'slot' },
  { label: '', value: 'st', connect: 'et', type: 'date', clearable: false },
  { label: '', value: 'action', type: 'action' }
]

export const TableColumn = [
  { label: '日期', value: 'date', type: 'text' },
  { label: '新增老用户（活跃）', value: 'loginUser', type: 'text', bind: { sortable: true } },
  { label: '新增老用户（新增）', value: 'loginUserAdd', type: 'text', bind: { sortable: true } },
  { label: '新增老付费用户', value: 'payUserAdd', type: 'text', bind: { sortable: true } },
  { label: '新增老用户充值', value: 'payMoneyAdd', type: 'text', bind: { sortable: true } },
  { label: '新增老付费率', value: 'payRate', type: 'text', bind: { sortable: true } },
  { label: '新增老ARPU', value: 'arpu', type: 'text', bind: { sortable: true } }
]
