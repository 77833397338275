<template>
  <section>
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart1.tabActive" @tab-click="fetchChart1Data">
            <el-tab-pane label="新增用户及设备" name="tab1"></el-tab-pane>
            <el-tab-pane label="玩家转化" name="tab2" v-if="!isOpenServer"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart1.data"></chart-line-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="新增用户-渠道分布" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="charts.chart2"></chart-line-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="新增用户-等级分布" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="charts.chart3"></chart-line-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="isOpenServer">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="新增用户-服务器分布" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="charts.chart4"></chart-line-bar>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'
export default {
  components: {
    BiChannelDateSearch,
    ChartLineBar
  },
  data () {
    let t = option.targetType
    return {
      isOpenServer: false,
      defaultTarget: t.loginUserAdd,
      chart1: {
        tabActive: 'tab1',
        tab1: { title: '新增用户与设备', fetchTargets: [t.loginUserAdd, t.loginDeviceAdd], targets: [t.loginUserAdd, t.loginDeviceAdd], type: 'line' },
        tab2: { title: '玩家转化率', fetchTargets: [t.conversion], targets: [t.conversion], type: 'line' },
        data: {}
      },
      charts: {
        chart2: { title: '新增用户-渠道分布', xAxisKey: 'channel', targets: [t.loginUserAdd], apiFunc: api.getTargetChannel, data: {} },
        chart3: { title: '新增用户-等级分布', xAxisKey: 'level', targets: [t.loginUserAdd], apiFunc: api.getTargetLevel, data: {} },
        chart4: { title: '新增用户-服务器分布', xAxisKey: 'server', targets: [t.loginUserAdd], apiFunc: api.getTargetServer, data: {} }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.fetchChart1Data()
      this.fetchChartsData('chart2')
      this.fetchChartsData('chart3')
      if (this.isOpenServer) {
        this.fetchChartsData('chart4')
      }
    },
    fetchChart1Data () {
      let tab = this.chart1.tabActive
      let data = Object.assign({}, this.chart1[tab])
      api.getMultiTargetData(data.fetchTargets, null, this.isOpenServer).then(res => {
        data.data = res
        this.chart1.data = data
      })
    },
    fetchChartsData (chart) {
      let data = this.charts[chart]
      data.apiFunc(this.defaultTarget.value, this.isOpenServer).then(res => {
        let d = {}
        d[this.defaultTarget.value] = res
        data.data = d
      })
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
