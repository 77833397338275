<template>
  <section>
    <div class="bi-channel-date-search">
      <el-form :inline="true">
        <el-form-item>
          <channel-cascade :multiple="true"></channel-cascade>
        </el-form-item>
        <el-form-item>
          <server-multiple-select @serverList="getServerList"></server-multiple-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model.number="minMoney" type="number" placeholder="最小金额" @blur="minMoneyChange" ref="minMoney"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.number="maxMoney" type="number" placeholder="最大金额" @blur="maxMoneyChange" ref="maxMoney"></el-input>
        </el-form-item>
        <el-form-item>
          <date-range :type="type"></date-range>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData" :loading="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="box">
      <el-table :data="tableData.target" style="width: 100%" stripe sortable  max-height="650" show-summary :summary-method="getSummaries">
        <el-table-column  v-for="item in thList.targetList"
                          :prop="item.value"
                         :label="item.label"
                         :width="item.value === 'date'? '100' : 'auto'"
                         :fixed="item.value === 'date' || item.value === 'channel'"
                         :sortable="item.value === 'loginUserAdd' || item.value === 'loginUser' || item.value === 'payMoney' || item.value === 'date' || item.value === 'channel' || item.value === 'server'"
                         :key="item.value" :formatter="autoFormatter">
        </el-table-column>
      </el-table>
      <export-excel :data="ExcelData.target" :headers="thList.targetList" filename="指标" style="margin: 5px 0"></export-excel>
    </div>
    <div class="box">
      <el-table :data="tableData.ltv" style="width: 100%" stripe sortable  max-height="650" show-summary :summary-method="getSummaries">
        <el-table-column  v-for="item in thList.ltvList"
                        :prop="item.value"
                        :label="item.label"
                        :width="item.value === 'date'? '100' : 'auto'"
                        :fixed="item.value === 'date' || item.value === 'channel'"
                        :sortable="item.value === 'loginUserAdd' || item.value === 'loginUser' || item.value === 'payMoney' || item.value === 'date' || item.value === 'channel' || item.value === 'server'"
                        :key="item.value" :formatter="autoFormatter">
        </el-table-column>
      </el-table>
      <export-excel :data="ExcelData.ltv" :headers="thList.ltvList" filename="LTV" style="margin: 5px 0"></export-excel>
    </div>
    <div class="box">
      <el-table :data="tableData.keep" style="width: 100%" stripe sortable  max-height="650" show-summary :summary-method="getSummaries">
        <el-table-column  v-for="item in thList.keepList"
                        :prop="item.value"
                        :label="item.label"
                        :width="item.value === 'date'? '100' : 'auto'"
                        :fixed="item.value === 'date' || item.value === 'channel'"
                        :sortable="item.value === 'loginUserAdd' || item.value === 'loginUser' || item.value === 'payMoney' || item.value === 'date' || item.value === 'channel' || item.value === 'server'"
                        :key="item.value" :formatter="autoFormatter">
        </el-table-column>
      </el-table>
      <export-excel :data="ExcelData.keep" :headers="thList.keepList" filename="留存" style="margin: 5px 0"></export-excel>
    </div>
  </section>
</template>

<script>
import api from '@/api/bi'
import f from '@/filter'
import ExportExcel from '@/components/Excel/ExportExcel'
import DateRange from '@/components/DateRange'
import ChannelCascade from '@/components/Channel/ChannelCascade'
import ServerMultipleSelect from '@/components/Server/ServerMultipleSelect2'

export default {
  components: {
    DateRange,
    ExportExcel,
    ChannelCascade,
    ServerMultipleSelect
  },
  props: {},
  data () {
    let targetList = [
      { value: 'date', label: '日期' },
      // { value: 'loginDeviceAdd', label: '新增设备' },
      { value: 'loginUserAdd', label: '新增用户' },
      // { value: 'loginUser', label: '活跃用户' },
      // { value: 'loginUserOld', label: '老用户' },
      // { value: 'payMoney', label: '付费金额' },
      { value: 'payUser', label: '付费用户' },
      { value: 'payMoneyTotal', label: '累计付费' },
      { value: 'payRate', label: '付费率（%）' },
      { value: 'arppu', label: 'ARPPU' },
      { value: 'arpu', label: 'ARPU' }
      // { value: 'payMoneyAdd', label: '新增付费金额' },
      // { value: 'payUserAdd', label: '新增付费用户' },
      // { value: 'payUserOld', label: '老付费用户' },
      // { value: 'payRateAdd', label: '新增付费率' },
      // { value: 'arppuAdd', label: '新增ARPPU' },
      // { value: 'arppuOld', label: '老用户ARPPU' },
      // { value: 'arpuAdd', label: '新增ARPU' },
      // { value: 'arpuOld', label: '老用户ARPU' },
      // { value: 'payRateOld', label: '老用户付费率' }
    ]
    let ltvList = [
      { value: 'date', label: '日期' },
      { value: 'ltv1', label: '当日ltv' },
      { value: 'ltv2', label: '次日ltv' },
      { value: 'ltv3', label: '3日ltv' },
      { value: 'ltv4', label: '4日ltv' },
      { value: 'ltv5', label: '5日ltv' },
      { value: 'ltv6', label: '6日ltv' },
      { value: 'ltv7', label: '7日ltv' },
      { value: 'ltv14', label: '14日ltv' },
      { value: 'ltv30', label: '30日ltv' },
      { value: 'ltv45', label: '45日ltv' },
      { value: 'ltv60', label: '60日ltv' },
      { value: 'ltv90', label: '90日ltv' },
      { value: 'ltv120', label: '120日ltv' },
      { value: 'ltv180', label: '180日ltv' }
    ]
    let keepList = [
      { value: 'date', label: '日期' },
      { value: 'keep2', label: '次日留存' },
      { value: 'keep3', label: '3日留存' },
      { value: 'keep4', label: '4日留存' },
      { value: 'keep5', label: '5日留存' },
      { value: 'keep6', label: '6日留存' },
      { value: 'keep7', label: '7日留存' },
      { value: 'keep14', label: '14日留存' },
      { value: 'keep30', label: '30日留存' },
      { value: 'keep45', label: '45日留存' },
      { value: 'keep60', label: '60日留存' },
      { value: 'keep90', label: '90日留存' },
      { value: 'keep120', label: '120日留存' },
      { value: 'keep180', label: '180日留存' }
    ]
    return {
      type: 'date',
      minMoney: '',
      maxMoney: '',
      loading: false,
      serverList: [],
      thList: {
        targetList: targetList,
        ltvList: ltvList,
        keepList: keepList
      },
      tableData: [],
      sumData: {},
      ExcelData: {
        target: [],
        ltv: [],
        keep: []
      }
    }
  },
  created () {
    // this.fetchData()
  },
  computed: {},
  mounted () {},
  methods: {
    fetchData () {
      this.loading = true
      let param = {
        servers: []
      }
      if (this.minMoney) {
        param.minMoney = this.minMoney
      }
      if (this.maxMoney) {
        param.maxMoney = this.maxMoney
      }
      for (let s of this.serverList) {
        param.servers.push(s)
      }
      api.getCustom(param).then(res => {
        this.loading = false
        this.tableData = res.data
        this.sumData = res.sum
        this.ExcelData.target = [...this.tableData.target]
        this.ExcelData.target.push(Object.assign({ date: '统计' }, this.sumData.target))
        this.ExcelData.ltv = [...this.tableData.ltv]
        this.ExcelData.ltv.push(Object.assign({ date: '统计' }, this.sumData.ltv))
        this.ExcelData.keep = [...this.tableData.keep]
        this.ExcelData.keep.push(Object.assign({ date: '统计' }, this.sumData.keep))
      })
    },
    getServerList (serverList) {
      this.serverList = serverList
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      let sumData = this.sumData
      if (!sumData) {
        return sums
      }
      columns.forEach((column, index) => {
        let key = column.property
        if (index === 0) {
          sums[index] = '统计'
          return
        }
        sums[index] = sumData['target'][key] || sumData['keep'][key] || sumData['ltv'][key]
      })
      return sums
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    },
    minMoneyChange () {
      if (!this.maxMoney) {
        return
      }
      if (this.minMoney > this.maxMoney) {
        this.maxMoney = ''
        this.$refs.maxMoney.focus()
      }
    },
    maxMoneyChange () {
      if (!this.minMoney) {
        return
      }
      if (this.minMoney > this.maxMoney) {
        this.minMoney = ''
        this.$refs.minMoney.focus()
      }
    }
  }
}
</script>
<style scoped>

</style>
