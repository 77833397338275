import { render, staticRenderFns } from "./Bilpay.vue?vue&type=template&id=6cee90e8&scoped=true"
import script from "./Bilpay.vue?vue&type=script&lang=js"
export * from "./Bilpay.vue?vue&type=script&lang=js"
import style0 from "./Bilpay.vue?vue&type=style&index=0&id=6cee90e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cee90e8",
  null
  
)

export default component.exports