<template>
  <el-select
    v-model="servers" placeholder="请搜索服务器"
    @change="handleChange"
    clearable filterable
    multiple :collapse-tags="isCollapseTags"
    :filter-method="filterMethod"
    v-el-select-loadmore="loadMore(rangeNumber)"
    @visible-change="visibleChange">
    <el-option v-for="item in serverList.slice(0, rangeNumber)" :key="item.value + '-' + item.label" :label="item.label" :value="item.value"> </el-option>
  </el-select>
</template>
<script>
import util from '@/common/util'
export default {
  name: 'ServerSelect',
  data () {
    return {
      servers: this.$store.getters.serverList ? this.$store.getters.serverList : [0],
      rangeNumber: 50, // 首次加载条数
      isCollapseTags: false,
      serverList: [] // 筛选后的下拉列表
    }
  },
  created () {
    // if (!this.$store.getters.serverId) {
    //   this.serverId = 0
    // }
  },
  computed: {
    serverOptions () {
      return this.$store.getters.servers
    }
  },
  methods: {
    handleChange () {
      this.$emit('serverList', this.servers)
      this.$store.dispatch('ChangeServerList', this.servers).then(() => {})
    },
    loadMore (n) {
      return () => {
        this.rangeNumber += 10
      }
    },
    // 筛选方式
    filterMethod: util.debounce(function (filterVal) {
      if (filterVal) {
        let filterArr = this.serverOptions.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(filterVal) > -1
          })
        })
        this.serverList = filterArr
      } else {
        this.serverList = this.serverOptions
      }
    }),
    visibleChange (flag) {
      if (flag) {
        this.filterMethod()
      }
      const data = [...this.servers]
      this.isCollapseTags = !flag
      this.servers = data
    }
  }
}
</script>
