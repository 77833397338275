<template>
  <div class="bg-white charts-canvas"></div>
</template>

<script>
import f from '@/filter'
import util from '@/common/util'
require('echarts/theme/macarons')

export default {
  name: 'ChartPie',
  props: {
    data: { // 原始数据
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
    let _this = this
    function handle () {
      if (_this.chart) {
        _this.chart.resize()
      }
    }
    window.addEventListener('resize', util.debounce(handle, 500))
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = this.$echarts.init(this.$el, 'macarons')
      this.setOptions(this.data)
    },
    setOptions (val) {
      this.chart.clear()
      if (!val) {
        return
      }
      let targets = val.targets || []
      let data = val.data
      let xAxisKey = val.xAxisKey || 'date'
      let type = val.type || 'pie'
      let formatOptions = val.formatOptions || []
      if (!data || targets.length <= 0) {
        return
      }
      let name = targets[0].label
      let chartData = data[targets[0].value]
      if (!chartData) {
        return
      }
      let legendData = [] // 头顶的指标快捷选项
      let pieData = [] // 饼内数据
      for (let d of chartData) {
        let key = f.optionsFormat(d[xAxisKey], formatOptions)
        // legendData.push(key)
        pieData.push({ name: key, value: d.value })
      }
      this.chart.setOption({
        // title: {
        //   text: this.title || ''
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          x: 'left',
          top: '10',
          data: legendData
        },
        series: [
          {
            name: name,
            type: 'pie',
            radius: type === 'hoop' ? ['50%', '65%'] : '65%',
            center: ['50%', '50%'],
            label: {
              normal: {
                // show: false,
                position: 'outside',
                // formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                formatter: '{b}: {c} ({d}%)'

              }
            },
            data: pieData
          }
        ]
      })
    }
  }
}
</script>
