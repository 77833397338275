<template>
  <section class="old-user-reflux-daily">
    <el-card>
      <template slot="header">
        <FilterForm
          :form.sync="filterForm"
          @onChange="onChange"
          :filterColumn="filterColumn"
          @onSearch="(form) => fetchData({ page: 0, size: 10, ...form })"
        >
          <template slot="channels">
            <ChannelCascade :size="'small'" :multiple="true" />
          </template>
        </FilterForm>
      </template>
      <Table
        show-summary
        :max-height="clientHeight * 0.8"
        :summary-method="summaryMethod"
        :tableData="tableData"
        :column="tableColumn"
      />
      <div class="execl">
        <ExportExcel :data="tableData" :headers="tableColumn" :filename="fileName" />
      </div>
    </el-card>
  </section>
</template>

<script>
import { FilterColumn, TableColumn } from './config'
import ChannelCascade from '@/components/Channel/ChannelCascade'
import FilterForm from '@/components/Layout/FilterForm.vue'
import Table from '@/components/Layout/Table.vue'
import ExportExcel from '@/components/Excel/ExportExcel.vue'
// import Form from '@/components/Layout/Form.vue'

import api from '@/api/OldUserReport/daily'
export default {
  name: 'OldUserReportRefluxDaily',
  components: {
    FilterForm,
    ChannelCascade,
    Table,
    ExportExcel
    // Form,
  },
  data () {
    return {
      filterForm: { st: [new Date(+new Date() - 7 * 24 * 3600 * 1000), new Date()] },
      page: { page: 0, size: 10 },
      tableData: [],
      summaryData: []
    }
  },
  computed: {
    fileName () {
      const { matched } = this.$route
      const name = matched.reduce((pre, cur) => (pre ? `${pre}-${cur.name}` : cur.name), '')
      return `${name}_${+new Date()}`
    },
    clientHeight () { return document.body.clientHeight * 0.9 },
    channelCascadeIds () {
      return this.$store.getters.channelCascadeIds
    },
    filterColumn () { return [...FilterColumn] },
    tableColumn () { return [...TableColumn] }
  },
  mounted () {
    const stArr = sessionStorage.getItem(this.$route.path)
    if (stArr && stArr.length) {
      const [st, et] = JSON.parse(stArr)
      this.$set(this.filterForm, 'st', [new Date(st), new Date(et)])
    }
    this.$nextTick(this.fetchData())
  },
  methods: {
    fetchData (filterForm = {}) {
      const channels = this.channelCascadeIds.reduce((pre, [start, end]) => {
        if (end) pre.push(end)
        return pre
      }, [])

      const { st, et } = { ...this.filterForm, ...filterForm }
      const form = {
        ...this.filterForm,
        ...this.page,
        ...filterForm,
        channels: channels.length ? channels : undefined,
        st: this.$moment(Array.isArray(st) ? st.at(0) : st).format('YYYY-MM-DD'),
        et: this.$moment(Array.isArray(st) ? st.at(-1) : et).format('YYYY-MM-DD')
      }
      const loading = this.$loading({
        target: document.querySelector('.old-user-reflux-daily')
      })
      // console.log(loading, form)
      api.getRefluxList(form).then(res => {
        this.tableData = res.data
        this.summaryData = res.sum
      }).finally(() => loading.close())
    },
    summaryMethod () {
      return this.tableColumn.reduce((pre, cur, index) => {
        // if(cur.value === 'date')
        const value = !index ? '合计' : this.summaryData[cur.value]
        return [...pre, value]
      }, [])
    },
    onChange (i, form, value) {
      // console.log(i, form, value)
      if (i.value === 'st') {
        sessionStorage.setItem(this.$route.path, JSON.stringify(value))
      }
    }
  }
}
</script>

<style scoped>
.execl {
  margin-top: 10px;
}
</style>
