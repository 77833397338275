import { render, staticRenderFns } from "./Retained.vue?vue&type=template&id=5ce7883a&scoped=true"
import script from "./Retained.vue?vue&type=script&lang=js"
export * from "./Retained.vue?vue&type=script&lang=js"
import style0 from "./Retained.vue?vue&type=style&index=0&id=5ce7883a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce7883a",
  null
  
)

export default component.exports