<template>
<section>
  <div class="box">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column label="IP" prop="ip" sortable> </el-table-column>
      <el-table-column label="封禁时间" prop="blockedTime" sortable> </el-table-column>
      <el-table-column label="操作" fixed="right" width="120">
        <template slot-scope="scope">
          <el-button @click="handleOpenIP(scope.row, scope.$index)" type="text" size="small">解封</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</section>
</template>

<script>
import api from '@/api/bi'

export default {
  name: 'OpenIP',
  data () {
    return {
      tableData: []
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      api.getBlacklist().then(res => {
        this.tableData = res
      }).catch(() => {
        this.loading = false
      })
    },
    handleOpenIP (ip, index) {
      let params = JSON.stringify([ip])
      this.$confirm('此操作将解封该IP, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.deleteBlacklist(params).then(res => {
          this.tableData.splice(index, 1)
          this.$message.success('解封成功')
        }).catch((res) => {
          this.$message.success('解封失败', '错误码' + res.code)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消解封'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
