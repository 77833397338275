<template>
  <el-select
    v-model="serverId" placeholder="请搜索服务器"
    @change="handleChange"
    :filter-method="filterMethod"
    v-el-select-loadmore="loadMore(rangeNumber)"
    @visible-change="visibleChange"
    clearable filterable>
    <el-option v-for="item in serverList.slice(0, rangeNumber)" :key="item.value" :label="item.label" :value="item.value"> </el-option>
  </el-select>
</template>
<script>
import util from '@/common/util'
export default {
  name: 'ServerSelect',
  // props: {
  //   multiple: { // 多选
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data () {
    return {
      serverId: this.$store.getters.serverId,
      rangeNumber: 50, // 首次加载条数
      serverList: [] // 筛选后的下拉列表
    }
  },
  computed: {
    serverOptions () {
      return this.$store.getters.servers
    }
  },
  created () {
    if (!this.$store.getters.serverId) {
      this.serverId = 0
    }
  },
  methods: {
    handleChange () {
      this.$store.dispatch('ChangeServerId', this.serverId).then(() => {})
    },
    loadMore (n) {
      return () => {
        this.rangeNumber += 10
      }
    },
    // 筛选方式
    filterMethod: util.debounce(function (filterVal) {
      if (filterVal) {
        let filterArr = this.serverOptions.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(filterVal) > -1
          })
        })
        this.serverList = filterArr
      } else {
        this.serverList = this.serverOptions
      }
    }),
    visibleChange (flag) {
      if (flag) {
        this.filterMethod()
      }
    }
  }

}
</script>
