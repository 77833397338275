<template>
  <section style="width: 100%;height: 100%;position: relative">
    <vue-particles :color=color :particleOpacity="0.7" :particlesNumber="80" shapeType="star" :particleSize="4" :linesColor="color" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="2" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="repulse" class="lizi"></vue-particles>
    <h2>{{testText}}</h2>
<!--    <img src="@/assets/img/logo.png" alt="" :width="isMobile ? '40%' : '15%'">-->
  </section>
</template>

<script>
export default {
  components: {},
  data () {
    let color = ['#2EC7C9', '#B6A2DE', '#5AB1EF', '#FFB980', '#87F7CF', '#72CCFF']
    return {
      color: color[Math.floor(Math.random() * 5)],
      testText: '您好！',
      nowIndex: 0
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    }
  },
  mounted () {
    let _this = this
    let timer = setInterval(function () {
      _this.nowIndex++
      switch (_this.nowIndex) {
        case 1:
          _this.testText = '欢迎访问BI平台'
          break
        case 2:
          _this.testText = '愿您浏览愉快'
          break
        case 3:
          _this.testText = 'BI Mars v3.6.10'
          break
      }
      if (_this.nowIndex > 3) {
        setTimeout(() => {
          clearInterval(timer)
        }, 2000)
      }
    }, 2000)
  },
  methods: {}
}
</script>
<style scoped>
  .lizi {
    background: linear-gradient(calc(389deg), rgba(163, 163, 163, 0.09) 0%, rgba(163, 163, 163, 0.09) 33.3%, rgba(100, 100, 100, 0.09) 33.3%, rgba(100, 100, 100, 0.09) 66.6%, rgba(162, 162, 162, 0.09) 66.6%, rgba(162, 162, 162, 0.09) 99%), linear-gradient(calc(595deg), rgba(193, 193, 193, 0.06) 0%, rgba(193, 193, 193, 0.06) 33.3%, rgba(169, 169, 169, 0.06) 33.3%, rgba(169, 169, 169, 0.06) 66.6%, rgba(92, 92, 92, 0.06) 66.6%, rgba(92, 92, 92, 0.06) 99%), linear-gradient(calc(466deg), rgba(45, 45, 45, 0.03) 0%, rgba(45, 45, 45, 0.03) 33.3%, rgba(223, 223, 223, 0.03) 33.3%, rgba(223, 223, 223, 0.03) 66.6%, rgba(173, 173, 173, 0.03) 66.6%, rgba(173, 173, 173, 0.03) 99%), linear-gradient(calc(617deg), rgba(226, 226, 226, 0.06) 0%, rgba(226, 226, 226, 0.06) 33.3%, rgba(81, 81, 81, 0.06) 33.3%, rgba(81, 81, 81, 0.06) 66.6%, rgba(186, 186, 186, 0.06) 66.6%, rgba(186, 186, 186, 0.06) 99%), linear-gradient(calc(422deg), rgba(225, 225, 225, 0.04) 0%, rgba(225, 225, 225, 0.04) 33.3%, rgba(95, 95, 95, 0.04) 33.3%, rgba(95, 95, 95, 0.04) 66.6%, rgba(39, 39, 39, 0.04) 66.6%, rgba(39, 39, 39, 0.04) 99%), linear-gradient(calc(465deg), rgba(184, 184, 184, 0.06) 0%, rgba(184, 184, 184, 0.06) 33.3%, rgba(0, 0, 0, 0.06) 33.3%, rgba(0, 0, 0, 0.06) 66.6%, rgba(140, 140, 140, 0.06) 66.6%, rgba(140, 140, 140, 0.06) 99.9%), linear-gradient(calc(660deg), rgba(40, 40, 40, 0.07) 0%, rgba(40, 40, 40, 0.07) 33.3%, rgba(214, 214, 214, 0.07) 33.3%, rgba(214, 214, 214, 0.07) 66.6%, rgba(190, 190, 190, 0.07) 66.6%, rgba(190, 190, 190, 0.07) 99.9%), linear-gradient(calc(398deg), rgba(230, 230, 230, 0) 0%, rgba(230, 230, 230, 0) 33.3%, rgba(241, 241, 241, 0) 33.3%, rgba(241, 241, 241, 0) 66.6%, rgba(55, 55, 55, 0) 66.6%, rgba(55, 55, 55, 0) 99%), linear-gradient(calc(337deg), rgb(38, 38, 227), rgb(11, 186, 239));
    width: 100%;
    height: 100%;
    position: fixed;
  }
  h2 {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 2rem;
    animation: letterspacing 1s 7 alternate ease-in-out;
    display: block;
    letter-spacing: .5rem;
  }
  img {
    position: absolute;
    top: 360px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    display: block;
  }
  @keyframes letterspacing {
    0% {
      letter-spacing: -72px;
      filter: blur(20px);
    }

    40% {
      filter: blur(6px);
    }

    80% {
      letter-spacing: 8px;
      filter: blur(0);
    }
  }
</style>
