<template>
  <orders v-if="appId > 3"></orders>
  <orders3 v-else></orders3>
</template>

<script>
import Orders from './Orders'
import Orders3 from './Orders3'
export default {
  name: 'Order',
  components: {
    Orders,
    Orders3
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  }
}
</script>
