<template>
  <section>
    <div class="bi-channel-date-search">
      <el-form label-position="left" label-width="100px" :model="form" inline>
        <el-row>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="订单ID">
              <el-input v-model="form.orderId" placeholder="订单ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="平台用户ID">
              <el-input v-model="form.userId" placeholder="平台用户ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="订单状态">
              <el-select v-model="form.status" placeholder="请选择订单状态" clearable>
                <el-option value="">不限</el-option>
                <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="选择渠道">
              <channel-select></channel-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="渠道订单ID">
              <el-input v-model="form.channelOrderId" placeholder="渠道订单ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="渠道用户ID">
              <el-input v-model="form.channelUserId" placeholder="渠道用户ID"></el-input>
            </el-form-item>
          </el-col>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="选择服务器">
              <template>
                <server-select></server-select>
              </template>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="角色ID">
              <el-input v-model="form.roleId" placeholder="角色ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="角色名称">
              <el-input v-model="form.roleName" placeholder="支持模糊查询"></el-input>
            </el-form-item>
          </el-col>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.st" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH-mm-ss"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="结束时间">
              <el-date-picker v-model="form.et" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH-mm-ss"> </el-date-picker>
            </el-form-item>
          </el-col>
          <!--<el-form-item label="excPayAdmin" style="width: 30%">-->
          <!--<el-checkbox v-model="form.excPayAdmin">excPayAdmin</el-checkbox>-->
          <!--</el-form-item>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-checkbox v-model="form.excTestServer">排除测试服</el-checkbox>
            <el-button type="primary" @click="fetchData" :loading="ordersDataLoading">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!--信息展示区-->
    <div class="box" style="display: flex; flex-direction: column;">
      <!--分页器-->
      <div class="block" style="float: left">
        <el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.page"
                       :page-sizes="pageSizes" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
      <!--<el-button @click="dataDownExcel" type="primary" style="float: right">下载到本地</el-button>-->
      <el-table :data="ordersData" style="width: 100%" border size="medium" stripe :summary-method="getSummaries" show-summary>
        <el-table-column prop="id" label="平台订单ID" sortable> </el-table-column>
        <el-table-column prop="order_type" label="订单类型" sortable>
          <template slot-scope="{row, column}">
            {{ { 0: '游戏内购', 1: '订阅', 2: '官网商城-普通订单(代金券+特惠礼包)', 3: '官网商城-特殊直购', 4: '线下支付' }[row[column.property]] }}
          </template>
        </el-table-column>
        <el-table-column prop="user_id" label="用户ID" sortable> </el-table-column>
        <el-table-column prop="channel_id" label="渠道ID" :formatter="channelFormatter" sortable> </el-table-column>
        <el-table-column prop="channel_order_id" label="渠道订单ID" sortable> </el-table-column>
        <el-table-column prop="product_desc" label="商品描述"  sortable> </el-table-column>
        <el-table-column prop="buy_num" label="商品购买数量" sortable> </el-table-column>
        <el-table-column prop="money" label="金额" :formatter="moneyFormatter" sortable> </el-table-column>
        <el-table-column prop="status" label="状态" :formatter="statusFormatter" sortable> </el-table-column>
        <el-table-column prop="server_id" label="服务器ID" sortable> </el-table-column>
        <el-table-column prop="role_id" label="角色ID" sortable> </el-table-column>
        <el-table-column prop="role_name" label="角色名称" sortable> </el-table-column>
        <el-table-column prop="create_time" label="下单时间" :formatter="dateFormatter" sortable> </el-table-column>
<!--        <el-table-column prop="finish_time" label="完成时间" sortable> </el-table-column>-->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="handleOrderDetail(scope.row)" type="text" size="small">详情</el-button>
            <el-button @click="handleOrderRenotify(scope.row, scope.$index)" type="text" size="small" v-show="scope.row.status === 1 && !scope.row.renotified">补单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="block" style="float: left">
        <el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pagination.page" :page-sizes="pageSizes" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper"
                       :total="pagination.total">
        </el-pagination>
      </div>
      <div style="margin: 5px;">
        <export-excel v-show="exportExcelVisible" :data="ordersData" :headers="ordersTableHeaderList" filename="BI-订单查询数据"></export-excel>
      </div>
    </div>
  <!--订单详情展示-->
  <el-dialog title="订单详情" :visible.sync="orderDetailVisible" top="3vh">
    <el-table :data="orderDetailData" size="medium" stripe>
<!--      <el-table-column type="index" width="50"></el-table-column>-->
      <el-table-column prop="key" label="属性" width="160"></el-table-column>
      <el-table-column prop="label" label="名称" width="160"></el-table-column>
      <el-table-column prop="value" label="值"></el-table-column>
    </el-table>
    <!-- <div slot="footer" class="dialog-footer flex align-center justify-between direction-row-reverse">
      <el-button @click="handleOrderRenotify(orderDetailRow, -1)" type="primary" size="medium">重发通知</el-button>
      <el-button v-show="orderDetailRow.status < 1" type="warning" size="medium" @click="onRepairOrderClik(orderDetailRow)">技术补单通知</el-button>
    </div> -->
  </el-dialog>
  <SupplementaryOrder :form="orderDetailRow" :visible.sync="supplementaryOrderVisible"/>
  </section>
</template>
<script>
import ChannelSelect from '@/components/Channel/ChannelSelect'
import ServerSelect from '@/components/Server/ServerSelect'
import api from '@/api/fee'
import f from '@/filter'
import options from '@/common/option'
import ExportExcel from '@/components/Excel/ExportExcel'
import SupplementaryOrder from './components/supplementaryOrder.vue'
export default {
  name: 'offline-orders',
  components: {
    ExportExcel,
    ChannelSelect,
    ServerSelect,
    SupplementaryOrder
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    channelId () {
      return this.$store.getters.channelId
    },
    serverId () {
      return this.$store.getters.serverId
    }
  },
  created () {
    this.$store.dispatch('ChangeChannelId', 0)
    this.$store.dispatch('ChangeServerId', 0)
    // console.log('orders by ufo')
  },
  data () {
    const ordersLegendKey = {
      id: '平台订单号',
      user_id: '用户ID',
      currency: '货币',
      money: '金额',
      status: '状态',
      channel_id: '渠道ID',
      channel_order_id: '渠道订单号',
      channel_user_id: '渠道用户ID',
      product_id: '商品ID',
      price: '商品价格',
      product_name: '商品名称',
      product_desc: '商品描述',
      cp_order_id: 'CP订单号',
      server_id: '服务器ID',
      server_name: '服务器名称',
      role_id: '角色ID',
      role_name: '角色名称',
      role_level: '角色等级',
      create_time: '订单创建时间',
      paid_time: '支付完成时间',
      finish_time: '通知完成时间',
      notify_url: '通知游戏地址',
      extension: '游戏扩展信息'
    }
    const ordersTableHeaderList = []
    for (let key in ordersLegendKey) {
      ordersTableHeaderList.push({ value: key, label: ordersLegendKey[key] })
    }
    return {
      form: {
        orderId: '',
        channelOrderId: '',
        userId: '',
        roleId: '',
        roleName: '',
        channelUserId: '',
        status: 2,
        excPayAdmin: false,
        excTestServer: false,
        st: '',
        et: ''
      },
      ordersData: [],
      ordersDataLoading: false,
      pagination: {
        total: 0,
        size: 0,
        page: 1
      },
      pageSizes: [20, 30, 50, 100, 200, 500, 1000],
      statusOptions: options.orderStatusOptions,
      orderDetailRow: {},
      orderDetailData: [],
      orderDetailVisible: false,
      ordersLegendKey: ordersLegendKey,
      ordersTableHeaderList: ordersTableHeaderList,
      exportExcelVisible: false,
      supplementaryOrderVisible: false
    }
  },
  methods: {
    fetchData () {
      let params = Object.assign({}, this.form)
      params.appId = this.appId
      params.channelId = this.channelId
      params.serverId = this.serverId
      params.page = this.pagination.page
      params.size = this.pagination.size
      for (let item in params) {
        if (params[item] === '' || params[item] === false || params[item] === 0) {
          delete params[item]
        }
      }
      if (!params) {
        this.$message.error('请填写内容')
        return
      }
      this.ordersDataLoading = true
      api.getOfflineOrder(params).then(res => {
        // 内容
        this.ordersData = res.content
        // 总条目数
        this.pagination.total = res.totalSizes
        // 每页展示条数
        this.pagination.size = res.size
        // 当前是第几页
        this.pagination.page = res.page
        this.ordersDataLoading = false
        this.exportExcelVisible = true
      }).catch(() => {
        this.ordersDataLoading = false
      })
    },
    // 每页条数改变的时候触发
    handleSizeChange (val) {
      this.pagination.size = val
      this.fetchData()
    },
    // 当前页改变的时候触发
    handleCurrentChange (val) {
      this.pagination.page = val
      this.fetchData()
    },
    // 初始化状态
    statusFormatter (row, column, cellValue, index) {
      return f.optionsFormat(cellValue, this.statusOptions)
    },
    handleOrderDetail (row) {
      this.orderDetailVisible = true
      this.orderDetailRow = row
      this.orderDetailData = []
      for (let key in this.ordersLegendKey) {
        this.orderDetailData.push({ key: key, value: f.k2vOrder(key, row[key]), label: this.ordersLegendKey[key] })
      }
    },
    handleOrderRenotify (row, index) {
      const params = { appId: this.appId, orderId: row.id }
      api.orderRenotify(params).then(res => {
        let { code, msg } = res
        if (code !== 1) {
          this.$message.error(`补单失败: ${code} - ${msg}`)
          return
        }
        row.renotified = true
        if (index >= 0) {
          this.ordersData.splice(index, 1, row)
        }
        this.$message.success('操作成功，请稍候查询订单状态')
      })
    },
    moneyFormatter (row, column, cellValue, index) {
      return row.currency + ' ' + (cellValue / 100).toFixed(2)
    },
    channelFormatter (row, column, cellValue, index) {
      return f.channelFormat(cellValue)
    },
    dateFormatter (row, column, cellValue, index) {
      return cellValue ? this.$moment(cellValue).format('YYYY-MM-DD HH:mm') : ''
    },
    // 结尾展示总计
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      if (this.form.status >= 1) {
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          }
          if (column.property === 'money') {
            const values = data.map(item => Number(item[column.property]))
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return (prev + curr)
              } else {
                return prev
              }
            }, 0)
            sums[index] = (sums[index] / 100).toFixed(2) + ' 元'
          }
        })
      }
      return sums
    },
    /**
     * 技术补单通知
     */
    onRepairOrderClik (row) {
      console.log(row)
      this.supplementaryOrderVisible = true
    }
  }
}
</script>
<style scoped>
.flex {
  display: flex;
  width: 100%;
}
.direction-row-reverse {
  flex-direction: row-reverse;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
</style>
