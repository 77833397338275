<template>
  <div class="box">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column :prop="item.value" :label="item.label" v-for="item in list" :key="item.value" :formatter="autoFormatter"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import api from '@/api/bi'
import f from '@/filter'
export default {
  name: 'Transfer',
  created () {
    this.fetchData()
  },
  data () {
    const list = [
      { value: 'userId', label: '用户ID' },
      { value: 'channelId', label: '渠道ID' },
      { value: 'channelUserId', label: '渠道用户ID' },
      { value: 'channelUserName', label: '渠道用户名称' },
      { value: 'serverId', label: '服务器ID' },
      { value: 'roleId', label: '角色ID' },
      { value: 'roleName', label: '角色名称' },
      { value: 'username', label: '官网账号' },
      { value: 'password', label: '初始密码' }
    ]
    return {
      tableData: [],
      list
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  methods: {
    fetchData () {
      api.getAccountTransfer(this.appId).then(res => {
        this.tableData = res
      })
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    }
  }
}
</script>

<style scoped>

</style>
