<template>
  <div class="stage">
    <div class="bi-channel-date-search">
      <el-form :inline="true">
        <el-form-item>
          <server-multiple-select @serverList="handleChildServerChange"></server-multiple-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="cycles" placeholder="请选择阶段" filterable clearable multiple collapse-tags>
            <el-option v-for="item in cycleOptions" :key="item.cycle" :label="item.name" :value="item.cycle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchTableData">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" style="width: 100%" stripe max-height="650">
        <el-table-column prop="server" label="服务器" :formatter="autoFormatter" sortable></el-table-column>
        <el-table-column prop="cycle" label="阶段" :formatter="autoFormatter" sortable></el-table-column>
        <el-table-column :prop="item.target" :label="item.name" v-for="item in targetOptions" :key="item.target" sortable></el-table-column>
      </el-table>
      <export-excel
        v-show="tableData.length"
        :data="tableData"
        :headers="tableHeaderList"
        filename="分服画像"></export-excel>
    </div>
  </div>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ExportExcel from '@/components/Excel/ExportExcel'
import f from '@/filter'
import ServerMultipleSelect from '@/components/Server/ServerMultipleSelect2'
export default {
  name: 'Stage',
  components: {
    ExportExcel,
    ServerMultipleSelect
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      tableHeaderList: [],
      targetOptions: [], // 指标列表
      cycleOptions: [], // 阶段列表
      tableData: [], // 表格数据
      serverList: [], // 已选中的服务器列表
      cycles: [], // 已选中的阶段
      targetAndCycleMap: new Map()
    }
  },
  methods: {
    fetchData () {
      // 查询指标和阶段
      api.getPortraitParams().then(response => {
        let tHead = [
          { value: 'server', label: '服务器' },
          { value: 'cycle', label: '阶段' }
        ]
        this.targetOptions = [...response.targets]
        this.cycleOptions = [...response.cycles]
        this.targetOptions.forEach(item => {
          tHead.push({ value: item.target, label: item.name })
          this.targetAndCycleMap.set(item.target, item)
        })
        this.tableHeaderList = tHead
        this.cycleOptions.forEach(item => this.targetAndCycleMap.set(item.cycle, item))
        option.contrastOptions = this.targetAndCycleMap
      })
    },
    fetchTableData () {
      if (this.serverList.length <= 0 || this.cycles.length <= 0) {
        this.$message.error('不可为空')
        return
      }
      const params = {
        servers: this.serverList,
        cycles: this.cycles
      }
      api.getPortraitSnapshot(params).then(response => {
        this.tableData = [...response]
      })
    },
    // 接收子组件传来的已经选择的服务器列表
    handleChildServerChange (serverList) {
      this.serverList = serverList
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    }
  }
}
</script>

<style scoped>

</style>
