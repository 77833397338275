import http from './http'

export default {
  getFeeBill (params) {
    return http.get(`/api/fee/bill`, { params: params })
  },
  getSdkBill (params) {
    return http.get(`/api/sdk/bill`, { params: params })
  },
  getFeeBillDetail (params) {
    return http.get(`/api/fee/bill-detail`, { params: params })
  },
  getSdkBillDetail (params) {
    return http.get(`/api/sdk/bill-detail`, { params: params })
  },
  getOrders3 (params) {
    return http.get(`/api/fee/orders3`, { params: params })
  },
  getOrders (params) {
    return http.get(`/api/fee/orders`, { params: params })
  },
  getIosOrders (params) {
    return http.get(`/api/sdk/orders`, { params: params })
  },
  orderRenotify (params) {
    return http.get(`/api/ufo/order-renotify`, { params: params })
  },

  /** 海外线下订单列表 */
  getOfflineOrder (params) {
    return http.get(`/api/offline-pay/orders`, { params: params })
  },
  /* 获取渠道包名 */
  getChannelPackages (params) {
    return http.get(`/api/packer/channel-package`, { params: params })
  }
}
