<template>
  <el-card class="retained-daily">
    <div slot="header">
      <channel-cascade :multiple="true"></channel-cascade>
      <el-date-picker
        v-model="date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-button class="header-search" type="primary" size="small" @click="fetchData">查看</el-button>
    </div>
    <PopoverComponents :visible.sync="visible" :chosenColumn.sync="chosenColumn"/>
    <span class="execl">
      <ExportExcel :headers="displayCloumn" :data="[...tableData, summaryData]" :filename="execlFileName" size="mini"/>
    </span>
    <el-tabs v-model="tabsValue" @tab-click="fetchData">
      <template v-for="(item, index) in tabsObj">
        <el-tab-pane :key="index" :label="item.label" :name="item.value" />
      </template>
    </el-tabs>
    <TableFunctional
      show-summary
      :max-height="screenHeight"
      :summaryMethod="summaryMethod"
      :column="displayCloumn"
      :tableData="tableData"
    />
  </el-card>
</template>

<script>
import TableFunctional from '@/components/Layout/Table.vue'
import PopoverComponents from './components/Popover.vue'
import ExportExcel from '@/components/Excel/ExportExcel.vue'
import ChannelCascade from '@/components/Channel/ChannelCascade'
import api from '@/api/OldUserReport/daily.js'

export default {
  name: 'old-user-retained-daily',
  components: {
    TableFunctional,
    PopoverComponents,
    ExportExcel,
    ChannelCascade
  },
  data () {
    // const switchType = new Map();
    return {
      tableCloumn: [],
      visible: false,
      chosenColumn: [],
      tableData: [],
      summaryData: {},
      date: [],
      tabsValue: 'date',
      tabsObj: [
        { id: 0, label: '数据总览', value: 'date' },
        { id: 1, label: '渠道数据', value: 'channel' },
        { id: 2, label: '原始数据', value: 'origin' }
      ]
    }
  },
  computed: {
    screenHeight () {
      return document.body.clientHeight * 0.7
    },
    channelIdOptions () {
      return this.$store.getters.channels.reduce((pre, cur) => {
        return { ...pre, [cur.value]: cur.label }
      }, {})
    },
    // 基础显示表头
    baseCloumn () {
      const column = [
        { value: 'login_user_add', label: '新增用户', type: 'text' }
      ]
      const date = [{ value: 'date', label: '日期', type: 'date', bind: { fixed: 'left', width: 100 } }]
      const channel = [{ value: 'channel_id', label: '渠道ID', bind: { width: 160 }, type: 'hash', options: this.channelIdOptions }]
      const tabObj = {
        date,
        channel,
        origin: [ ...date, ...channel ]
      }

      return [
        ...tabObj[this.tabsValue],
        ...column
      ]
    },
    // 显示表头
    displayCloumn () {
      return [
        ...(this.baseCloumn === undefined ? [] : this.baseCloumn),
        ...(this.tableCloumn === undefined ? [] : this.tableCloumn)
      ]
    },
    execlFileName () {
      const arr = this.tabsObj.find(item => {
        return item.value === this.tabsValue
      }) || { label: this.$router.name }
      return `${this.$route.name}-${arr.label}`
    }
  },
  watch: {
    visible: {
      handler (nVal) {
        if (!nVal) {
          this.fetchData()
        }
      }
    },
    chosenColumn: {
      handler (nVal) {
        if (nVal) {
          sessionStorage.setItem('chosenColumn', JSON.stringify(nVal))
          this.handleColumn()
          // this.fetchData()
        }
      }
    }
  },
  created () {
    const newDate = (new Date()).toLocaleDateString().replaceAll('/', '-')
    this.date = [newDate, newDate]
    const chosenColumn = sessionStorage.getItem('chosenColumn')
    this.chosenColumn = !chosenColumn ? [2, 3, 4, 5, 6, 7, 8] : JSON.parse(chosenColumn)
  },
  methods: {
    fetchData () {
      let params = ''
      const [st, et] = this.date
      if (st && et) {
        params = `st=${st}&et=${et}`
      } else {
        this.$message.error('请选择日期')
        return
      }
      const channelCascadeIds = this.$store.getters.channelCascadeIds
      const channels = channelCascadeIds.reduce((pre, [parent, item]) => {
        if (item) pre = pre ? `${pre}&channels=${item}` : `channels=${item}`
        return pre
      }, '')
      if (channels) params = `${params}&${channels}`

      if (this.tableData.length) this.tableData = []

      const days = this.chosenColumn.reduce((pre, cur) => {
        return pre ? `${pre}&days=${cur}` : `days=${cur}`
      }, '')
      if (days) params = `${params}&${days}`

      // params = { ...params, days }
      const loading = this.$loading({
        target: document.querySelector('.retained-daily')
      })
      api.getKeepList(params, this.tabsValue).then(res => {
        console.log(res)
        this.tableData = res.data
      }).finally(() => loading.close())
    },
    // 处理数据转化显示表头
    handleColumn () {
      const column = this.chosenColumn.map((item) => {
        return {
          label: `${item === 2 ? '次' : item}日留存`,
          value: `k${item}`,
          type: 'text'
        }
      })
      // console.log(column)
      this.tableCloumn = column
    },
    summaryMethod (param) {
      const { columns, data } = param
      const handleWay = {
        channel_id: 'date',
        date: 'date',
        login_user_add: 'add'
      }
      // const map = new Map()
      const amountData = data.reduce((pre, cur) => {
        const obj = { ...pre }
        Object.entries(cur).forEach(([key, item]) => {
          switch (handleWay[key]) {
            case 'date':
              obj[key] = '合计'
              break
            default:
              obj[key] = Reflect.has(obj, key) ? [...obj[key], item] : [item]
              break
          }
        })
        return obj
      }, {})
      console.log(amountData)
      return columns.reduce((pre, cur, index) => {
        let value = ''
        if (Reflect.has(amountData, cur.property)) {
          switch (cur.property) {
            case 'date':
              break
            case 'channel_id':
              break
            case 'login_user_add':
              value = amountData[cur.property].reduce((pre, cur) => (pre + +(cur)), 0)
              break
            default:
              const amount = amountData[cur.property].reduce((pre, cur) => (pre + +(cur)), 0)
              value = (amount / amountData[cur.property].length).toFixed(2)
              break
          }
          this.summaryData[cur.property] = index === 0 ? '合计' : value
        }
        return index === 0 ? pre : [...pre, value]
      }, ['合计'])
    }
  }
}
</script>

<style scoped>
.retained-daily {
  width: 100%;
}
/* .retained-daily /deep/.el-table__body-wrapper {
  max-height: 60vh;
  overflow-y: auto;
} */
.retained-daily /deep/.el-card__header div {
  display: flex;
  align-items: center;
}
.retained-daily .header-search {
  margin-left: 20px;
}

.execl {
  margin-left: 10px;
}
</style>
