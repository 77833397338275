<template>
  <section>
    <bi-channel-date-search @change="fetchData"></bi-channel-date-search>

    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart1.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-map :data="chart1Data" :target="chartsData.chart1.target"> </chart-map>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart2.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-map :data="chart2Data" :target="chartsData.chart2.target"> </chart-map>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart3.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-map :data="chart3Data" :target="chartsData.chart3.target"> </chart-map>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart4.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-map :data="chart4Data" :target="chartsData.chart4.target"> </chart-map>
        </div>
      </el-col>
    </el-row>

<!--    <div class="box-row">-->
<!--      <div class="box-left">-->

<!--      </div>-->
<!--      <div class="box-right">-->
<!--        <chart-map :data="chart2Data" :title="chartsData.chart2.title" :target="chartsData.chart2.target"> </chart-map>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="box-row">-->
<!--      <div class="box-left">-->
<!--        <chart-map :data="chart3Data" :title="chartsData.chart3.title" :target="chartsData.chart3.target"> </chart-map>-->
<!--      </div>-->
<!--      <div class="box-right">-->
<!--        <chart-map :data="chart4Data" :title="chartsData.chart4.title" :target="chartsData.chart4.target"> </chart-map>-->
<!--      </div>-->
<!--    </div>-->
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartMap from '../../components/Charts/ChartMap'
import BiChannelDateSearch from '../../components/BI/BiChannelDateSearch'
export default {
  components: {
    BiChannelDateSearch,
    ChartMap
  },
  created () {
    this.fetchData()
  },
  data () {
    let t = option.targetType
    return {
      chartsData: {
        chart1: { target: [t.loginUser], title: '活跃用户分布图', apiFunc: api.getRegion },
        chart2: { target: [t.loginUserAdd], title: '新增用户分布图', apiFunc: api.getRegion },
        chart3: { target: [t.payMoney], title: '付费金额分布图', apiFunc: api.getRegion },
        chart4: { target: [t.payUser], title: '付费用户分布图', apiFunc: api.getRegion }
      },
      chart1Data: [],
      chart2Data: [],
      chart3Data: [],
      chart4Data: []
    }
  },
  methods: {
    fetchData () {
      for (let chart in this.chartsData) {
        this.fetchChartsData(chart)
      }
    },
    fetchChartsData (chart) {
      let data = this.chartsData[chart]
      let targetValue = data.target[0].value
      data.apiFunc(targetValue).then(res => {
        this[chart + 'Data'] = res
      })
    }
  }
}
</script>
