<template>
  <section>
<!--    <bi-channel-date-search @change="fetchData"></bi-channel-date-search>-->
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart1Tab" @tab-click="fetchChart1Data">
            <el-tab-pane label="DAU" name="tab1"></el-tab-pane>
            <el-tab-pane label="WAU" name="tab2"></el-tab-pane>
            <el-tab-pane label="MAU" name="tab3"></el-tab-pane>
          </el-tabs>
          <chart-line :data="chart1Data" :targets="chart1[chart1Tab].targets"></chart-line>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart2.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-bar :data="chartsData.chart2.data" :targets="chartsData.chart2.targets" :xAxisKey="chartsData.chart2.xAxisKey"></chart-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart3.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-bar :data="chartsData.chart3.data" :targets="chartsData.chart3.targets" :xAxisKey="chartsData.chart3.xAxisKey"></chart-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="isOpenServer">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart4.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-bar :data="chartsData.chart4.data"  :targets="chartsData.chart4.targets" :xAxisKey="chartsData.chart4.xAxisKey"></chart-bar>
        </div>
      </el-col>
    </el-row>

<!--    <div class="box">-->
<!--      <el-tabs v-model="chart1Tab" @tab-click="fetchChart1Data">-->
<!--        <el-tab-pane label="DAU" name="tab1"></el-tab-pane>-->
<!--        <el-tab-pane label="WAU" name="tab2"></el-tab-pane>-->
<!--        <el-tab-pane label="MAU" name="tab3"></el-tab-pane>-->
<!--      </el-tabs>-->
<!--      <chart-line :title="chart1[chart1Tab].title" :data="chart1Data" :targets="chart1[chart1Tab].targets"></chart-line>-->
<!--    </div>-->
<!--    <div class="box-row">-->
<!--      <div class="box-left">-->
<!--        <chart-bar :title="chartsData.chart2.title" :data="chartsData.chart2.data" :targets="chartsData.chart2.targets" :xAxisKey="chartsData.chart2.xAxisKey"></chart-bar>-->
<!--      </div>-->
<!--      <div class="box-right">-->
<!--        <chart-bar :title="chartsData.chart3.title" :data="chartsData.chart3.data" :targets="chartsData.chart3.targets" :xAxisKey="chartsData.chart3.xAxisKey"></chart-bar>-->
<!--      </div>-->
<!--    </div>-->
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartLine from '@/components/Charts/ChartLine'
import ChartBar from '@/components/Charts/ChartBar'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'
export default {
  components: {
    BiChannelDateSearch,
    ChartLine,
    ChartBar
  },
  data () {
    let t = option.targetType
    return {
      isOpenServer: false,
      defaultTarget: t.loginUser,
      chart1Tab: 'tab1',
      chart1Data: {},
      chart1: {
        tab1: { title: '日新增与活跃', targets: [t.loginUser, t.loginUserAdd], data: {} },
        tab2: { title: '周活跃用户', targets: [t.weekLoginUser], data: {} },
        tab3: { title: '月活跃用户', targets: [t.monthLoginUser], data: {} }
      },
      chartsData: {
        chart2: { title: '活跃用户-渠道分布', xAxisKey: 'channel', targets: [t.loginUser], apiFunc: api.getTargetChannel, data: {} },
        chart3: { title: '活跃用户-等级分布', xAxisKey: 'level', targets: [t.loginUser], apiFunc: api.getTargetLevel, data: {} },
        chart4: { title: '活跃用户-服务器分布', xAxisKey: 'server', targets: [t.loginUser], apiFunc: api.getTargetServer, data: {} }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.fetchChart1Data()
      this.fetchChartsData('chart2')
      this.fetchChartsData('chart3')
      if (this.isOpenServer) {
        this.fetchChartsData('chart4')
      }
    },
    fetchChart1Data () {
      let chartData = this.chart1[this.chart1Tab]
      api.getMultiTargetData(chartData.targets, null, this.isOpenServer).then(res => {
        this.chart1Data = res
      })
    },
    fetchChartsData (chart) {
      let data = this.chartsData[chart]
      data.apiFunc(this.defaultTarget.value, this.isOpenServer).then(res => {
        let d = {}
        d[this.defaultTarget.value] = res
        data.data = d
      })
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
