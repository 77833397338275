<template>
    <section>
      <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="box">
            <el-tabs v-model="tabActive" @tab-click="handleChartClick">
              <el-tab-pane :label="t.label" :name="t.value" v-for="t in ltvTargets" :key="t.value"></el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="box">
            <el-tabs value="tab1">
              <el-tab-pane :label="chart1.title" name="tab1"></el-tab-pane>
            </el-tabs>
            <chart-line :data="chart1.data" :targets="chart1.targets"></chart-line>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="box">
            <el-tabs value="tab1">
              <el-tab-pane :label="chart2.title" name="tab1"></el-tab-pane>
            </el-tabs>
            <chart-bar :data="chart2.data" :targets="chart2.targets" :xAxisKey="chart2.xAxisKey"></chart-bar>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="isOpenServer">
          <div class="box">
            <el-tabs value="tab1">
              <el-tab-pane :label="chart3.title" name="tab1"></el-tab-pane>
            </el-tabs>
            <chart-bar :data="chart3.data" :targets="chart3.targets" :xAxisKey="chart3.xAxisKey"></chart-bar>
          </div>
        </el-col>
      </el-row>
    </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartLine from '@/components/Charts/ChartLine'
import ChartBar from '@/components/Charts/ChartBar'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'

export default {
  components: {
    BiChannelDateSearch,
    ChartLine,
    ChartBar
  },
  name: 'Ltv',
  data () {
    let lt = option.ltvType
    let lrt = option.ltvRateType
    let ltvTargets = [lt.l2, lt.l3, lt.l4, lt.l5, lt.l6, lt.l7, lt.l14, lt.l30, lt.l45, lt.l60, lt.l90, lt.l120, lt.l180]
    let ltvRateTargets = [lrt.ltv2, lrt.ltv3, lrt.ltv4, lrt.ltv5, lrt.ltv6, lrt.ltv7, lrt.ltv14, lrt.ltv30, lrt.ltv45, lrt.ltv60, lrt.ltv90, lrt.ltv120, lrt.ltv180]
    return {
      isOpenServer: false,
      ltvTargets: ltvTargets,
      ltvRateTargets: ltvRateTargets,
      tabActive: ltvTargets[0].value,
      chart1: { title: '新玩家价值', targets: [ltvRateTargets[0]], data: {} },
      chart2: { title: '新玩家价值-分渠道', targets: [ltvTargets[0]], xAxisKey: 'channel', data: {} },

      // 分服这个地方需要和服务器对接好
      chart3: { title: '新玩家价值-分服务器', targets: [ltvTargets[0]], xAxisKey: 'server', data: {} }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    handleChartClick () {
      for (let i in this.ltvTargets) {
        let t = this.ltvTargets[i]
        if (t.value === this.tabActive) {
          this.chart1.targets = [this.ltvRateTargets[i]]
          this.chart2.targets = [t]
          this.chart3.targets = [t]
        }
      }
      this.fetchData()
    },
    fetchData () {
      let params = { target: this.tabActive, dist: 'channel' }
      api.getMatrixTargetData(this.isOpenServer, this.tabActive, params).then(res => {
        this.chart1.data = res
        let d = {}
        d[this.tabActive] = res.dist || []
        this.chart2.data = d
      })
      // 这里缺少一个ltv的分服接口
      if (this.isOpenServer) {
        let params = { target: this.tabActive, dist: 'server' }
        api.getMatrixTargetData(this.isOpenServer, this.tabActive, params).then(res => {
          let d = {}
          d[this.tabActive] = res.dist || []
          this.chart3.data = d
        })
      }
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
