<template>
  <section>
    <bi-channel-date-search @change="fetchData" :loading="loading"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="type" @tab-click="fetchData">
            <el-tab-pane label="新增用户" name="add"></el-tab-pane>
            <el-tab-pane label="活跃用户" name="active"></el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="tabActive">
            <el-tab-pane label="流程流失率" name="tab1"></el-tab-pane>
            <el-tab-pane label="用户角色数" name="tab2"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chartsData.chart1" v-if="tabActive === 'tab1'"></chart-line-bar>
          <chart-line-bar :data="chartsData.chart2" v-else></chart-line-bar>
        </div>
      </el-col>
    </el-row>
    <div class="box" style="margin-bottom: 20px">
      <el-table :data="tableData" v-loading="loading" stripe style="width: 100%" show-summary :summary-method="getSummaries">
        <el-table-column :prop="item.value"
                         :label="item.label"
                         :width="item.value === 'date'? '100' : 'auto'"
                         :fixed="item.value === 'date'" v-for="item in tableHeaderList"
                         :key="item.value">
        </el-table-column>
      </el-table>
      <export-excel v-show="exportExcelVisible" :data="exportData" :headers="tableHeaderList" :filename="filename"></export-excel>
    </div>
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'
import ExportExcel from '@/components/Excel/ExportExcel'
export default {
  components: {
    ChartLineBar,
    ExportExcel,
    BiChannelDateSearch
  },
  data () {
    let t = option.targetType
    let thList = [
      { value: 'verify', label: '验证数' },
      { value: 'verifyPassed', label: '通过数' },
      { value: 'roleUser', label: '角色账号' },
      { value: 'role', label: '角色数' },
      { value: 'churnRate', label: '流失率（%）' },
      { value: 'verifyChurnRate', label: '验证流失率（%）' }
    ]
    return {
      type: 'add',
      loading: false,
      tableData: [],
      sumData: {},
      thList: thList,
      exportExcelVisible: false,
      exportData: [],
      tableHeaderList: [],
      tableLabel: '新增用户',
      tabActive: 'tab1',
      filename: '流程统计',
      chartsData: {
        chart1: { targets: [t.churnRate, t.verifyChurnRate], title: '流失率及验证流失率', type: 'line', data: {} },
        chart2: { targets: [t.user, t.roleUser, t.role], title: '用户数、角色账号及角色数', type: 'bar', data: {} }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      let type = this.type
      this.tableLabel = this.type === 'add' ? '新增用户' : '活跃用户'
      this.tableData = []
      this.sumData = []
      this.loading = true
      api.getProcess(type).then(res => {
        this.loading = false
        if (res.data.length === 0) {
          this.$message.info('暂无数据')
          return
        }
        this.tableData = res.data
        this.sumData = res.sum
        let data = {}
        for (let target in res.data[0]) {
          if (target === 'date') continue
          data[target] = []
          for (let item of res.data) {
            data[target].push({ date: item.date, value: item[target] })
          }
        }
        this.chartsData.chart1.data = this.chartsData.chart2.data = data
        // 导出所需数据
        let thDate = { value: 'date', label: '日期' }
        let thUser = { value: 'user', label: this.tableLabel }
        this.tableHeaderList = [...[thDate, thUser], ...this.thList]
        this.exportData = [...this.tableData]
        let sum = Object.assign({ date: '统计' }, this.sumData)
        this.exportData.push(sum)
        this.exportExcelVisible = true
      })
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      if (!this.sumData) {
        return sums
      }
      columns.forEach((column, index) => {
        let key = column.property
        if (index === 0) {
          sums[index] = '统计'
          return
        }
        sums[index] = this.sumData[key]
      })
      return sums
    }
  }
}
</script>
