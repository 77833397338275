<template>
  <section>
    <!--<el-tab-pane label="Android">-->
    <!--Android填写订单-->
    <div class="bi-channel-date-search">
      <el-form :label-position="labelPosition" label-width="100px" :model="AndroidForm" inline>
        <el-row>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="订单ID">
              <el-input v-model="AndroidForm.orderID" placeholder="订单ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="平台用户ID">
              <el-input v-model="AndroidForm.userID" placeholder="平台用户ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="选择状态">
              <el-select v-model="AndroidForm.state" placeholder="请选择" clearable>
                <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="选择渠道">
              <channel-select></channel-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="渠道订单ID">
              <el-input v-model="AndroidForm.channelOrderID" placeholder="渠道订单ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="渠道用户ID">
              <el-input v-model="AndroidForm.channelUserID" placeholder="渠道用户ID"></el-input>
            </el-form-item>
          </el-col>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="选择服务器">
              <template>
                <server-select></server-select>
              </template>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="角色ID">
              <el-input v-model="AndroidForm.roleID" placeholder="角色ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="角色名称">
              <el-input v-model="AndroidForm.roleName" placeholder="支持模糊查询"></el-input>
            </el-form-item>
          </el-col>
          <!--<el-row>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="开始时间">
              <el-date-picker v-model="AndroidForm.st" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH-mm-ss"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-form-item label="结束时间">
              <el-date-picker v-model="AndroidForm.et" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH-mm-ss"> </el-date-picker>
            </el-form-item>
          </el-col>
          <!--<el-form-item label="excPayAdmin" style="width: 30%">-->
          <!--<el-checkbox v-model="AndroidForm.excPayAdmin">excPayAdmin</el-checkbox>-->
          <!--</el-form-item>-->
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-checkbox v-model="AndroidForm.excTestServer">排除测试服</el-checkbox>
            <el-button type="primary" @click="queryOrders">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!--信息展示区-->
    <div class="box">
      <!--分页器-->
      <div class="block" style="float: left" v-show="androidPagination">
        <el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.page"
                       :page-sizes="[20, 30, 50, 100, 200, 500, 1000]" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
      <!--Android表格展示区-->
      <el-table :data="ordersData" style="width: 100%" border size="medium" stripe :summary-method="getSummaries" show-summary>
        <el-table-column prop="orderID" label="平台订单ID" sortable> </el-table-column>
        <el-table-column prop="userID" label="用户ID" sortable> </el-table-column>
        <el-table-column prop="roleID" label="角色ID" sortable> </el-table-column>
        <el-table-column prop="roleName" label="角色名称" sortable> </el-table-column>
        <el-table-column prop="money" label="金额" :formatter="moneyInit" sortable> </el-table-column>
        <el-table-column prop="state" label="状态" :formatter="payState" sortable> </el-table-column>
        <el-table-column prop="channelID" label="渠道ID" :formatter="channelText" sortable> </el-table-column>
        <el-table-column prop="channelOrderID" label="渠道订单ID" sortable> </el-table-column>
        <el-table-column prop="serverID" label="服务器ID" sortable> </el-table-column>
        <el-table-column prop="completeTime" label="订单完成时间" sortable> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="AndroidShowMore(scope.row)" type="text" size="small">查看更多</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="block" style="float: left" v-show="androidPagination">
        <el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pagination.page" :page-sizes="[20, 30, 50, 100, 200, 500, 1000]" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper"
                       :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!--</el-tab-pane>-->

    <!--<el-tab-pane label="IOS" name="second">-->
      <!--&lt;!&ndash;IOS填写订单&ndash;&gt;-->
      <!--<el-card style="margin: 20px 0">-->
        <!--<el-form :label-position="labelPosition" label-width="150px" :model="iosForm" inline>-->
          <!--<el-row :gutter="20">-->
            <!--<el-col :lg="8" :xs="12" :md="24">-->
              <!--<el-form-item label="选择服务器">-->
                <!--<server-select></server-select>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :lg="8" :xs="12" :md="24">-->
              <!--<el-form-item label="角色ID">-->
                <!--<el-input v-model="iosForm.roleID"></el-input>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :lg="8" :xs="12" :md="24">-->
              <!--<el-form-item label="角色名称">-->
                <!--<el-input v-model="iosForm.roleName" placeholder="支持模糊查询"></el-input>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :lg="8" :xs="12" :md="24">-->
              <!--<el-form-item label="订单ID">-->
                <!--<el-input v-model="iosForm.orderID"></el-input>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :lg="8" :xs="12" :md="24">-->
              <!--<el-form-item label="平台用户ID">-->
                <!--<el-input v-model="iosForm.userID"></el-input>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :lg="8" :xs="12" :md="24">-->
              <!--<el-form-item label="订单状态">-->
                <!--<el-select v-model="iosForm.state" placeholder="请选择" clearable>-->
                  <!--<el-option v-for="item in orderStateList" :key="item.value" :label="item.label" :value="item.value"> </el-option>-->
                <!--</el-select>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :lg="8" :xs="12" :md="24">-->
              <!--<el-form-item label="通知状态">-->
                <!--<el-select v-model="iosForm.status" placeholder="请选择" clearable>-->
                  <!--<el-option v-for="item in informStateList" :key="item.value" :label="item.label" :value="item.value"> </el-option>-->
                <!--</el-select>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">-->
              <!--<el-form-item label="开始时间">-->
                <!--<el-date-picker v-model="iosForm.st" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH-mm-ss"> </el-date-picker>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">-->
              <!--<el-form-item label="结束时间">-->
                <!--<el-date-picker v-model="iosForm.et" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH-mm-ss"> </el-date-picker>-->
              <!--</el-form-item>-->
            <!--</el-col>-->
          <!--</el-row>-->

        <!--</el-form>-->
      <!--</el-card>-->
      <!--<el-card class="box-card" style="margin: 20px 0">-->
        <!--<el-button type="primary" @click="queryIosOrders">查询</el-button>-->
      <!--</el-card>-->
      <!--&lt;!&ndash;信息展示区&ndash;&gt;-->
      <!--<el-card style="margin: 20px 0">-->
        <!--&lt;!&ndash;分页器&ndash;&gt;-->
        <!--<div class="block" style="float: left" v-show="iosPagination">-->
          <!--<el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.page"-->
                         <!--:page-sizes="[20, 30, 50, 100, 200, 500, 1000]" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">-->
          <!--</el-pagination>-->
        <!--</div>-->
        <!--&lt;!&ndash;ios表格展示区&ndash;&gt;-->
        <!--<el-button @click = "iosDataDownExcel" type="primary" style="float: right">下载到本地</el-button>-->
        <!--<el-table :data="iosOrdersData" style="width: 100%" border size="medium" stripe :summary-method="getSummaries" show-summary>-->
          <!--<el-table-column prop="id" label="订单ID" sortable > </el-table-column>-->
          <!--<el-table-column prop="uid" label="平台用户ID" sortable > </el-table-column>-->
          <!--<el-table-column prop="productID" label="商品ID" sortable > </el-table-column>-->
          <!--<el-table-column prop="serverID" label="服务器" :formatter="iosServerText" sortable> </el-table-column>-->
          <!--<el-table-column prop="price" label="价格" :formatter="priceInit" sortable> </el-table-column>-->
          <!--<el-table-column prop="roleID" label="角色ID" sortable > </el-table-column>-->
          <!--<el-table-column prop="roleName" label="角色名称" sortable > </el-table-column>-->
          <!--<el-table-column prop="createDate" label="订单创建时间" sortable > </el-table-column>-->
          <!--<el-table-column label="操作">-->
            <!--<template slot-scope="scope">-->
              <!--<el-button @click="iosShowMore(scope.row)" type="text" size="small">查看更多</el-button>-->
            <!--</template>-->
          <!--</el-table-column>-->
        <!--</el-table>-->
        <!--&lt;!&ndash;分页器&ndash;&gt;-->
        <!--<div class="block" style="float: left" v-show="iosPagination">-->
          <!--<el-pagination style="padding: 10px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"-->
                         <!--:current-page="pagination.page" :page-sizes="[20, 30, 50, 100, 200, 500, 1000]" :page-size="pagination.size" layout="total, sizes, prev, pager, next, jumper"-->
                         <!--:total="pagination.total">-->
          <!--</el-pagination>-->
        <!--</div>-->
      <!--</el-card>-->
    <!--</el-tab-pane>-->
    <!--&lt;!&ndash;ios更多信息展示&ndash;&gt;-->
    <!--<el-dialog title="更多信息" :visible.sync="iosDialogShow">-->
      <!--<el-form :model="moreOrders" v-for="(item, key) in iosOrdersFormList" :key="item" label-position="right" label-width="120px" size="medium">-->
        <!--<el-form-item :label="item">-->
          <!--<el-input v-model="moreOrders[key]" auto-complete="off" disabled> </el-input>-->
        <!--</el-form-item>-->
      <!--</el-form>-->
    <!--</el-dialog>-->
    <!--Android更多信息展示-->
    <el-dialog title="更多信息" :visible.sync="androidDialogShow">
      <el-form :model="moreOrders" v-for="(item, key) in androidOrdersFormList" :key="item" label-position="right" label-width="120px">
        <el-form-item :label="item">
          <el-input v-model="moreOrders[key]" auto-complete="off" disabled> </el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import ChannelSelect from '@/components/Channel/ChannelSelect'
import ServerSelect from '@/components/Server/ServerSelect'
import api from '@/api/fee'
import f from '@/filter'
export default {
  name: 'orders',
  components: {
    ChannelSelect,
    ServerSelect
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    channelId () {
      return this.$store.getters.channelId
    },
    serverId () {
      return this.$store.getters.serverId
    }
  },
  created () {
    this.$store.dispatch('ChangeChannelId', 0)
    this.$store.dispatch('ChangeServerId', 0)
    // console.log('game3 orders by u8server')
  },
  data () {
    return {
      labelPosition: 'left',
      AndroidForm: {
        orderID: '',
        channelOrderID: '',
        userID: '',
        roleID: '',
        roleName: '',
        channelUserID: '',
        state: 3,
        excPayAdmin: false,
        excTestServer: false,
        st: '',
        et: ''
      },
      ordersData: [],
      pagination: {
        total: 0,
        size: 0,
        page: 1
      },
      stateList: [
        {
          value: 1,
          label: '待支付'
        }, {
          value: 2,
          label: '待处理'
        }, {
          value: 3,
          label: '支付成功'
        }
      ],
      orderStateList: [
        {
          value: -1,
          label: '失败'
        }, {
          value: 1,
          label: '支付中'
        }, {
          value: 2,
          label: '已提交'
        }, {
          value: 3,
          label: '已处理'
        }
      ],
      informStateList: [
        {
          value: 0,
          label: '未通知'
        }, {
          value: 1,
          label: '已通知'
        }, {
          value: 2,
          label: '通知失败'
        }
      ],
      iosForm: {
        orderID: '',
        userID: '',
        roleID: '',
        roleName: '',
        state: '',
        status: '',
        st: '',
        et: ''
      },
      iosOrdersData: [],
      iosDialogShow: false,
      moreOrders: [],
      iosOrdersFormList: {
        commitDate: '订单提交时间',
        finishDate: '完成时间',
        lastUpdateDate: '最后更新时间',
        platformUserID: '支付用户ID',
        currency: '货币单位',
        extra: '扩展信息',
        notifyUrl: '回调地址',
        notifyStatus: '回调状态',
        orderType: '订单类型',
        payType: '支付类型',
        platformAmount: '支付总计',
        platformOrderID: '支付订单ID',
        platformOrderInfo: '支付订单初始化',
        platformStatus: '支付状态',
        platformTime: '支付时间',
        productDesc: '商品描述',
        productName: '名称',
        roleLevel: '角色等级',
        state: '状态',
        vip: 'vip'
      },
      iosLegendKey: {
        id: '订单ID',
        uid: '平台用户ID',
        productID: '商品ID',
        serverID: '服务器',
        price: '价格',
        roleID: '角色ID',
        roleName: '角色名称',
        createDate: '订单创建时间',
        commitDate: '订单提交时间',
        finishDate: '完成时间',
        lastUpdateDate: '最后更新时间',
        platformUserID: '支付用户ID',
        currency: '货币单位',
        extra: '扩展信息',
        notifyUrl: '回调地址',
        notifyStatus: '回调状态',
        orderType: '订单类型',
        payType: '支付类型',
        platformAmount: '支付总计',
        platformOrderID: '支付订单ID',
        platformOrderInfo: '支付订单初始化',
        platformStatus: '支付状态',
        platformTime: '支付时间',
        productDesc: '商品描述',
        productName: '名称',
        roleLevel: '角色等级',
        state: '状态',
        vip: 'vip'
      },
      androidDialogShow: false,
      androidOrdersFormList: {
        serverName: '服务器名称',
        channelName: '渠道名称',
        cpOrderId: 'CP订单号',
        createdTime: '创建时间',
        username: '用户名称',
        currency: '货币单位',
        extension: '扩展信息',
        notifyUrl: '回调地址',
        productDesc: '商品描述',
        productID: '商品ID',
        productName: '商品名称',
        realMoney: '现金',
        roleLevel: '角色等级',
        sdkOrderTime: 'sdkOrderTime',
        subChannelID: 'subChannelID'
      },
      androidLegendKey: {
        orderID: '平台订单ID',
        userID: '用户ID',
        roleID: '角色ID',
        roleName: '角色名称',
        money: '金额',
        state: '状态',
        channelID: '渠道ID',
        channelOrderID: '渠道订单ID',
        serverID: '服务器ID',
        completeTime: '订单完成时间',
        serverName: '服务器名称',
        channelName: '渠道名称',
        cpOrderId: 'CP订单号',
        createdTime: '创建时间',
        username: '用户名称',
        currency: '货币单位',
        extension: '扩展信息',
        notifyUrl: '回调地址',
        productDesc: '商品描述',
        productID: '商品ID',
        productName: '商品名称',
        realMoney: '现金',
        roleLevel: '角色等级',
        sdkOrderTime: 'sdkOrderTime',
        subChannelID: 'subChannelID'
      },
      iosPagination: false,
      androidPagination: false
    }
  },
  methods: {
    fetchData () {
      let params = this.AndroidForm
      params.appID = this.appId
      params.channelID = this.channelId
      params.serverID = this.serverId
      params.page = this.pagination.page
      params.size = this.pagination.size
      for (let item in params) {
        if (params[item] === '' || params[item] === false || params[item] === 0) {
          delete params[item]
        }
      }
      if (!params) {
        this.$message.error('请填写内容')
        return
      }
      api.getOrders3(params).then(res => {
        if (res.content.length !== 0) {
          this.androidPagination = true
        } else {
          this.androidPagination = false
        }
        // 内容
        this.ordersData = res.content
        // 总条目数
        this.pagination.total = res.totalSizes
        // 每页展示条数
        this.pagination.size = res.size
        // 当前是第几页
        this.pagination.page = res.page
      }).catch(_ => {
        this.downloadLoading = false
      })
    },
    queryOrders () {
      this.fetchData()
    },
    queryIosOrders () {
      let params = this.iosForm
      params.appID = 2
      params.serverID = this.serverId
      params.page = this.pagination.page
      params.size = this.pagination.size
      for (let item in params) {
        if (params[item] === '' || params[item] === false || params[item] === 0) {
          delete params[item]
        }
      }
      if (!params) {
        this.$message.error('请填写内容')
        return
      }
      api.getIosOrders(params).then(res => {
        if (res.content.length !== 0) {
          this.iosPagination = true
        } else {
          this.iosPagination = false
        }
        // 内容
        this.iosOrdersData = res.content
        // 总条目数
        this.pagination.total = res.totalSizes
        // 每页展示条数
        this.pagination.size = res.size
        // 当前是第几页
        this.pagination.page = res.page
      })
    },
    // 每页条数改变的时候触发
    handleSizeChange (val) {
      this.pagination.size = val
      if (this.ordersData.length === 0) {
        return
      }
      this.fetchData()
    },
    // 当前页改变的时候触发
    handleCurrentChange (val) {
      this.pagination.page = val
      if (this.ordersData.length === 0) {
        return
      }
      this.fetchData()
    },
    // 初始化状态
    payState (row) {
      if (row.state === 1) {
        return '待支付'
      } else if (row.state === 2) {
        return '待处理'
      } else if (row.state === 3) {
        return '支付成功'
      }
    },
    // // 初始化渠道名字
    // channnelText (row) {
    //   let str = f.channelFormat(row.channelID)
    //   str.substr(str.indexOf('-'))
    //   return str.substr(str.indexOf('-') + 1)
    // },
    // 查看更多
    iosShowMore (row) {
      this.moreOrders = row
      this.iosDialogShow = true
    },
    AndroidShowMore (row) {
      this.moreOrders = row
      this.androidDialogShow = true
    },
    priceInit (row) {
      return row.price / 100
    },
    moneyInit (row) {
      return row.money / 100
    },
    channelText (row) {
      return f.channelFormat(row.channelID)
    },
    iosServerText (row) {
      return row.serverID + '-' + row.serverName
    },
    // 结尾展示总计
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      if (Number(this.AndroidForm.state) === 3 || Number(this.iosForm.state) === 3) {
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          }
          const values = data.map(item => Number(item[column.property]))
          if (index === 4) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return (prev + curr)
              } else {
                return prev
              }
            }, 0)
            sums[index] = sums[index] / 100 + ' 元'
          }
        })
      }
      return sums
    }
  }
}
</script>
