<template>
  <section>
<!--    <bi-channel-date-search @change="fetchData"></bi-channel-date-search>-->
    <bi-channel-date-search @change="fetchData" :isGame="false" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chart1.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-map :data="chart1.data" :targets="chart1.target"></chart-line-map>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chart2.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-map :data="chart2.data" :targets="chart2.target"></chart-line-map>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chart3.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-map :data="chart3.data" :targets="chart3.target" :units='chart3.units'></chart-line-map>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import api from '@/api/bi'
import ChartLineMap from '@/components/Charts/ChartLineMap'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'

export default {
  components: {
    BiChannelDateSearch,
    ChartLineMap
  },
  name: 'Distribution',
  created () {
    this.fetchData()
  },
  data () {
    return {
      isOpenServer: false,
      chart1: { title: '新增用户', target: [{ label: '新增用户', target: 'payMoneyAdd', value: 'loginUserAdd' }], data: [] },
      chart2: { title: '活跃用户', target: [{ label: '活跃用户', target: 'payMoney', value: 'loginUser' }], data: [] },
      chart3: { title: '付费金额', target: [{ label: '付费金额', target: 'payMoneyDist', value: 'payMoney' }], units: '元', data: [] }
    }
  },
  methods: {
    fetchData () {
      this.fetchChartData('chart1')
      this.fetchChartData('chart2')
      this.fetchChartData('chart3')
    },
    fetchChartData (chart) {
      let chartData = this[chart]
      api.getPayDistribution(chartData.target[0].target, this.isOpenServer).then(res => {
        chartData.data = res
      })
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
