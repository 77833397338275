<template>
  <section>
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart1.tabActive" @tab-click="fetchChart1Data">
            <el-tab-pane label="付费金额" name="tab1"> </el-tab-pane>
            <el-tab-pane label="付费次数" name="tab2"> </el-tab-pane>
            <el-tab-pane label="付费用户" name="tab3"> </el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart1.data"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart2.tabActive" @tab-click="fetchChart2Data">
            <el-tab-pane label="付费金额" name="tab1"> </el-tab-pane>
            <el-tab-pane label="付费人次" name="tab2"> </el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart2.data"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="付费金额-渠道分布" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart3.data"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="isOpenServer">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="付费金额-服务器分布" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart4.data"></chart-line-bar>
        </div>
      </el-col>
    </el-row>

    <div class="box">

    </div>
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'

export default {
  components: {
    BiChannelDateSearch,
    ChartLineBar
  },
  name: 'PayMoney',
  data () {
    let t = option.targetType
    return {
      isOpenServer: false,
      chart1: {
        tabActive: 'tab1',
        tab1: { title: '付费金额', targets: [t.payMoney], type: 'line' },
        tab2: { title: '付费次数', fetchTarget: t.payCount.value, targets: [t.payCount], type: 'line' },
        tab3: { title: '付费用户', targets: [t.payUser], type: 'line' },
        data: {}
      },
      chart2: {
        tabActive: 'tab1',
        tab1: { title: '付费金额-等级分布', fetchTarget: t.payMoney.value, targets: [t.payMoney], xAxisKey: 'level', type: 'line' },
        tab2: { title: '付费人次-等级分布', fetchTarget: t.payCount.value, targets: [t.payCount], xAxisKey: 'level', type: 'line' },
        data: {}
      },
      chart3: {
        data: { title: '付费金额-渠道分布', targets: [t.payMoney], xAxisKey: 'channel', data: {} }
      },
      chart4: {
        data: { title: '付费金额-服务器分布', targets: [t.payMoney], xAxisKey: 'server', data: {} }
      }
    }
  },
  created () {
    this.fetchChart1Data()
    this.fetchChart2Data()
  },
  methods: {
    fetchData () {
      this.fetchChart1Data()
      this.fetchChart2Data()
      if (this.isOpenServer) {
        this.fetchChart4Data()
      }
    },
    fetchChart1Data () {
      let tab = this.chart1.tabActive
      let data = Object.assign({}, this.chart1[tab])
      if (tab === 'tab2') {
        api.getTargetData(data.fetchTarget, this.isOpenServer).then(res => {
          let d = {}
          d[data.fetchTarget] = res
          data.data = d
          this.chart1.data = data
        })
      } else {
        let params = { target: 'payMoney', dist: 'channel' }
        api.getMatrixTargetData(this.isOpenServer, 'payMoney', params).then(res => {
          data.data = res
          this.chart1.data = data
          this.chart3.data.data = { payMoney: res.dist }
        })
      }
    },
    fetchChart2Data () {
      let tab = this.chart2.tabActive
      let data = Object.assign({}, this.chart2[tab])
      api.getTargetLevel(data.fetchTarget, this.isOpenServer).then(res => {
        let d = {}
        d[data.fetchTarget] = res
        data.data = d
        this.chart2.data = data
      })
    },
    fetchChart4Data () {
      let data = Object.assign({}, this.chart4.data)
      let params = { target: 'payMoney', dist: 'server' }
      api.getMatrixTargetData(this.isOpenServer, 'payMoney', params).then(res => {
        data.data = res
        this.chart4.data.data = { payMoney: res.dist }
      })
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
