<template>
  <el-select v-model="channelId" placeholder="请选择渠道(默认全部)" @change="handleChange" filterable clearable>
    <el-option v-for="item in channelOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>
<script>
export default {
  name: 'ChannelSelect',
  data () {
    return {
      channelId: this.$store.getters.channelId
    }
  },
  created () {
    // if (!this.channelId) {
    //   this.channelId = ''
    // }
  },
  computed: {
    channelOptions () {
      return this.$store.getters.channels
    }
  },
  methods: {
    handleChange () {
      this.$store.dispatch('ChangeChannelId', this.channelId).then(() => {})
    }
  }
}
</script>
