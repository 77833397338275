
<template>
  <div class="bg-white charts-canvas" style="height:500px"></div>
</template>

<script>
import f from '@/filter'
import util from '@/common/util'
require('echarts/theme/macarons')

export default {
  name: 'ChartLineMap',
  props: {
    data: { // 原始数据
      required: true
    },
    // title: {
    //   type: String,
    //   required: true
    // },
    targets: {
      type: Array,
      required: true
    },
    units: {
      default: '人'
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    data: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
    let _this = this
    function handle () {
      if (_this.chart) {
        _this.chart.resize()
      }
    }
    window.addEventListener('resize', util.debounce(handle, 500))
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = this.$echarts.init(this.$el, 'macarons')
      this.setOptions(this.data)
    },
    setOptions (chartData) {
      this.chart.clear()
      if (!chartData.length) {
        return
      }
      let data = [] // 最后需要的数据
      let arr = ['product'] // 存放日期的数组
      let payRangeSet = new Set() // 存放所有的区间 去重
      let tmpRVData = []
      let units = this.units || '人'
      for (let item of chartData) {
        if (!item) {
          continue
        }
        arr.push(item.date)
        let rvMap = new Map()
        for (let d of item.data) {
          let payRange = f.payRangeFormat(d.payRange)
          payRangeSet.add(payRange)
          rvMap.set(payRange, d.value)
        }
        tmpRVData.push(rvMap)
      }
      for (let item of payRangeSet) {
        data.push([item])
      }
      data.unshift(arr)
      data.forEach((val, i) => {
        if (i === 0) return
        let key = val[0] // 每个区间
        for (let rvMap of tmpRVData) {
          let rv = rvMap.get(key) || 0
          val.push(rv)
        }
      })
      let series = Array.from({ length: data.length }, () => {
        return { type: 'line', smooth: true, seriesLayoutBy: 'row' }
      })
      let dimension = data[0][1]
      let pie = {
        type: 'pie',
        id: 'pie',
        radius: '30%',
        center: ['50%', '30%'],
        label: {
          formatter: '{b}: {@' + dimension + '}' + units + ' ({d}%)'
        },
        encode: {
          itemName: 'product',
          value: dimension,
          tooltip: dimension
        }
      }
      series.push(pie)
      this.chart.clear()
      this.chart.setOption({
        // title: {
        //   text: this.title || ''
        // },
        legend: {},
        tooltip: {
          trigger: 'axis', // 坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          axisPointer: { // 坐标轴指示器
            type: 'cross' // 十字准心指示器
          },
          showContent: false
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        dataset: {
          source: data
        },
        xAxis: { type: 'category' },
        yAxis: { gridIndex: 0 },
        grid: { top: '50%' },
        series: series
      })
      let self = this
      this.chart.on('updateAxisPointer', function (event) {
        let xAxisInfo = event.axesInfo[0]
        if (xAxisInfo) {
          let dimension = xAxisInfo.value + 1
          self.chart.setOption({
            series: {
              id: 'pie',
              label: {
                formatter: '{b}: {@[' + dimension + ']}' + units + ' ({d}%)'
              },
              encode: {
                value: dimension,
                tooltip: dimension
              }
            }
          })
        }
      })
    }
  }
}
</script>
