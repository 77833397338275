<template>
    <section>
      <div class="bi-channel-date-search">
        <el-form ref="filterForm" :inline="true" :model="{roleId}" :rules="filterFormRule">
          <!-- <el-form-item>
            <server-select></server-select>
          </el-form-item> -->
          <el-form-item prop="roleId">
            <el-input v-model="roleId" placeholder="角色ID" clearable></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-input v-model="roleName" placeholder="角色名称(模糊查询)" clearable></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="fetchData" :loading="loading">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="box">
        <el-alert title="TOP 50" type="warning" :closable="false" show-icon> </el-alert>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="userId" label="用户ID" fixed>
            <template slot-scope="scope">
              <el-button @click="handleSearchRoleList(scope.row.userId)" type="text">
               {{scope.row.userId}}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="用户充值(元)"> </el-table-column>
          <el-table-column :prop="item.value" :label="item.label" v-for="item in roleList" :key="item.value" :formatter="autoFormatter"> </el-table-column>
          <el-table-column label="迁移">
            <template slot-scope="scope">
              <el-button @click="handleTransfer(scope.row)" type="text">迁移</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog :visible.sync="dialogTableVisible" :width="isMobile ? '100%' : '65%'" :title="title">
        <el-table :data="roleListData" style="width: 100%">
          <el-table-column prop="money" label="角色充值(元)"> </el-table-column>
          <el-table-column :prop="item.value" :label="item.label" v-for="item in roleList" :key="item.value" :formatter="autoFormatter"> </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog title="确认信息" :visible.sync="dialogTransferVisible" center>
        <el-table :data="transferData">
          <el-table-column prop="userId" label="用户ID" width="120"> </el-table-column>
          <el-table-column prop="channelId" label="渠道ID" :formatter="autoFormatter"> </el-table-column>
          <el-table-column prop="serverId" label="服务器ID"> </el-table-column>
          <el-table-column prop="roleId" label="角色ID"> </el-table-column>
          <el-table-column prop="roleName" label="角色名称"> </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTransferVisible = false">取 消</el-button>
          <!--          transferData内只有一条数据，element表格不支持对象格式-->
          <el-button type="primary" @click="handleSubmitTransfer(transferData[0])">确 定</el-button>
        </div>
      </el-dialog>
      <!--      账号迁移返回结果dialog-->
      <el-dialog title="返回结果" center :visible.sync="dialogResultVisible" width="30%">
        <el-input placeholder="账 号" v-model="transferResult.username" disabled style="margin-bottom: 10px">
          <template slot="prepend">账 号</template>
        </el-input>
        <el-input placeholder="密 码" v-model="transferResult.password" disabled>
          <template slot="prepend">密 码</template>
        </el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="copy" type="primary">复制</el-button>
        </div>
      </el-dialog>
    </section>
</template>

<script>
// import ServerSelect from '@/components/Server/ServerSelect'
import api from '@/api/bi'
import f from '@/filter'
export default {
  name: 'Role',
  components: {
    // ServerSelect
  },
  computed: {
    serverId () {
      return this.$store.getters.serverId
    },
    isMobile () {
      return this.$store.getters.isMobile
    },
    appId () {
      return this.$store.getters.appId
    }
  },
  data () {
    const roleList = [
      { value: 'channelId', label: '渠道ID' },
      { value: 'serverId', label: '服务器ID' },
      { value: 'roleId', label: '角色ID' },
      { value: 'roleName', label: '角色名称' },
      { value: 'roleLevel', label: '角色等级' },
      { value: 'createTime', label: '创建时间' },
      { value: 'lastLoginTime', label: '最后登录时间' },
      { value: 'model', label: '设备' },
      { value: 'region', label: '地区' },
      { value: 'fromServerId', label: '滚服来源' }
    ]
    return {
      roleId: '',
      roleName: '',
      title: '',
      tableData: [],
      roleList: roleList,
      // tableList: tableList,
      loading: false,
      dialogTableVisible: false,
      roleListData: [],
      transferData: [],
      dialogTransferVisible: false,
      dialogResultVisible: false,
      transferResult: {},
      filterFormRule: { roleId: { required: true, message: '请填写角色ID' } }
    }
  },
  methods: {
    fetchData () {
      this.$refs.filterForm.clearValidate()
      this.$refs.filterForm.validate((valid) => {
        if (!valid) return false

        let params = {
          // serverId: this.serverId,
          roleId: this.roleId
          // roleName: this.roleName
        }
        this.tableData = []
        this.roleListData = []
        this.loading = true
        api.getRoleData(params).then(res => {
          this.loading = false
          if (!res.length) {
            this.$message.info('没有查询到相关数据')
          }
          this.tableData = res
        })
      })
    },
    handleSearchRoleList (userId) {
      api.getUserRoleList(userId).then(res => {
        if (!res.length) {
          this.$message.info('没有查询到角色相关数据')
        }
        this.title = `【${userId}】角色列表`
        this.dialogTableVisible = true
        this.roleListData = res
      })
    },
    // 打开账户迁移弹窗
    handleTransfer (rowData) {
      this.transferData = [rowData]
      this.dialogTransferVisible = true
    },
    // 发送账户迁移
    handleSubmitTransfer ({ userId, channelId, serverId, roleId, roleName }) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = { userId, channelId, serverId, roleId, roleName }
      api.accountTransfer(this.appId, params).then(res => {
        this.dialogTransferVisible = false
        this.$message.success('迁移成功')
        this.transferResult = res
        this.dialogResultVisible = true
        loading.close()
      }).catch(() => {
        loading.close()
      })
    },
    // 复制迁移结果
    copy () {
      let input = document.createElement('input')
      input.value = '账号：' + this.transferResult.username + ' ' + '密码：' + this.transferResult.password
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
      this.dialogResultVisible = false
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    }
  }
}
</script>

<style scoped>
.bi-channel-date-search {
  padding: 8px 10px 18px;
}
  .el-form-item {
    margin-bottom: 0;
  }
</style>
