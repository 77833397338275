<template>
  <div class="bi-channel-date-search">
    <div class="box" style="padding: 5px 5px;margin-bottom: 5px" v-show="isGame">
      <el-switch v-model="isServer" active-color="#13ce66" active-text="游戏数据" @change="handleServerSwitchChange"> </el-switch>
    </div>
    <el-form :inline="true">
      <el-form-item v-if="isServer">
        <server-multiple-select @serverList="getServerList"></server-multiple-select>
      </el-form-item>
      <el-form-item>
        <channel-cascade :multiple="true"></channel-cascade>
      </el-form-item>
      <el-form-item>
        <date-range :type="type"></date-range>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleChange" :loading="loading">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import DateRange from '../DateRange'
import ChannelCascade from '../Channel/ChannelCascade'
import ServerMultipleSelect from '@/components/Server/ServerMultipleSelect'
export default {
  components: {
    DateRange,
    ServerMultipleSelect,
    ChannelCascade
  },
  props: {
    type: {
      default: 'date'
    },
    loading: {
      default: false
    },
    // 是否是游戏数据  默认为平台数据 false
    isGame: {
      default: false
    }
  },
  data () {
    return {
      serverList: [],
      isServer: false
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    timeValue () {
      return this.$store.getters.dataPickerValue
    },
    channelCascadeIds () {
      return this.$store.getters.channelCascadeIds
    }
  },
  methods: {
    handleChange () {
      this.$emit('change')
      this.$emit('isLoading', true)
    },
    getServerList (serverList) {
      this.serverList = serverList
    },
    handleServerSwitchChange (value) {
      this.$emit('isServer', value)
      this.$emit('change')
    }
  }
}
</script>
<style scoped>
</style>
