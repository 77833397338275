import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=b6302a70&scoped=true&functional=true"
import script from "./Table.vue?vue&type=script&lang=js"
export * from "./Table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "b6302a70",
  null
  
)

export default component.exports