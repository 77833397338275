<template>
  <section>
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart1.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-bar :data="chartsData.chart1.data" :targets="chartsData.chart1.target" :xAxisKey="chartsData.chart1.xAxisKey"></chart-bar>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane :label="chartsData.chart2.title" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-bar :data="chartsData.chart2.data" :targets="chartsData.chart2.target" :xAxisKey="chartsData.chart2.xAxisKey"></chart-bar>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import api from '@/api/bi'
import ChartBar from '@/components/Charts/ChartBar'
import option from '@/common/option'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'

export default {
  components: {
    BiChannelDateSearch,
    ChartBar
  },
  name: 'PlayerLevel',
  created () {
    this.fetchData()
  },
  data () {
    let t = option.targetType
    return {
      isOpenServer: false,
      chartsData: {
        chart1: { title: '活跃用户-等级分布', target: [t.loginUser], apiFunc: api.getTargetLevel, xAxisKey: 'level', data: {} },
        chart2: { title: '游戏次数-等级分布', target: [t.loginCount], apiFunc: api.getTargetLevel, xAxisKey: 'level', data: {} }
      }
    }
  },
  methods: {
    fetchData () {
      for (let chart in this.chartsData) {
        this.fetchChartsData(chart)
      }
    },
    fetchChartsData (chart) {
      let data = this.chartsData[chart]
      let targetValue = data.target[0].value
      data.apiFunc(targetValue, this.isOpenServer).then(res => {
        let d = {}
        d[targetValue] = res
        data.data = d
      })
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
