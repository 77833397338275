<template>
  <section>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="boxActive" type="border-card" @tab-click="handleBoxClick">
            <!--日期查询-->
            <el-tab-pane label="日期查询" name="date">
              <!--搜索条件区域-->
              <div class="bi-channel-date-search">
                <el-form :inline="true">
                  <el-form-item>
                    <channel-cascade :multiple="true"></channel-cascade>
                  </el-form-item>
                  <el-form-item>
                    <el-date-picker v-model="date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="handleDateSearch" :loading="loading">查询</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <!--表格展示区域-->
              <div class="box flex" style="padding: 5px 5px">
                <el-switch style="display: block" v-model="isDateSwitchNumber" active-color="#13ce66" active-text="显示原始值"> </el-switch>
                <div class="execl">
                  <el-button
                    type="success"
                    size="small"
                    @click="onExecl(isDateSwitchNumber, targetList, dateTableData, '日期查询', 'date')"
                  >导出当前数据</el-button>
                </div>
              </div>
              <div class="box">
                <el-table :data="dateTableData" style="width: 100%" max-height="650" stripe sortable show-summary :summary-method="getSummaries">
                  <el-table-column label="区间" fixed width="100">
                    <template slot-scope="scope">
                      <span>{{payRangeFormatter(scope.row['payRange'])}}</span>
<!--                      <el-button type="text" size="small" @click="handleDialogVisible(scope, 'date', 'row')">{{payRangeFormatter(scope.row['payRange'])}}</el-button>-->
                    </template>
                  </el-table-column>
                  <el-table-column prop="payUserAdd" label="新增"> </el-table-column>
                  <el-table-column :prop="isDateSwitchNumber ? item.value+'.keep' : item.value+'.rate'" :label="item.label" v-for="(item, i) in targetList" :key="i"> </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>

            <!--区间查询-->
            <el-tab-pane label="区间查询" name="range">
              <!--搜索条件区域-->
              <div class="bi-channel-date-search">
                <el-form :inline="true">
                  <el-form-item>
                    <channel-cascade :multiple="true"></channel-cascade>
                  </el-form-item>
                  <el-form-item>
                    <date-range></date-range>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="handleRangeSearch" :loading="loading">查询</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div class="box">
                <el-tabs v-model="rangeActive" @tab-click="handleRangeTabClick">
                  <el-tab-pane :label="item.label" :name="item.value" v-for="(item, i) in payRangeOptions" :key="i"></el-tab-pane>
                </el-tabs>
              </div>
              <!--表格展示区域-->
              <div class="box flex" style="padding: 5px 5px">
                <el-switch style="display: block" v-model="isRangeSwitchNumber" active-color="#13ce66" active-text="显示原始值"> </el-switch>
                <div class="execl">
                  <el-button
                    type="success"
                    size="small"
                    @click="onExecl(isRangeSwitchNumber, targetList, rangeTableData, `区间查询-${getRangValue}`, 'range')"
                  >导出当前数据</el-button>
                </div>
              </div>
              <div class="box">
                <el-table :data="rangeTableData" style="width: 100%" max-height="650" stripe sortable show-summary :summary-method="getSummaries">
                  <el-table-column label="日期" width="100" fixed>
                    <template slot-scope="scope">
                      <span>{{payRangeFormatter(scope.row['date'])}}</span>
<!--                      <el-button type="text" size="small" @click="handleDialogVisible(scope, 'range', 'row')">{{payRangeFormatter(scope.row['date'])}}</el-button>-->
                    </template>
                  </el-table-column>
                  <el-table-column prop="payUserAdd" label="新增"> </el-table-column>
                  <el-table-column :prop="isRangeSwitchNumber ? item.value+'.keep' : item.value+'.rate'" :label="item.label" v-for="(item, i) in targetList" :key="i"> </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>

            <!--指标查询-->
            <el-tab-pane label="指标查询" name="target">
              <!--搜索条件区域-->
              <div class="bi-channel-date-search">
                <el-form :inline="true">
                  <el-form-item>
                    <channel-cascade :multiple="true"></channel-cascade>
                  </el-form-item>
                  <el-form-item>
                    <date-range></date-range>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="handleTargetSearch" :loading="loading">查询</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div class="box">
                <el-tabs v-model="targetActive" @tab-click="handleTargetTabClick">
                  <el-tab-pane :label="item.label" :name="item.value" v-for="(item, i) in targetList" :key="i"></el-tab-pane>
                </el-tabs>
              </div>
              <!--表格展示区域-->
              <div class="box flex" style="padding: 5px 5px">
                <el-switch style="display: block" v-model="isTargetSwitchNumber" active-color="#13ce66" active-text="显示原始值"> </el-switch>
                <div class="execl">
                  <el-button
                    type="success"
                    size="small"
                    @click="onExecl(isTargetSwitchNumber, targetRangeOptions, targetTableData, `指标查询-${getTargetValue}`, 'target')"
                  >导出当前数据</el-button>
                </div>
              </div>
              <div class="box">
                <el-table :data="targetTableData" style="width: 100%" max-height="650" stripe sortable  show-summary :summary-method="getSummaries">
                  <el-table-column prop="date" label="日期" width="100" fixed> </el-table-column>
                  <el-table-column :label="item.label" v-for="(item, i) in targetRangeOptions" :key="i" :prop="item.value">
                    <template slot-scope="scope">
                      <span v-if="isTargetSwitchNumber"> {{scope.row[item.value]['keep']}} / {{scope.row[item.value]['add']}}</span>
                      <span v-else> {{scope.row[item.value]['rate']}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="全 [0,+)" prop="total">
                    <template slot-scope="scope">
                      <span v-if="isTargetSwitchNumber"> {{scope.row.total['keep']}} / {{scope.row.total['add']}}</span>
                      <span v-else> {{scope.row.total['rate']}}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>

            <!-- 模态框-->
            <el-dialog :visible.sync="dialogVisible" :width="isMobile ? '100%' : '50%'" :before-close="handleClose">
              <chart-line-bar v-if="chartShowType === 'line'" :data=chartData></chart-line-bar>
<!--              <chart-line  v-if="chartShowType === 'line'"></chart-line>-->
              <chart-pie v-else></chart-pie>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
              </div>
            </el-dialog>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import DateRange from '@/components/DateRange'
import ChannelCascade from '@/components/Channel/ChannelCascade'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import ChartPie from '@/components/Charts/ChartPie'
import f from '@/filter'
import XLSX from 'xlsx'

export default {
  components: {
    DateRange,
    ChannelCascade,
    ChartLineBar,
    ChartPie
  },
  name: 'PayKeep',
  created () {
    this.handleDateSearch() // 默认请求第一个按日期分布数据
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    },
    getRangValue () {
      const obj = this.payRangeOptions.find(item => (+item.value === +this.rangeActive)) || {}
      return obj.label || ''
    },
    getTargetValue () {
      const obj = this.targetList.find(item => (item.value === this.targetActive)) || {}
      return obj.label || ''
    }
  },
  data () {
    let date = new Date().getTime() - 3600 * 1000 * 24 * 7
    let kt = option.keepType
    let targetList = [kt.k2, kt.k3, kt.k4, kt.k5, kt.k6, kt.k7, kt.k14, kt.k30, kt.k45, kt.k60, kt.k90, kt.k120, kt.k180]
    let payRangeOptions = [{ value: '0', label: '[0, +∞]' }, ...option.payRangeOptions]
    return {
      boxActive: 'date', // 最外层的标签
      dialogVisible: false, // 模态框是否打开
      chartShowType: 'line', // 模态框中展示的图表类型
      chartData: {}, // 图表需要展示的数据·
      sumMap: new Map(), // 保存合计数据

      date: new Date(date).toJSON().substring(0, 10),
      isDateSwitchNumber: false, // 查看方式
      dateTableData: [], // 日期分布表格数据

      // rangeActive: payRangeOptions[0].value, // 区间标签
      rangeActive: '0',
      isRangeSwitchNumber: false, // 查看方式
      payRangeOptions: payRangeOptions, // 区间列表
      targetRangeOptions: [...option.payRangeOptions],
      rangeTableData: [], // 区间分布表格数据

      targetActive: targetList[0].value, // 指标标签
      isTargetSwitchNumber: false, // 查看方式
      targetList: targetList, // 指标列表
      targetTableData: [], // 指标分布表格数据
      loading: false,
      boxTabs: {
        date: { apiFunc: api.getPayKeepDate, fetchFunc: this.handleDateSearch },
        range: { apiFunc: api.getPayKeepRange, fetchFunc: this.handleRangeSearch },
        target: { apiFunc: api.getPayKeepTarget, fetchFunc: this.handleTargetSearch }
      }
    }
  },
  methods: {
    // 最外层3个标签点击事件
    handleBoxClick () {
      this.boxTabs[this.boxActive].fetchFunc()
    },
    // 日期分布查询按钮
    handleDateSearch () {
      let params = { date: this.date }
      api.getPayKeepDate(params).then(res => {
        this.dateTableData = res.data
        this.sumMap.set(this.boxActive, res.sum)
      })
    },
    // 区间分布查询按钮
    handleRangeSearch () {
      const params = +this.rangeActive ? { range: this.rangeActive } : {}

      api.getPayKeepRange(params).then(res => {
        this.rangeTableData = res.data
        this.sumMap.set(this.boxActive, res.sum)
      })
    },
    // 区间标签点击事件
    handleRangeTabClick () {
      this.handleRangeSearch()
    },
    // 指标分布查询按钮
    handleTargetSearch () {
      let params = { target: this.targetActive }
      api.getPayKeepTarget(params).then(res => {
        this.targetTableData = res.data
        this.sumMap.set(this.boxActive, res.sum)
      })
    },
    // 指标标签点击事件
    handleTargetTabClick () {
      this.handleTargetSearch()
    },
    payRangeFormatter (value) {
      return f.payRangeFormat(value)
    },
    // 打开模态框，传入展示的图形
    handleDialogVisible (scope, tag, rowOrColumn) {
      let p = option.payRangeType
      switch (tag) {
        case 'date':
          if (rowOrColumn === 'row') {
            let target = [p[scope.row['payRange']]]
            this.handleLineOrBarData(scope.row, target, scope.row['payRange'], this.isDateSwitchNumber)
          }
          if (rowOrColumn === 'column') {}
          break
        case 'range':
          if (rowOrColumn === 'row') {
          }
          if (rowOrColumn === 'column') {}
          break
        case 'target':
          break
      }
      this.dialogVisible = true
      // this.chartShowType = type
      // let p = option.payRangeType
      // let kt = option.keepType
      // let data = {}
      // // this.payRangeOptions[target]
      // if (this.chartShowType === 'line') {
      //   // 日期查询
      //   if (target === 'payRange') {
      //     data.targets = [p[row[target]]]
      //     data.type = type
      //     data.xAxisKey = 'keep'
      //     data.data = {}
      //     let arr = []
      //     for (let item in row) {
      //       let obj = {}
      //       if (typeof (row[item]) !== 'object') {
      //         continue
      //       }
      //       obj['keep'] = kt[item].label
      //       obj['value'] = row[item].keep
      //       arr.push(obj)
      //     }
      //     data.data[row[target]] = arr
      //   }
      // }
      // this.chartData = data
    },
    // 日期、区间 合计
    getSummaries (param) {
      const { columns } = param
      const sums = []
      let sumData = this.sumMap.get(this.boxActive)
      if (!sumData) {
        return sums
      }
      columns.forEach((column, index) => {
        let key = column.property
        if (index === 0) {
          sums[index] = '统计'
          return
        }
        if (this.boxActive !== 'target') {
          let n = key.indexOf('.')
          sums[index] = n > 0 ? sumData[key.slice(0, n)][key.slice(n + 1, key.length)] : sumData[key]
        } else {
          let obj = sumData[key] || {}
          let keep = obj['keep'] || 0
          let add = obj['add'] || 0
          let rate = obj['rate'] || 0
          sums[index] = this.isTargetSwitchNumber ? keep + '/' + add : rate
        }
      })
      return sums
    },
    // 关闭模态框
    handleClose (done) {
      done()
    },
    handleLineOrBarData (data, targets, key, isSwitchNumber) {
      let obj = {}
      obj.targets = targets
      obj.type = isSwitchNumber ? 'line' : 'bar'
      obj.xAxisKey = isSwitchNumber ? 'keep' : 'rate'
      obj.data = {}
      let arr = []
      for (let item of this.targetList) {
        let obj = {}
        obj[isSwitchNumber ? 'keep' : 'rate'] = item.label
        obj['value'] = data[item.value][isSwitchNumber ? 'keep' : 'rate']
        arr.push(obj)
      }
      obj.data[key] = arr
      this.chartData = obj
    },
    onExecl (isOriginal, column, list, fileName, type) {
      let columnList = JSON.parse(JSON.stringify(column))
      switch (type) {
        case 'date':
          columnList = [...[
            { label: '区间', value: 'payRange' },
            { label: '新增', value: 'payUserAdd' }
          ], ...columnList]
          break
        case 'range':
          columnList = [
            { label: '日期', value: 'date' },
            { label: '新增', value: 'payUserAdd' },
            ...columnList
          ]
          break
        case 'target':
          columnList = [
            { label: '日期', value: 'date' },
            ...columnList,
            { label: '全 [0,+)', value: 'total' }
          ]
          break
        default:
          break
      }
      const headerData = columnList.reduce((pre, cur) => {
        return { ...pre, [type === 'target' ? `k${cur.value}` : cur.value]: cur.label }
      }, {})
      const json = [headerData, ...list.map((item) => {
        return Object.keys(headerData).reduce((pre, cur) => {
          const key = type === 'target' ? cur.replace('k', '') : cur
          let value = item[key]
          if (cur === 'payRange') value = this.payRangeFormatter(value)

          if (typeof value === 'object') {
            value = isOriginal
              ? (type === 'target' ? `${value.keep} / ${value.add}` : value.keep)
              : value.rate
          }
          return { ...pre, [cur]: value }
        }, '')
      }, {})]
      if (this.sumMap.has(this.boxActive)) {
        const sumObj = this.sumMap.get(this.boxActive)
        json.push(Object.keys(headerData).reduce((pre, cur, index) => {
          const key = type === 'target' ? cur.replace('k', '') : cur
          let value = sumObj[key]
          if (cur === 'payRange') value = this.payRangeFormatter(value)

          if (typeof value === 'object') {
            value = isOriginal
              ? (type === 'target' ? `${value.keep} / ${value.add}` : value.keep)
              : value.rate
          }
          return { ...pre, [cur]: !index ? '统计' : value }
        }, {}))
      }

      const prefix = `${fileName}${isOriginal ? '-原始值' : ''}`
      this.handleExecl(json, prefix)
    },
    handleExecl (json, fileName) {
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(json, { skipHeader: true })
      worksheet['!cols'] = Object.keys(json[0]).map(item => {
        if (['kdate', 'date', 'payRange'].includes(item)) {
          return { width: 16 }
        }
        return { width: 10 }
      })
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

      const filename = `${fileName}-${new Date().getTime()}.xlsx`
      XLSX.writeFile(workbook, filename)
    }
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
.execl {
  margin-left: 6px;
}
</style>
