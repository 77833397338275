<template>
  <section>
    <div class="bi-channel-date-search">
      <el-form :inline="true">
        <el-form-item>
          <channel-cascade></channel-cascade>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dateType" placeholder="请选择时间类型" @change="handleDateTypeChange">
            <el-option  v-for="item in dateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="st" :type="dateType" placeholder="起始日期" @change="startTimeChange" ref="startTimePicker"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="et" :type="dateType" placeholder="截至日期" @change="endTimeChange" ref="endTimePicker"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQueryClick" :loading="loading">查看</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bi-channel-date-search">
      <el-row :gutter="10">
        <!--    合计-->
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <div :style="{marginTop: '10px'}">
            <el-table :data="billsSumData"
                      stripe border
                      :summary-method="getSummaries"
                      show-summary
                      v-loading="sumLoading"
                      element-loading-text="拼命加载中,请稍等..."
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.8)">
              <el-table-column type="index" width="60" label="#"></el-table-column>
              <el-table-column prop="money" label="金额" sortable :formatter="moneyFormatter"></el-table-column>
              <el-table-column prop="discount" label="折扣金额" sortable :formatter="discountFormatter"></el-table-column>
              <el-table-column prop="currency" label="货币" sortable></el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-alert title="汇率说明" description="1USD = 7CNY | 1TWD = 0.2CNY | 1RMB = 1CNY" type="warning" style="margin-top: 10px" :closable="false" show-icon></el-alert>
        </el-col>
      </el-row>
    </div>
<!--    渠道账单-->
    <div class="bi-channel-date-search">
      <el-tabs v-model="activeName" @tab-click="handleTableClick">
        <el-tab-pane label="分渠道展示" name="tab1">
          <el-table :data="channelBillsData"
                    stripe border
                    style="width: 100%"
                    v-loading="channelLoading"
                    element-loading-text="拼命加载中,请稍等..."
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-table-column type="index" width="60" label="#"></el-table-column>
            <el-table-column prop="channelId" label="渠道ID" :formatter="channelFormatter" sortable></el-table-column>
            <el-table-column prop="money" label="金额" sortable :formatter="moneyFormatter"></el-table-column>
            <el-table-column prop="discount" label="折扣金额" sortable :formatter="discountFormatter"></el-table-column>
            <el-table-column prop="currency" label="货币" sortable></el-table-column>
          </el-table>
          <export-excel :data="channelBillsData" :headers="channelExcelTHList" filename="渠道账单" style="margin-top: 10px"></export-excel>
        </el-tab-pane>
        <el-tab-pane label="按日期展示" name="tab2">
          <el-table :data="dateTableBillsData"
                    stripe row-key="id"
                    border
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    v-loading="timeLoading"
                    element-loading-text="拼命加载中,请稍等..."
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-table-column type="index" width="60" label="#"></el-table-column>
            <el-table-column prop="date" label="日期" sortable></el-table-column>
            <el-table-column prop="money" label="金额" sortable :formatter="moneyFormatter"></el-table-column>
            <el-table-column prop="discount" label="折扣金额" sortable :formatter="discountFormatter"></el-table-column>
            <el-table-column prop="currency" label="货币" sortable></el-table-column>
          </el-table>
          <export-excel :data="dateExcelBillsData" :headers="detailExcelTHList" filename="渠道账单详情" style="margin-top: 10px"></export-excel>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import api from '@/api/bi'
import ExportExcel from '@/components/Excel/ExportExcel'
import f from '@/filter'
import ChannelCascade from '../../components/Channel/ChannelCascade'

export default {
  components: {
    ChannelCascade,
    ExportExcel
  },
  name: 'Reconciliation',
  data () {
    let dateOptions = [{
      value: 'month',
      label: '年-月'
    }, {
      value: 'date',
      label: '年-月-日'
    }]
    return {
      dateOptions: dateOptions,
      st: this.$moment().add(-1, 'month').format('YYYY-MM'),
      et: this.$moment().add(-1, 'month').format('YYYY-MM'),
      dateType: 'month',
      loading: false,
      sumLoading: false,
      channelLoading: false,
      timeLoading: false,
      activeName: 'tab1',
      billsSumData: [], // 货币合计
      channelBillsData: [], // 渠道数据
      dateTableBillsData: [], // 表格展示数据
      dateExcelBillsData: [], // Excel导出数据
      detailExcelTHList: [
        { value: 'date', label: '日期' },
        { value: 'money', label: '金额（分）' },
        { value: 'discount', label: '折扣金额(分)' },
        { value: 'moneyYuan', label: '金额（元）' },
        { value: 'discountYuan', label: '折扣金额(元)' },
        { value: 'currency', label: '货币' }
      ],
      channelExcelTHList: [
        { value: 'channelId', label: '渠道' },
        { value: 'money', label: '金额（分）' },
        { value: 'discount', label: '折扣金额(分)' },
        { value: 'moneyYuan', label: '金额（元）' },
        { value: 'discountYuan', label: '折扣金额(元)' },
        { value: 'currency', label: '货币' }
      ],
      totalMoney: 0,
      totalDiscount: 0,
      isTotalFetched: false,
      isChannelFetched: false,
      isDateFetched: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters.isMobile
    },
    appId () {
      return this.$store.getters.appId
    },
    channelCascadeIds () {
      return this.$store.getters.channelCascadeIds
    }
  },
  methods: {
    // 所需要的参数
    params () {
      let channels = []
      for (let c of this.channelCascadeIds) {
        if (c.length > 1) {
          channels.push(c[1])
        }
      }
      let format = this.dateType === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD'
      return {
        appId: this.appId,
        st: this.$moment(this.st).format(format),
        et: this.$moment(this.et).format(format),
        channels: channels
      }
    },
    // 请求合计
    fetchData () {
      if (this.isTotalFetched) {
        return
      }
      this.loading = true
      this.sumLoading = true
      this.billsSumData = []
      this.totalMoney = 0
      this.totalDiscount = 0
      // 合计
      let params = this.params()
      api.getChannelFeeBills(params).then(res => {
        this.isTotalFetched = true
        this.billsSumData = [...res]
        let totalMoney = 0
        for (let d of res) {
          totalMoney += f.money2CNY(d.money, d.currency)
        }
        this.totalMoney = (totalMoney / 100).toFixed(2)
        let totalDiscount = 0
        for (let d of res) {
          totalDiscount += f.money2CNY(d.discount, d.currency)
        }
        this.totalDiscount = (totalDiscount / 100).toFixed(2)
        this.sumLoading = false

        if (this.activeName === 'tab1') {
          this.fetchChannelData()
        }
        if (this.activeName === 'tab2') {
          this.fetchDateData()
        }
      })
    },
    // 请求渠道账单
    fetchChannelData () {
      if (this.isChannelFetched) {
        return
      }
      this.loading = true
      this.channelLoading = true
      this.channelBillsData = []
      let params = this.params()
      let channelParams = Object.assign({ display: 'channel' }, params)
      api.getChannelFeeBills(channelParams).then(res => {
        this.isChannelFetched = true
        // this.channelBillsData = [...res]
        this.channelBillsData = [...res].map((item) => {
          return {
            moneyYuan: +(item.money / 100).toFixed(2),
            discountYuan: +(item.discount / 100).toFixed(2),
            ...item
          }
        })
        // for (let item of this.channelBillsData) {
        //   if (item.currency === 'RMB') {
        //     item.currency = 'CNY'
        //   }
        // }
        this.loading = false
        this.channelLoading = false
      })
    },
    // 请求日期账单
    fetchDateData () {
      if (this.isDateFetched) {
        return
      }
      this.loading = true
      this.timeLoading = true
      this.dateTableBillsData = []
      this.dateExcelBillsData = []
      let params = this.params()
      let dateParams = Object.assign({ display: 'date' }, params)
      // 日期账单
      api.getChannelFeeBills(dateParams).then(res => {
        console.log(JSON.stringify(res))
        this.isDateFetched = true
        this.dateExcelBillsData = [...res].map((item) => {
          return {
            moneyYuan: +(item.money / 100).toFixed(2),
            discountYuan: +(item.discount / 100).toFixed(2),
            ...item
          }
        }) // 导出数据
        let dataMap = new Map()
        for (let d of res) {
          d.id = d.date + d.currency
          let date = d.date
          let obj = dataMap.get(date)
          if (!obj) {
            obj = Object.assign({ children: [] }, d)
            obj.id += '-x'
            dataMap.set(date, obj)
          }
          obj.children.push(d)
        }
        let dateTableBillsData = [] // 表格数据
        for (let v of dataMap.values()) {
          if (v.children.length > 1) {
            let sum = 0
            for (let d of v.children) {
              sum += f.money2CNY(d.money, d.currency)
            }
            v.money = sum
            let discount = 0
            for (let e of v.children) {
              discount += f.money2CNY(e.discount, e.currency)
            }
            v.discount = discount
            v.currency = 'CNY' // 将父层的RMB转换为CNY，子层的保持RMB
          } else {
            v.children = []
          }
          dateTableBillsData.push(v)
        }
        this.dateTableBillsData = dateTableBillsData
        this.loading = false
        this.timeLoading = false
      })
    },
    // 日期类型发生改变
    handleDateTypeChange () {
      switch (this.dateType) {
        case 'date':
          this.st = this.$moment().format('YYYY-MM-DD')
          this.et = this.$moment().format('YYYY-MM-DD')
          break
        case 'month':
          this.st = this.$moment().add(-1, 'month').format('YYYY-MM')
          this.et = this.$moment().add(-1, 'month').format('YYYY-MM')
          break
      }
    },
    // 点击查询按钮
    handleQueryClick () {
      this.isDateFetched = false
      this.isChannelFetched = false
      this.isTotalFetched = false
      this.fetchData()
    },
    // 点击标签页
    handleTableClick (tab) {
      if (this.activeName === 'tab1') {
        this.fetchChannelData()
      }
      if (tab.name === 'tab2') {
        this.fetchDateData()
      }
    },
    // 初始化渠道ID
    channelFormatter (row) {
      return f.channelFormat(row.channelId)
    },
    // 初始化渠道ID
    moneyFormatter (row) {
      return (row.money / 100).toFixed(2)
    },
    // 初始化渠道ID
    discountFormatter (row) {
      return (row.discount / 100).toFixed(2)
    },
    // 初始时间变化
    startTimeChange () {
      if (this.$moment(this.st).isAfter(this.et)) {
        this.et = ''
        this.$refs.endTimePicker.focus()
      }
    },
    // 截至日期变化
    endTimeChange () {
      if (this.$moment(this.st).isAfter(this.et)) {
        this.st = ''
        this.$refs.startTimePicker.focus()
      }
    },
    // 合计
    getSummaries (param) {
      return ['合计', this.totalMoney, this.totalDiscount, 'CNY']
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>
