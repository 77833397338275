import http from '@/api/http'
import store from '@/store'

export default {
  getKeep: (params, type = 'date') => {
    return http.get(`bi/${store.getters.appId}/daily/keep/${type}?${params}`)
  },
  getLtv: (params, type = 'date') => {
    return http.get(`bi/${store.getters.appId}/daily/ltv/${type}?${params}`)
  }
}
