<template>
  <section>
    <div class="box">
      <el-form :inline="true">
        <el-form-item>
          <el-select v-model="interval" placeholder="请选择" @change="selectChange">
            <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData" :loading="loading">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" icon="el-icon-plus" @click="handleManual">封禁IP</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="box">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="ip" label="IP" sortable></el-table-column>
        <el-table-column prop="userCount" label="数量" sortable></el-table-column>
        <el-table-column prop="blockedTime" label="封禁时间"></el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button @click="handleCloseIP(scope.row.ip, scope.$index)" type="text" size="small" :disabled="scope.row.isBlocked">封禁</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import api from '@/api/bi'

export default {
  name: 'CloseIP',
  created () {
    this.fetchData()
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  data () {
    return {
      loading: false,
      interval: '12',
      timeOptions: [
        {
          value: '12',
          label: '12h'
        }, {
          value: '24',
          label: '24h'
        }, {
          value: '72',
          label: '72h'
        }
      ],
      tableData: []
    }
  },
  methods: {
    fetchData () {
      let params = { interval: this.interval }
      this.loading = true
      api.getTOPIP(this.appId, params).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleCloseIP (ip, index) {
      let params = JSON.stringify([ip])
      this.$confirm('此操作将封禁该IP, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.postBlacklist(params).then(res => {
          this.tableData.splice(index, 1)
          this.$message.success('封禁成功')
        }).catch((res) => {
          this.$message.error('封禁失败', '错误码' + res.code)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消封禁'
        })
      })
    },
    selectChange () {
      this.tableData = []
      this.fetchData()
    },
    // 手动查封
    handleManual () {
      this.$prompt('', '封禁IP', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入待封禁IP',
        inputPattern: /(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/,
        inputErrorMessage: 'IP格式不正确'
      }).then(({ value }) => {
        let params = JSON.stringify([value])
        api.postBlacklist(params).then(() => {
          this.$message.success('封禁成功')
        }).catch((res) => {
          this.$message.error('封禁失败', '错误码' + res.code)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消封禁'``
        })
      })
    }
  }
}
</script>

<style scoped>
  .el-form-item {
    padding: 5px 0;
    margin-bottom: 0;
  }
</style>
