<template>
  <section>
    <div class="box">
      <el-form :inline="true" style="padding: 10px">
        <el-form-item>
          <el-select v-model="subChannels"
                     filterable clearable
                     multiple placeholder="子渠道(广告创意ID)"
                     style="margin-bottom: 10px;width: 350px"
                     :collapse-tags = isCollapseTags
                     @visible-change="selectChange">
            <el-option v-for="item in subChannelOptions"
                       :key="item.cid"
                       :label="item.subChannelId + '-' + item.cidName + '-' + item.cid"
                       :value="item.subChannelId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <date-range></date-range>
        </el-form-item>
        <el-form-item>
          <el-button @click="fetchTableData"  type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="box">
      <el-table :data="tables"
                stripe sortable
                max-height="650"
                style="width: 100%"
                v-loading="loading"
                element-loading-text="拼命加载中,请稍等..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                v-el-table-loadmore="loadMore">
        <el-table-column
          :prop="item.value"
          :label="item.label"
          v-for="item in tHeaderList"
          :key="item.value"
          :sortable="item.sortable"
          :width="item.value === 'date'? '100' : '120'"
          :fixed="item.value === 'date' || item.value === 'subChannelId'"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <export-excel
        v-show="exportExcelVisible"
        :data="tableData"
        :headers="thList"
        filename="广告报表">
      </export-excel>
    </div>
  </section>
</template>

<script>
import api from '@/api/bi'
import ExportExcel from '@/components/Excel/ExportExcel'
import DateRange from '@/components/DateRange/index'
export default {
  name: 'tg_Daily',
  components: { DateRange, ExportExcel },
  created () {
    this.fetchData()
  },
  computed: {
    tables () {
      return this.tableData.slice(0, this.rangeYNumber)
    },
    tHeaderList () {
      return this.thList.slice(0, this.rangeXNumber)
    },
    timeValue () {
      return this.$store.getters.dataPickerValue
    }
  },
  data () {
    const thList = [
      { value: 'date', label: '日期', sortable: true },
      { value: 'subChannelId', label: '子渠道ID', sortable: false },
      { value: 'advertiserId', label: '广告主ID', sortable: false },
      { value: 'campaignId', label: '广告组ID', sortable: false },
      { value: 'aid', label: '广告计划ID', sortable: false },
      { value: 'cid', label: '广告创意ID', sortable: false },
      { value: 'cidName', label: '广告创意名称', sortable: false },
      { value: 'showCount', label: '展示数', sortable: true },
      { value: 'clickCount', label: '点击数', sortable: true },
      { value: 'clickRate', label: '点击率', sortable: true },
      { value: 'loginDeviceAdd', label: '新增设备', sortable: true },
      { value: 'loginDevice', label: '活跃设备', sortable: true },
      { value: 'activeRate', label: '转化率', sortable: true },
      { value: 'loginUserAdd', label: '新增用户', sortable: true },
      { value: 'loginUser', label: '活跃用户', sortable: true },
      { value: 'payUserAdd', label: '新增付费用户', sortable: true },
      { value: 'payUser', label: '付费用户', sortable: true },
      { value: 'payMoneyAdd', label: '新增付费金额', sortable: true },
      { value: 'payMoney', label: '付费金额', sortable: true },
      { value: 'payRate', label: '付费率', sortable: true },
      { value: 'payRateAdd', label: '新增付费率', sortable: true },
      { value: 'arpu', label: 'ARPU', sortable: true },
      { value: 'arppu', label: 'ARPPU', sortable: true },
      { value: 'keep2', label: '次日留存', sortable: true },
      { value: 'keep3', label: '3日留存', sortable: true },
      { value: 'keep4', label: '4日留存', sortable: true },
      { value: 'keep5', label: '5日留存', sortable: true },
      { value: 'keep6', label: '6日留存', sortable: true },
      { value: 'keep7', label: '7日留存', sortable: true },
      { value: 'keep14', label: '14日留存', sortable: true },
      { value: 'keep30', label: '30日留存', sortable: true },
      { value: 'keep45', label: '45日留存', sortable: true },
      { value: 'keep60', label: '60日留存', sortable: true },
      { value: 'keep90', label: '90日留存', sortable: true },
      { value: 'keep120', label: '120日留存', sortable: true },
      { value: 'keep180', label: '180日留存', sortable: true },
      { value: 'keep180later', label: '180日+留存', sortable: true },
      { value: 'ltv1', label: '当日ltv', sortable: true },
      { value: 'ltv2', label: '次日ltv', sortable: true },
      { value: 'ltv3', label: '3日ltv', sortable: true },
      { value: 'ltv4', label: '4日ltv', sortable: true },
      { value: 'ltv5', label: '5日ltv', sortable: true },
      { value: 'ltv6', label: '6日ltv', sortable: true },
      { value: 'ltv7', label: '7日ltv', sortable: true },
      { value: 'ltv14', label: '14日ltv', sortable: true },
      { value: 'ltv30', label: '30日ltv', sortable: true },
      { value: 'ltv45', label: '45日ltv', sortable: true },
      { value: 'ltv60', label: '60日ltv', sortable: true },
      { value: 'ltv90', label: '90日ltv', sortable: true },
      { value: 'ltv120', label: '120日ltv', sortable: true },
      { value: 'ltv180', label: '180日ltv', sortable: true },
      { value: 'ltv180later', label: '180日+ltv', sortable: true }
    ]
    return {
      thList,
      // 子渠道下拉列表
      subChannelOptions: [],
      // 已选子渠道列表
      subChannels: [],
      // 表格数据
      tableData: [],
      loading: false,
      rangeXNumber: 60,
      rangeYNumber: 30,
      isCollapseTags: false,
      exportExcelVisible: false
    }
  },
  methods: {
    fetchData () {
      api.getTgParams().then(res => {
        this.subChannelOptions = [...res]
      })
    },
    fetchTableData () {
      if (!this.subChannels.length) {
        this.$message.error('请选择子渠道')
        return false
      }
      const param = { subChannels: this.subChannels, st: this.timeValue[0], et: this.timeValue[1] }
      api.getTgReport(param).then(res => {
        this.tableData = [...res]
        this.exportExcelVisible = true
      })
    },
    // 下拉框的展现形态
    selectChange (val) {
      const data = [...this.subChannels]
      this.subChannels = []
      this.isCollapseTags = !val
      this.subChannels = [...data]
    },
    loadMore (str) {
      if (str === 'x') {
        this.rangeXNumber += 5
      }
      if (str === 'y') {
        this.rangeYNumber += 10
      }
    }
  }
}
</script>

<style scoped>
section .el-card .el-card__body {
  padding: 0;
}
.el-form .el-form-item .el-select {
  margin-bottom: 0 !important;
}
</style>
