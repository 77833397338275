import http from '@/api/http'
import store from '@/store'

export default {
  /** 新增老用户日报 */
  getList (params) {
    return http.get(`/bi/${store.getters.appId}/add-old/report`, { params })
  },

  /** 回流老用户日报 */
  getRefluxList (params) {
    return http.get(`/bi/${store.getters.appId}/reflux-old/report`, { params })
  },

  /** ltv */
  getLtvList (params, type = 'date') {
    return http.get(`/bi/${store.getters.appId}/add-old/daily/ltv/${type}?${params}`)
  },

  /** keep */
  getKeepList (params, type = 'date') {
    return http.get(`/bi/${store.getters.appId}/add-old/daily/keep/${type}?${params}`)
  }
}
