<template>
  <section>
    <bi-channel-date-search @change="fetchData" :isGame="true" @isServer="isServer"></bi-channel-date-search>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart1.tabActive" @tab-click="fetchChart1Data">
            <el-tab-pane label="新增付费用户" name="tab1"></el-tab-pane>
            <el-tab-pane label="累计付费用户" name="tab2"></el-tab-pane>
            <el-tab-pane label="总体付费率" name="tab3"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart1.data"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="box">
          <el-tabs v-model="chart2.tabActive" @tab-click="fetchChart2Data">
            <el-tab-pane label="首日付费率" name="tab1"></el-tab-pane>
            <el-tab-pane label="首周付费率" name="tab2"></el-tab-pane>
            <el-tab-pane label="首月付费率" name="tab3"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="chart2.data"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="首充等级" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="charts.chart3"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="首充商品" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="charts.chart4"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="首充金额" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="charts.chart5"></chart-line-bar>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="box">
          <el-tabs value="tab1">
            <el-tab-pane label="首充周期" name="tab1"></el-tab-pane>
          </el-tabs>
          <chart-line-bar :data="charts.chart6"></chart-line-bar>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import api from '@/api/bi'
import option from '@/common/option'
import ChartLineBar from '@/components/Charts/ChartLineBar'
import BiChannelDateSearch from '@/components/BI/BiChannelDateSearch'

export default {
  components: {
    BiChannelDateSearch,
    ChartLineBar
  },
  data () {
    let t = option.targetType
    return {
      isOpenServer: false,
      chart1: {
        tabActive: 'tab1',
        tab1: { title: '新增付费用户', fetchTargets: [t.payUserAdd], targets: [t.payUserAdd], type: 'line' },
        tab2: { title: '累计付费用户', fetchTargets: [t.payUserTotal], targets: [t.payUserTotal], type: 'line' },
        tab3: { title: '总体付费率', fetchTargets: [t.payRateTotal], targets: [t.payRateTotal], type: 'line' },
        data: {}
      },
      chart2: {
        tabActive: 'tab1',
        tab1: { title: '首日付费率', fetchTargets: [t.payRate1d], targets: [t.payUserAdd1d], targets2: [t.payRate1d] },
        tab2: { title: '首周付费率', fetchTargets: [t.payRate7d], targets: [t.payUserAdd7d], targets2: [t.payRate7d] },
        tab3: { title: '首月付费率', fetchTargets: [t.payRate30d], targets: [t.payUserAdd30d], targets2: [t.payRate30d] },
        data: {}
      },
      charts: {
        chart3: { title: '首充等级', apiFunc: api.getFirstPayLevel, xAxisKey: 'level', targets: [t.firstPayLevel], data: {} },
        chart4: { title: '首充商品', apiFunc: api.getFirstPayProduct, xAxisKey: 'product', targets: [t.firstPayProduct], data: {} },
        chart5: { title: '首充金额', apiFunc: api.getFirstPayMoney, xAxisKey: 'payRange', targets: [t.firstPayMoney], data: {} },
        chart6: { title: '首充周期', apiFunc: api.getFirstPayDays, xAxisKey: 'interval', targets: [t.firstPayDays], data: {} }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.fetchChart1Data()
      this.fetchChart2Data()
      for (let chart in this.charts) {
        if (this.charts.hasOwnProperty(chart)) {
          this.fetchChartsData(chart)
        }
      }
    },
    fetchChart1Data () {
      let tab = this.chart1.tabActive
      let data = Object.assign({}, this.chart1[tab])
      api.getMultiTargetData(data.fetchTargets, data.apiFunc, this.isOpenServer).then(res => {
        data.data = res
        this.chart1.data = data
      })
    },
    fetchChart2Data () {
      let tab = this.chart2.tabActive
      let data = this.chart2[tab]
      api.getMultiTargetData(data.fetchTargets, null, this.isOpenServer).then(res => {
        data.data = res
        this.chart2.data = data
      })
    },
    fetchChartsData (chart) {
      let data = this.charts[chart]
      let targetValue = data.targets[0].value
      data.apiFunc(this.isOpenServer).then(res => {
        let d = {}
        d[targetValue] = res
        data.data = d
      })
    },
    isServer (value) {
      this.isOpenServer = value
    }
  }
}
</script>
